import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {Router} from '@angular/router';
import {ConnectedStatusEnum} from '../../../../../model/enums/connected-status.enum';
import {CurrentUserService} from '../../../../../services/current-user.service';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-edit-btn-renderer-custom',
  templateUrl: './edit-btn-renderer.component.html',
  styleUrls: ['./edit-btn-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatMenuModule, MatIconModule]
})
export class EditBtnRendererComponent implements ICellRendererAngularComp {
  id: number;
  isConnected: ConnectedStatusEnum;

  statusValidated: ConnectedStatusEnum = ConnectedStatusEnum.VALIDATED;
  statusMigration: ConnectedStatusEnum = ConnectedStatusEnum.MIGRATION;
  statusWeb: ConnectedStatusEnum = ConnectedStatusEnum.WEB;
  statusMobile: ConnectedStatusEnum = ConnectedStatusEnum.MOBILE;

  constructor(
    private readonly router: Router,
    private readonly currentUserService: CurrentUserService
  ) {}

  agInit(params): void {
    this.id = params.data.id;
    this.isConnected = params.data.isConnected;
  }

  refresh(): boolean {
    return false;
  }

  validation() {
    this.router.navigate(['young-validation', this.id]);
  }

  edit() {
    this.router.navigate(['/young-creation'], {queryParams: {id: this.id}});
  }

  showInfos() {
    this.router.navigate(['/young-info', this.id]);
  }

  canAccessCreateYoung(): boolean {
    return this.currentUserService.canAccess('young-creation');
  }
}
