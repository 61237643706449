import {TypeStructureEntrepriseEnum} from '../enums/type-structure-entreprise.enum';
import {Entreprise} from '../entreprise/entreprise.model';

export class EntrepriseDTO {
  id: number;
  typeStructure: TypeStructureEntrepriseEnum;
  nom: string;
  adresse: string;
  partenaire: boolean;
  interest: string[];
  needs: string[];
  convention: boolean;
  entrepriseMere: EntrepriseDTO;

  listOfEntreprise: EntrepriseDTO[];
  listOfUsers: string[];

  dateBegin?: Date;
  dateEnd?: Date;

  static toDto(entreprise: Entreprise) {
    const entrepriseDTO: EntrepriseDTO = new EntrepriseDTO();
    entrepriseDTO.id = entreprise.id;
    entrepriseDTO.dateBegin = entreprise.dateBegin;
    entrepriseDTO.partenaire = entreprise.entreprisePartenaire;
    entrepriseDTO.nom = entreprise.nom;
    entrepriseDTO.convention = entreprise.convention;
    return entrepriseDTO;
  }
}
