import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {SimpleProgramDTO} from 'src/app/model/dto/simple-program.dto';
import {NgFor} from '@angular/common';

@Component({
  selector: 'app-event-type-list-cell-renderer',
  templateUrl: './event-type-list-cell-renderer.component.html',
  styleUrls: ['./event-type-list-cell-renderer.component.scss'],
  standalone: true,
  imports: [NgFor]
})
export class EventTypeListCellRendererComponent implements ICellRendererAngularComp {
  listOfPrograms: SimpleProgramDTO[];

  agInit(params): void {
    this.listOfPrograms = params.data.listOfPrograms;
  }

  refresh(): boolean {
    return false;
  }
}
