import {Component} from '@angular/core';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-confirm-suppression-relation',
  templateUrl: './dialog-confirm-suppression-relation.component.html',
  styleUrls: ['./dialog-confirm-suppression-relation.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatDialogContent, NgIf, MatDialogActions, MatButtonModule]
})
export class DialogConfirmSuppressionRelationComponent {
  canDelete = false;
  title: string;
  message: string;

  constructor(public readonly dialogRef: MatDialogRef<DialogConfirmSuppressionRelationComponent>) {}

  Fermer() {
    this.dialogRef.close(false);
  }

  Confirmer() {
    this.dialogRef.close(true);
  }

  dismiss() {
    this.dialogRef.close(false);
  }
}
