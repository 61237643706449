import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Paged} from 'src/app/model/response/paged.model';
import {SimpleMonitoringDTO} from 'src/app/model/dto/simple-monitoring.dto';

@Injectable({
  providedIn: 'root'
})
export class GridYoungMonitoringDataService {
  private readonly dataMonitoringList = new BehaviorSubject(null);
  public monitoringList = this.dataMonitoringList.asObservable();

  changeMonitoringList(listOfMonitoring: Paged<SimpleMonitoringDTO>) {
    this.dataMonitoringList.next(listOfMonitoring);
  }
}
