import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {GridOptions} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {StatusCellRendererComponent} from '../custom-cell/status-cell-renderer/status-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {User} from 'src/app/model/user/user.model';
import {GridUserPresenceService} from 'src/app/services/grid-user-presence.service';
import {Timesheet} from 'src/app/model/event/timesheet.model';
import {TimesheetWebservice} from 'src/app/services/webservices/timesheet.webservice';
import {AlertService} from 'src/app/services/alert.service';
import {EventOccurrenceDetailUserDTO} from 'src/app/model/dto/event-occurrence-detail-user.dto';
import {Destroyed} from '../../directives/destroyed.directive';
import {ModuleSource, onlyAdmin} from 'src/app/model/enums/source/module-source.enum';
import {AsyncPipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {HasModuleDirective} from '../../directives/has-module.directive';
import {AlertComponent} from '../../alert/alert/alert.component';
import {AgGridModule} from '@ag-grid-community/angular';

@Component({
  selector: 'app-grid-user-presence',
  templateUrl: './grid-user-presence.component.html',
  styleUrls: ['./grid-user-presence.component.scss'],
  standalone: true,
  imports: [AgGridModule, AlertComponent, HasModuleDirective, MatButtonModule, AsyncPipe]
})
export class GridUserPresenceComponent extends Destroyed implements OnInit, OnDestroy {
  @Input() presencesEditable: boolean;

  gridApi: unknown;
  gridOptions: GridOptions;
  columnDefs;
  context;
  frameworkComponents;
  langue = agGridLanguageFr;
  paginationSize = 10;
  rowData: unknown = null;
  rowClassRules;
  domLayout: unknown = 'autoHeight';
  rowSelection;

  listOfUser: EventOccurrenceDetailUserDTO[];

  public readonly canValidate = [...onlyAdmin, ModuleSource.MODIFICATION_EVENEMENT];

  loading = false;

  constructor(
    private readonly formatService: FormatService,
    private readonly gridUserPresenceService: GridUserPresenceService,
    private readonly timesheetWebservice: TimesheetWebservice,
    private readonly alertService: AlertService
  ) {
    super();

    this.columnDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        cellRenderer: NameCellRendererComponent,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        sortable: true,
        minWidth: 250,
        maxWidth: 300
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        minWidth: 50,
        maxWidth: 70
      },
      {
        headerName: 'Naissance',
        field: 'birthday',
        sortable: true,
        minWidth: 150,
        maxWidth: 180
      },
      {
        headerName: 'Tél.',
        field: 'mobilePhone',
        minWidth: 200,
        maxWidth: 200
      },
      {
        headerName: 'Statut',
        field: 'isValidated',
        cellRenderer: StatusCellRendererComponent,
        sortable: true,
        minWidth: 200,
        maxWidth: 200
      },
      {
        headerName: 'Présence',
        field: 'presence',
        sortable: true
      }
    ];

    this.context = {componentParent: this};
    this.frameworkComponents = {
      statutCellRenderer: StatusCellRendererComponent,
      nameCellRenderer: NameCellRendererComponent
    };
  }

  ngOnDestroy(): void {
    this.alertService.clear();
  }

  ngOnInit() {
    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;
    this.rowSelection = 'multiple';

    this.gridUserPresenceService.listOfUserSportMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: EventOccurrenceDetailUserDTO[]) => {
        this.listOfUser = data;
        this.setAffichage();
      });
  }

  onGridSizeChanged(params) {
    this.gridOptions.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridOptions.animateRows = true;
    this.gridApi = params.api;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.suppressHorizontalScroll = true;
    this.gridOptions.api.sizeColumnsToFit();
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    const timeSheets = [];
    if (this.gridOptions.api) {
      this.listOfUser.forEach((user: EventOccurrenceDetailUserDTO) => {
        timeSheets.push(user.user.listOfTimesheet[0]);
        returnRows.push(this.getRow(user.user));
      }, this);

      this.gridOptions.api.setRowData(returnRows);
      this.gridUserPresenceService.changeListOfTimesheet(timeSheets);
    }

    this.gridUserPresenceService.changeUpdatedListOfUserSport(this.listOfUser);
  }

  /**
   * Extrait les infos d'un user pour l'affichagé sur une ligne
   * @param User, jeune à afficher
   */
  getRow(user: User) {
    const year = user.listOfYears[0];

    return {
      id: user.id,
      name: this.formatService.formatName(user),
      firstName: user.firstName,
      lastName: user.lastName,
      sex: user.sex,
      mobilePhone: user.phone,
      birthday: this.formatService.formatDate(user.birthday),
      isValidated: year.isValidated,
      isConnected: user.isConnected,
      presence: this.formatPresence(
        user.listOfTimesheet[0].isPresent,
        user.listOfTimesheet[0].isLate
      ),
      timesheet: user.listOfTimesheet[0]
    };
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  formatPresence(isPresent, isLate): string {
    let stringToDisplay: string;
    if (isLate) {
      stringToDisplay = 'En retard';
    } else {
      switch (isPresent) {
        case true:
          stringToDisplay = 'Présent';
          break;

        case false:
          stringToDisplay = 'Absent';
          break;

        default:
          stringToDisplay = 'À saisir';
          break;
      }
    }

    return stringToDisplay;
  }

  onValidPresence() {
    this.loading = true;
    const selectedRows = this.gridOptions.api.getSelectedRows();
    const selectedTimeSheet: Timesheet[] = [];

    if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        const newTimesheet: Timesheet = row.timesheet;
        newTimesheet.isPresent = true;
        newTimesheet.isLate = false;
        selectedTimeSheet.push(newTimesheet);
        this.gridUserPresenceService.changeOneTimesheet(newTimesheet);
      });

      this.updateTimesheet(selectedTimeSheet);
    } else {
      this.onErrorAlert('Merci de sélectionner au moins un jeune');
      this.loading = false;
    }
  }

  onValidAbsence() {
    this.loading = true;
    const selectedRows = this.gridOptions.api.getSelectedRows();
    const selectedTimeSheet: Timesheet[] = [];

    if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        const newTimeshheet: Timesheet = row.timesheet;
        newTimeshheet.isPresent = false;
        newTimeshheet.isLate = false;
        selectedTimeSheet.push(newTimeshheet);
        this.gridUserPresenceService.changeOneTimesheet(newTimeshheet);
      });

      this.updateTimesheet(selectedTimeSheet);
    } else {
      this.onErrorAlert('Merci de sélectionner au moins un jeune');
      this.loading = false;
    }
  }

  onValidLate() {
    this.loading = true;
    const selectedRows = this.gridOptions.api.getSelectedRows();
    const selectedTimeSheet: Timesheet[] = [];

    if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        const newTimeshheet: Timesheet = row.timesheet;
        newTimeshheet.isPresent = true;
        newTimeshheet.isLate = true;
        selectedTimeSheet.push(newTimeshheet);
        this.gridUserPresenceService.changeOneTimesheet(newTimeshheet);
      });

      this.updateTimesheet(selectedTimeSheet);
    } else {
      this.onErrorAlert('Merci de sélectionner au moins un jeune');
      this.loading = false;
    }
  }

  updateTimesheet(listOfTimesheet: Timesheet[]) {
    this.timesheetWebservice
      .putUpdateTimesheetList(listOfTimesheet)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (timesheets: Timesheet[]) => {
          const message = 'Les présences ont été mises à jour.';
          this.onSuccessAlert(message);

          timesheets.forEach((timesheet: Timesheet) => {
            const index = this.listOfUser.findIndex((user) => user.user.id === timesheet.idUser);
            this.listOfUser[index].user.listOfTimesheet[0] = timesheet;
          });
          this.setAffichage();
        },
        error: () => {
          const message = 'Nous avons rencontré une erreur lors de la mise à jour';
          this.onErrorAlert(message);
        }
      })
      .add(() => {
        this.loading = false;
      });
  }

  onSuccessAlert(message: string) {
    this.alertService.clear();
    this.alertService.success(message);
  }

  onErrorAlert(message: string) {
    this.alertService.clear();
    this.alertService.error(message);
  }
}
