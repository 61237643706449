import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Timesheet} from '../model/event/timesheet.model';
import {EventOccurrenceDetailUserDTO} from '../model/dto/event-occurrence-detail-user.dto';
import {EventRiDetailUserDto} from '../model/dto/event-ri-detail-user.dto';

@Injectable({
  providedIn: 'root'
})
export class GridUserPresenceService {
  private readonly dataListOfUserSport = new BehaviorSubject([]);
  listOfUserSportMessager = this.dataListOfUserSport.asObservable();

  private readonly dataUpdatedListOfUserSport = new BehaviorSubject([]);
  updatedlistOfUserSportMessager = this.dataUpdatedListOfUserSport.asObservable();

  private readonly dataListOfTimesheet = new BehaviorSubject([]);
  listOfTimesheet = this.dataListOfTimesheet.asObservable();

  private readonly dataListOfUserJob = new BehaviorSubject([]);
  listOfUserJobMessager = this.dataListOfUserJob.asObservable();

  private readonly dataUpdatedListOfUserJob = new BehaviorSubject([]);
  updatedlistOfUserJobMessager = this.dataUpdatedListOfUserJob.asObservable();

  changeListOfUserSport(list: EventOccurrenceDetailUserDTO[]) {
    this.dataListOfUserSport.next(list);
  }

  changeUpdatedListOfUserSport(list: EventOccurrenceDetailUserDTO[]) {
    this.dataUpdatedListOfUserSport.next(list);
  }

  changeListOfTimesheet(list: Timesheet[]) {
    this.dataListOfTimesheet.next(list);
  }

  changeOneTimesheet(timesheet: Timesheet) {
    this.listOfTimesheet.subscribe((value: Timesheet[]) => {
      const timesheetOld = value.findIndex((i) => i.idUser === timesheet.idUser); // User unique sur une occurrence
      value[timesheetOld] = timesheet;
    });
  }

  getCurrentListOfTimesheet() {
    return this.dataListOfTimesheet.getValue();
  }

  changeListOfUserJob(list: EventRiDetailUserDto[]) {
    this.dataListOfUserJob.next(list);
  }

  changeUpdatedListOfUserJob(list: EventRiDetailUserDto[]) {
    this.dataUpdatedListOfUserJob.next(list);
  }
}
