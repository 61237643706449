import {Component, OnInit} from '@angular/core';
import {FormatService} from 'src/app/services/format.service';
import {SelectOption} from 'src/app/model/form/select-option.model';
import {ParamWebservice} from 'src/app/services/webservices/param.webservice';
import {debounceTime} from 'rxjs/operators';
import {Interview} from 'src/app/model/user/interview.model';
import {InterviewTheme} from 'src/app/model/user/interview-theme.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {YoungWebservice} from 'src/app/services/webservices/young.webservice';
import {AlertService} from 'src/app/services/alert.service';
import {FilterUser} from 'src/app/model/filter/filter-relation-binome/filter-user.model';
import {Observable, of} from 'rxjs';
import {UserWebservice} from 'src/app/services/webservices/user.webservice';
import {InterviewDurationEnum} from 'src/app/model/enums/interview-duration.enum';
import {CustomDateAdapter} from 'src/app/utils/custom-date-adapter';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {FilterRelationBinomeIntervenantDTO} from 'src/app/model/filter/filter-relation-binome/filter-relation-binome-intervenant.dto';
import {User} from 'src/app/model/user/user.model';
import {PInterviewPlace} from 'src/app/model/param/p-interview-place.model';
import {PInterviewTheme} from 'src/app/model/param/p-interview-theme.model';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {SwitchComponent} from '../../form-parts/form-items/switch/switch.component';
import {SelectMultipleComponent} from '../../form-parts/form-items/select-multiple/select-multiple.component';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AsyncPipe, NgClass, NgFor, NgIf} from '@angular/common';
import {AlertComponent} from '../../alert/alert/alert.component';
import {getEnumKeysAsNumber, isNotNullOrUndefined} from 'src/app/utils/utils.static';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-dialog-add-interview',
  templateUrl: './dialog-add-interview.component.html',
  styleUrls: ['./dialog-add-interview.component.scss'],
  providers: [{provide: DateAdapter, useClass: CustomDateAdapter}],
  standalone: true,
  imports: [
    MatDialogTitle,
    AlertComponent,
    MatDialogContent,
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatDatepickerModule,
    MatIconModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    NgIf,
    SelectMultipleComponent,
    SwitchComponent,
    MatAutocompleteModule,
    MatDialogActions,
    MatButtonModule,
    MatProgressSpinnerModule,
    AsyncPipe
  ]
})
export class DialogAddInterviewComponent extends Destroyed implements OnInit {
  interview: Interview;
  idUser: number;
  interviewPlaces = [];
  interviewThemes = [];

  interviewFormGroup: UntypedFormGroup;

  filteredSdlvUser: FilterUser;
  filteredIntervenant: FilterUser;

  isReadOnly: boolean;

  public filteredSdlvUsersObservable: Observable<FilterRelationBinomeIntervenantDTO[]>;
  public filteredIntervenantObservable: Observable<Paged<User>>;
  public interviewDurationSelect: InterviewDurationSelect[];
  public isSaving: boolean = false;

  constructor(
    public readonly formatService: FormatService,
    private readonly dialogRef: MatDialogRef<DialogAddInterviewComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly paramWebservice: ParamWebservice,
    private readonly youngWebservice: YoungWebservice,
    private readonly alertService: AlertService,
    private readonly userWebservice: UserWebservice
  ) {
    super();
    this.interviewFormGroup = this.fb.group({
      interviewDate: [null, Validators.required],
      duration: [null, Validators.required],
      idDoneBy: [null],
      idIntervenant: [null],
      interviewPlace: [null, Validators.required],
      interviewThemes: [null, Validators.required],
      familyPresence: false,
      comment: null
    });

    this.initPlaceInterview();
    this.initThemeInterview();
    this.initInterviewDuration();

    this.fInterview.duration.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      this.onChangeDuration(value);
    });
  }

  get fInterview() {
    return this.interviewFormGroup.controls;
  }

  ngOnInit() {
    this.filteredSdlvUser = new FilterUser();
    this.filteredIntervenant = new FilterUser();
    if (this.interview && this.interview.id > 0) {
      this.youngWebservice
        .getInterview(this.interview.id)
        .pipe(this.untilDestroyed())
        .subscribe((value: Interview) => {
          this.interview = value;
          if (value.duration === undefined) {
            this.interview.duration = this.formatService.convertInterviewDurationToNumber('ZERO');
          }
          this.setForm();
          this.toggleDisable(true);
          this.isReadOnly = true;
        });
    } else {
      this.isReadOnly = false;
      this.interview = new Interview();
    }
  }

  initPlaceInterview() {
    this.paramWebservice
      .getAllPlaceInterview()
      .pipe(this.untilDestroyed())
      .subscribe((content: PInterviewPlace[]) => {
        content.forEach((interest) => {
          const option = new SelectOption();
          option.id = interest.id;
          option.name = interest.label;
          this.interviewPlaces.push(option);
        });
      });
  }

  initThemeInterview() {
    this.paramWebservice
      .getAllThemeInterview()
      .pipe(this.untilDestroyed())
      .subscribe((content: PInterviewTheme[]) => {
        content.forEach((interest) => {
          const option = new SelectOption();
          option.id = interest.id;
          option.name = interest.label;
          this.interviewThemes.push(option);
        });
      });
  }

  onChangeDuration(value: InterviewDurationEnum) {
    this.interview.duration = this.formatService.convertInterviewDurationToNumber(
      InterviewDurationEnum[value]
    );
  }

  saveInterview() {
    this.isSaving = true;
    this.checkBeforeSubmit();
    if (this.interviewFormGroup.valid && this.isSaving) {
      this.interview.idYoung = this.idUser;
      this.interview.interviewDate = this.fInterview.interviewDate.value;
      this.interview.idPInterviewPlace = this.fInterview.interviewPlace.value;
      this.interview.idDoneBy = this.fInterview.idDoneBy.value;
      this.interview.idIntervenant = this.fInterview.idIntervenant.value;
      this.interview.familyPresence = !!this.fInterview.familyPresence.value;

      this.interview.comment = this.fInterview.comment.value;
      this.interview.listOfInterviewTheme = [];
      if (this.fInterview.interviewThemes.value) {
        this.fInterview.interviewThemes.value.forEach((value) => {
          const interviewTheme = new InterviewTheme();
          interviewTheme.idPInterviewTheme = value;
          this.interview.listOfInterviewTheme.push(interviewTheme);
        });
      }

      this.youngWebservice
        .addOrUpdateInterview(this.interview)
        .pipe(this.untilDestroyed())
        .subscribe(() => {
          if (this.interview.id > 0) {
            this.toggleDisable(true);
            this.isSaving = false;
          } else {
            this.dialogRef.close();
            this.isSaving = false;
          }
        });
    } else {
      this.isSaving = false;
    }
  }

  compareWith(e1, e2) {
    return e1 === e2;
  }

  setForm() {
    this.idUser = this.interview.idYoung;

    if (this.interview.interviewDate) {
      this.fInterview.interviewDate.setValue(this.interview.interviewDate);
    }

    if (this.isInterviewValid()) {
      this.fInterview.duration.setValue(
        this.formatService.convertDurationToInterviewDurationEnum(this.interview.duration)
      );
    }

    if (this.interview.familyPresence) {
      this.fInterview.familyPresence.setValue(this.interview.familyPresence);
    }

    if (this.interview.idPInterviewPlace) {
      this.fInterview.interviewPlace.setValue(this.interview.idPInterviewPlace);
    }

    if (this.interview.listOfInterviewTheme && this.interview.listOfInterviewTheme.length > 0) {
      this.fInterview.interviewThemes.setValue(
        this.interview.listOfInterviewTheme.map((item) => item.idPInterviewTheme)
      );
    }

    if (this.interview.comment) {
      this.fInterview.comment.setValue(this.interview.comment);
    }

    if (this.interview.idDoneBy) {
      this.fInterview.idDoneBy.setValue(this.interview.idDoneBy);
      this.filteredSdlvUser.name =
        this.interview.doneByUser.firstName + ' ' + this.interview.doneByUser.lastName;
    }

    if (this.interview.idIntervenant) {
      this.fInterview.idIntervenant.setValue(this.interview.idIntervenant);
      this.filteredIntervenant.name =
        this.interview.intervenant.user.firstName + ' ' + this.interview.intervenant.user.lastName;
    }
  }

  isInterviewValid() {
    return isNotNullOrUndefined(this.interview.duration) && this.interview.duration >= 0;
  }

  toggleDisable(value: boolean) {
    this.isReadOnly = value;
    if (this.isReadOnly) {
      if (this.interview.id > 0) {
        this.fInterview.interviewDate.disable();
        this.fInterview.duration.disable();
        this.fInterview.familyPresence.disable();
        this.fInterview.interviewPlace.disable();
        this.fInterview.interviewThemes.disable();
        this.fInterview.comment.disable();
      } else {
        this.dismiss();
      }
    } else {
      this.fInterview.interviewDate.enable();
      this.fInterview.duration.enable();
      this.fInterview.familyPresence.enable();
      this.fInterview.interviewPlace.enable();
      this.fInterview.interviewThemes.enable();
      this.fInterview.comment.enable();
    }
  }

  public onChangeSdlvUsers() {
    this._filterSdlvUsersList(this.filteredSdlvUser.name);
  }

  public onChangeIntervenant() {
    this._filterIntervenantList(this.filteredIntervenant.name);
  }

  displayFn(item): string {
    return item && item.firstName && item.lastName ? item.firstName + ' ' + item.lastName : '';
  }

  getSdlvUser(event) {
    this.fInterview.idDoneBy.setValue(event.id);
  }

  getIntervenant(event) {
    this.fInterview.idIntervenant.setValue(event.id);
  }

  dismiss() {
    this.dialogRef.close();
  }

  resetDoneBy() {
    this.filteredIntervenant.name = null;
    this.filteredIntervenantObservable = of(new Paged<User>());
    this.fInterview.idDoneBy.reset();
  }

  private initInterviewDuration() {
    this.interviewDurationSelect = [];

    getEnumKeysAsNumber(InterviewDurationEnum).forEach((i) =>
      this.interviewDurationSelect.push({
        id: i,
        name: this.formatService.formatInterviewDuration(InterviewDurationEnum[i])
      })
    );
  }

  private checkBeforeSubmit() {
    const errors = [];

    if (this.fInterview.interviewDate.invalid) {
      errors.push("Date de l'entretien");
    }

    if (this.fInterview.duration.invalid) {
      errors.push("Durée de l'entretien");
    }

    if (this.fInterview.interviewPlace.invalid) {
      errors.push("Lieu de l'entretien");
    }

    if (this.fInterview.interviewThemes.invalid) {
      errors.push("Thème(s) de l'entretien");
    }

    if (!this.fInterview.idDoneBy.value && !this.fInterview.idIntervenant.value) {
      errors.push('Il faut renseigner au minimum un des deux intervenants.');
    }

    if (errors.length > 0) {
      const message = 'Les champs suivants sont manquants ou erronés : ' + errors.join(', ');

      this.alertService.clear('interviewAlert');
      this.alertService.error(message, 'interviewAlert');
      this.isSaving = false;
    }
  }

  private _filterSdlvUsersList(value) {
    if (typeof value === 'string') {
      let filterValue = this.formatService.clearAccent(value.toLowerCase().trim());
      const valueToSend = new FilterUser();
      filterValue = filterValue.replace(/\s/g, '');
      valueToSend.name = filterValue;
      const tempList: unknown = [];
      if (value.length > 1) {
        this.userWebservice
          .getAllUserSDLVByName(valueToSend)
          .pipe(debounceTime(1000))
          .subscribe({
            next: (res) => {
              this.filteredSdlvUsersObservable = of(res);
            }
          });
      }
    }
  }

  private _filterIntervenantList(value) {
    if (typeof value === 'string') {
      let filterValue = this.formatService.clearAccent(value.toLowerCase().trim());
      const valueToSend = new FilterUser();
      filterValue = filterValue.replace(/\s/g, '');
      valueToSend.name = filterValue;
      if (value.length > 1) {
        this.userWebservice
          .getAllIntervenant(0, valueToSend)
          .pipe(debounceTime(1000))
          .subscribe({
            next: (res) => {
              this.filteredIntervenantObservable = of(res);
            }
          });
      }
    }
  }
}

interface InterviewDurationSelect {
  id: number;
  name: string;
}
