<div class="m-0 p-0" id="grid-wrapper-event">
  <ag-grid-angular #agGrid2
                   (gridReady)="onEventGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   (selectionChanged)="onSelectionChanged()"
                   [animateRows]="true"
                   [columnDefs]="columnEventDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridEventOptions"
                   [localeText]="langue"
                   [paginationPageSize]="paginationSize"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham"
                   pagination="true"
                   rowSelection="multiple">
  </ag-grid-angular>
</div>
