import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {GridApi, GridOptions} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {ProgramDTO} from '../../../../model/dto/program.dto';
import {TreeItemNode} from '../../../../model/event/treeData/tree-item-node.model';
import {AlertService} from '../../../../services/alert.service';
import {ActionBtnRendererComponent} from '../custom-cell/action-btn-renderer/action-btn-renderer.component';
import {UserPerRoleDTO} from '../../../../model/dto/user-per-role.dto';
import {RoleUserWebservice} from '../../../../services/webservices/role-user.webservice';
import {MatDialog} from '@angular/material/dialog';
import {DialogDefaultComponent} from '../../dialog/dialog-default/dialog-default.component';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AlertComponent} from '../../alert/alert/alert.component';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-user-role-with-delete',
  templateUrl: './grid-role-user-with-delete.component.html',
  styleUrls: ['./grid-role-user-with-delete.scss'],
  standalone: true,
  imports: [NgIf, AlertComponent, AgGridModule, AsyncPipe]
})
export class GridRoleUserWithDeleteComponent extends Destroyed implements OnInit, OnChanges {
  @Input() roleList: UserPerRoleDTO[] = [];
  @Input() program: ProgramDTO[];
  @Input() place: TreeItemNode[];
  @Output() deleteEmitter = new EventEmitter<UserPerRoleDTO>();
  @Input() disableAutoDelete;
  placeInline: TreeItemNode[] = [];

  listRoleRemove: UserPerRoleDTO[] = [];

  rowClassRules;
  columnEventDefs;
  isLoading = true;
  gridOptions: GridOptions;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  paginationSize = 10;
  context;
  langue = agGridLanguageFr;
  private gridApi: GridApi;

  constructor(
    private readonly roleuserWS: RoleUserWebservice,
    private readonly dialog: MatDialog,
    private readonly alertService: AlertService
  ) {
    super();
    this.context = {componentParent: this};
  }

  ngOnInit() {
    this.columnEventDefs = [
      {
        headerName: 'Pole',
        field: 'poleType',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Position',
        field: 'positionType',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Programme',
        field: 'programme',
        sortable: true,
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: 'Lieux',
        field: 'place',
        sortable: true,
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: '',
        field: 'delete',
        cellRenderer: ActionBtnRendererComponent,
        sortable: true,
        minWidth: 50,
        maxWidth: 75
      }
    ];

    // init grid event
    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;
  }

  // Event grid
  onGridSizeChanged(params) {
    const grid = document.getElementById('grid-wrapper');
    if (grid) {
      const gridWidth = grid.offsetWidth;
      const columnsToShow = [];
      const columnsToHide = [];
      const allColumns = params.columnApi.getColumns();
      let totalColsWidth = 0;

      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
    }

    params.api.sizeColumnsToFit();
  }

  onEventGridReady(params) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.animateRows = true;
    this.gridApi = params.api;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    if (this.gridOptions && this.gridOptions.api) {
      const list = this.roleList
        ? this.roleList.filter((value) => {
            return !this.listRoleRemove.some((remove) => {
              return (
                remove.idUser === value.idUser &&
                remove.idRole === value.idRole &&
                remove.idPlace === value.idPlace &&
                remove.idProgram === value.idProgram
              );
            });
          })
        : [];
      this.gridOptions.api.setRowData(list.map((value) => this.getRow(value)));
    }
  }

  /**
   * Extrait les infos d'un event pour l'affichagé sur une ligne
   * @param obj
   */
  getRow(obj: UserPerRoleDTO) {
    const pg = this.program ? this.program.find((value) => value.id === obj.idProgram) : undefined;
    const pl = this.placeInline
      ? this.placeInline.find((value) => value.id === obj.idPlace)
      : undefined;
    return {
      id: obj.id,
      idUser: obj.idUser,
      idRole: obj.idRole,
      poleType: obj.poleType,
      positionType: obj.positionType,
      idProgram: obj.idProgram,
      programme: pg ? pg.programType : '',
      idPlace: obj.idPlace,
      place: pl ? pl.name : '',
      deleteAction: (data: UserPerRoleDTO) => {
        this.deleteAction(data);
      }
    };
  }

  loadPlace() {
    this.placeInline = this.currentNode([], this.place);
  }

  currentNode(array: TreeItemNode[], nodes: TreeItemNode[]) {
    nodes.forEach((node: TreeItemNode) => {
      if (node) {
        array.push({
          id: node.id,
          name: node.name
        });
        if (node.listOfChilds) {
          this.currentNode(array, node.listOfChilds);
        }
      }
    });
    return array;
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setAffichage();
    if (this.roleList) {
      this.isLoading = false;
    }
    if (this.place && (!this.placeInline || this.placeInline.length === 0)) {
      this.loadPlace();
    }
  }

  private deleteAction(data: UserPerRoleDTO) {
    if (data) {
      if (!this.disableAutoDelete || data.idUser > 0) {
        const dialogRef = this.dialog.open(DialogDefaultComponent);

        dialogRef.componentInstance.message = 'Êtes-vous sûr de vouloir supprimer ce rôle ?';
        dialogRef.componentInstance.title = 'Supprimer';
        dialogRef.componentInstance.no = 'Annuler';
        dialogRef.componentInstance.yes = 'Valider';

        dialogRef
          .afterClosed()
          .pipe(this.untilDestroyed())
          .subscribe((confirm) => {
            if (!confirm) {
              return;
            }
            this.roleuserWS
              .deleteRoleUser(data.id)
              .pipe(this.untilDestroyed())
              .subscribe({
                next: (value) => {
                  if (value) {
                    this.deleteEmitter.emit(data);
                    this.listRoleRemove.push(data);
                    this.setAffichage();
                  } else {
                    this.alertService.clear();
                    this.alertService.error(
                      'Impossible de supprimer le rôle, veuillez réessayer ultérieurement'
                    );
                  }
                },
                error: () => {
                  this.alertService.clear();
                  this.alertService.error(
                    'Impossible de supprimer le rôle, veuillez réessayer ultérieurement'
                  );
                }
              });
          });
      } else {
        this.deleteEmitter.emit(data);
      }
    }
  }
}
