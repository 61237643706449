import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridApi, GridOptions} from '@ag-grid-community/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {StatusCellRendererComponent} from '../custom-cell/status-cell-renderer/status-cell-renderer.component';
import {Router} from '@angular/router';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {ConnectedStatusEnum} from '../../../../model/enums/connected-status.enum';
import {ProfileYoungSportService} from '../../../users/young/listing/profile-young/profile-young-sport/profile-young-sport.service';
import {RegisteredYoungSportDTO} from '../../../../model/dto/registered-young-sport.dto';
import {Destroyed} from '../../directives/destroyed.directive';
import {OpenNewTabBtnComponent} from '../custom-cell/open-new-tab-btn/open-new-tab-btn.component';
import {BooleanCellRendererComponent} from '../custom-cell/boolean-cell-renderer/boolean-cell-renderer.component';
import {EditBtnRendererComponent} from '../custom-cell/edit-btn-renderer/edit-btn-renderer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PaginationComponent} from '../../pagination/pagination.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-profile-young-sport',
  templateUrl: './grid-profile-young-sport.component.html',
  styleUrls: ['./grid-profile-young-sport.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    NgIf,
    AgGridModule,
    PaginationComponent,
    MatProgressSpinnerModule,
    AsyncPipe
  ]
})
export class GridProfileYoungSportComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Input() itemPerPage;
  @Output() pageChange = new EventEmitter<unknown>();
  listOfYoung: RegisteredYoungSportDTO[];
  itemsYoung: Paged<RegisteredYoungSportDTO>;

  rowClassRules;
  gridYoungSportApi: GridApi;
  columnYoungDefs;
  isYoungLoading = true;
  gridYoungSportOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly gridProfileYoungSportDataService: ProfileYoungSportService,
    private readonly formatService: FormatService,
    private readonly router: Router
  ) {
    super();
    this.columnYoungDefs = [
      {
        headerName: '',
        colId: 'btn',
        cellRenderer: OpenNewTabBtnComponent,
        suppressSizeToFit: true,
        width: 70,
        flex: 0
      },
      {
        headerName: 'Nom',
        field: 'name',
        colId: 'name',
        sortable: true,
        cellRenderer: NameCellRendererComponent,
        flex: 2
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        suppressSizeToFit: true,
        width: 55,
        cellStyle: (params) => {
          return {display: 'flex', alignItems: 'center', justifyContent: 'center'};
        }
      },
      {
        headerName: 'Naissance',
        field: 'birthday',
        sortable: true,
        suppressSizeToFit: true,
        width: 100,
        cellStyle: (params) => {
          return {display: 'flex', alignItems: 'center', justifyContent: 'center'};
        }
      },
      {
        headerName: 'Adresse',
        field: 'address',
        sortable: true,
        flex: 1
      },
      {
        headerName: 'Centre',
        field: 'nameCenter',
        sortable: true,
        flex: 1
      },
      {
        headerName: 'Région',
        field: 'nameRegion',
        sortable: true,
        flex: 1
      },
      {
        headerName: 'Statut',
        field: 'isValidated',
        colId: 'status',
        cellRenderer: StatusCellRendererComponent,
        sortable: true,
        suppressSizeToFit: true,
        width: 65,
        cellStyle: (params) => {
          return {display: 'flex', alignItems: 'center', justifyContent: 'center'};
        },
        flex: 0
      },
      {
        headerName: "Droit à l'image",
        headerTooltip: "Droit à l'image",
        headerComponentParams: {template: '<mat-icon class="header-icon-grid">portrait</mat-icon>'},
        field: 'imageRightAutorization',
        colId: 'imageRightAutorization',
        cellRenderer: BooleanCellRendererComponent,
        width: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        flex: 0
      },
      {
        headerName: 'Droit de contact',
        headerTooltip: 'Droit de contact',
        headerComponentParams: {template: '<mat-icon class="header-icon-grid">phone</mat-icon>'},
        field: 'contactAccepted',
        colId: 'contactAccepted',
        cellRenderer: BooleanCellRendererComponent,
        width: 130,
        maxWidth: 130,
        suppressSizeToFit: true,
        flex: 0
      },
      {
        headerName: '',
        colId: 'btn',
        cellRenderer: EditBtnRendererComponent,
        suppressSizeToFit: true,
        width: 70,
        pinned: 'right'
      }
    ];
    this.context = {componentParent: this};
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.gridYoungSportOption = {
      defaultColDef: {
        flex: 1,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridProfileYoungSportDataService.youngListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<RegisteredYoungSportDTO>) => {
        if (data) {
          this.itemsYoung = data;
          this.listOfYoung = data.items;
          this.setAffichage();
        }
      });
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridYoungReady(params) {
    this.gridYoungSportOption.api.sizeColumnsToFit();
    this.gridYoungSportOption.animateRows = true;
    this.gridYoungSportApi = params.api;
    this.gridYoungSportOption.suppressCellFocus = true;
    this.gridYoungSportOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridYoungSportOption.api) {
      this.itemsYoung.items.forEach((young: RegisteredYoungSportDTO) => {
        returnRows.push(this.getRowFromYoung(young));
      }, this);

      this.gridYoungSportOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param young
   */
  getRowFromYoung(young: RegisteredYoungSportDTO) {
    return {
      id: young.id,
      name: this.formatService.formatName(young),
      firstName: young.firstName,
      lastName: young.lastName,
      sex: young.sex,
      birthday: this.formatService.formatDate(young.birthday),
      address: young.address,
      nameCenter: young.nameCenter,
      nameRegion: young.nameRegion,
      isValidated: young.isValidated,
      isConnected: young.isConnected,
      contactAccepted: !!young.contactAccepted,
      imageRightAutorization: !!young.imageRightAutorization
    };
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  onRowClick(event) {
    if (event.colDef.colId !== 'btn') {
      if (event.data.isConnected !== ConnectedStatusEnum.WEB) {
        this.router.navigate(['young-info', event.data.id]);
      } else {
        this.router.navigate(['young-validation', event.data.id]);
      }
    }
  }

  export() {
    const processCells = (cell) => {
      const cellVal = cell.value;

      if (cell.column.colId === 'status') {
        return this.formatService.formatIsValidatedStatus(cellVal);
      } else if (cell.column.colId === 'sex') {
        return this.formatService.formatSex(cellVal, true);
      } else {
        return cellVal;
      }
    };

    this.gridYoungSportOption.api.exportDataAsCsv({
      processCellCallback: (params) => processCells(params)
    });
  }

  rowSelected() {
    if (this.gridYoungSportApi) {
      return this.gridYoungSportApi.getSelectedNodes().length <= 0;
    } else {
      return true;
    }
  }

  emitPageChange() {
    this.pageChange.emit();
  }
}
