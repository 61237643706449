import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {ModuleWebservice} from 'src/app/services/webservices/module.webservice';
import {RoleSettingDataService} from 'src/app/services/role-setting-data.service';
import {Module} from 'src/app/model/role/module.model';
import {Destroyed} from '../../../directives/destroyed.directive';
import {ToastService} from '../../../../../services/toast.service';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-delete-module-btn-renderer',
  templateUrl: './delete-module-btn-renderer.component.html',
  styleUrls: ['./delete-module-btn-renderer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class DeleteModuleBtnRendererComponent
  extends Destroyed
  implements ICellRendererAngularComp
{
  idModule: number;
  nameModule: string;

  constructor(
    private readonly moduleWebservice: ModuleWebservice,
    private readonly roleSettingDataService: RoleSettingDataService,
    private readonly toastService: ToastService
  ) {
    super();
  }

  refresh(): boolean {
    return null;
  }

  agInit(params): void {
    this.idModule = params.data.id;
    this.nameModule = params.data.name;
  }

  onBtnClick() {
    this.moduleWebservice
      .deleteModule(this.idModule)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: () => {
          this.refreshFullModuleList();
          this.onSuccessAlert();
        },
        error: () => {
          this.onErrorAlert();
        }
      });
  }

  refreshFullModuleList() {
    this.moduleWebservice
      .getAllModules()
      .pipe(this.untilDestroyed())
      .subscribe((data: Module[]) => {
        if (data) {
          this.roleSettingDataService.changeFullModuleList(data);
        }
      });
  }

  onSuccessAlert() {
    this.toastService.success('Le module "' + this.nameModule + '" a bien été supprimé');
  }

  onErrorAlert() {
    this.toastService.error('Une erreur est survenue');
  }
}
