import {Component} from '@angular/core';
import {MatDialogActions, MatDialogContent, MatDialogRef} from '@angular/material/dialog';
import {Destroyed} from '../../directives/destroyed.directive';
import {Occurrence} from '../../../../model/event/occurrence.model';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FormsModule} from '@angular/forms';

@Component({
  selector: 'app-dialog-edit-occurrence',
  templateUrl: './dialog-edit-occurrence.component.html',
  styleUrls: ['./dialog-edit-occurrence.component.scss'],
  standalone: true,
  imports: [MatDialogContent, FormsModule, MatTooltipModule, MatDialogActions]
})
export class DialogEditOccurrenceComponent extends Destroyed {
  newTimeOccurrence: string;
  occurrence: Occurrence;

  constructor(public dialogRef: MatDialogRef<DialogEditOccurrenceComponent>) {
    super();
  }

  updateOccurrence(): void {
    this.occurrence.time = this.getMinuteFromTime(this.newTimeOccurrence);
    this.dialogRef.close(this.occurrence.time);
  }

  cancelUpdateOccurrence(): void {
    this.dialogRef.close();
  }

  getMinuteFromTime(time: string): number {
    const hours = +time.split(':')[0];
    const minutes = +time.split(':')[1];
    return hours * 60 + minutes;
  }
}
