import {Interview} from './interview.model';
import {PInterviewTheme} from '../param/p-interview-theme.model';

export class InterviewTheme {
  id: number;
  idInterview: number;
  idPInterviewTheme: number;

  interview: Interview;
  pInterviewTheme: PInterviewTheme;
}
