import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Paged} from 'src/app/model/response/paged.model';
import {IntervenantDTO} from 'src/app/model/dto/intervenant.dto';

@Injectable({
  providedIn: 'root'
})
export class GridIntervenantDataService {
  private readonly dataIntervenantList = new BehaviorSubject(null);
  public intervenantListMessager = this.dataIntervenantList.asObservable();

  changeIntervenantList(listOfIntervenant: Paged<IntervenantDTO>) {
    this.dataIntervenantList.next(listOfIntervenant);
  }
}
