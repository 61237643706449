import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {RoleWebservice} from 'src/app/services/webservices/role.webservice';
import {RoleSettingDataService} from 'src/app/services/role-setting-data.service';
import {Role} from 'src/app/model/role/role.model';
import {AlertService} from 'src/app/services/alert.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-delete-role-btn-renderer',
  templateUrl: './delete-role-btn-renderer.component.html',
  styleUrls: ['./delete-role-btn-renderer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class DeleteRoleBtnRendererComponent extends Destroyed implements ICellRendererAngularComp {
  idRole: number;
  poleType: string;
  positionType: string;

  constructor(
    private readonly roleWebservice: RoleWebservice,
    private readonly roleSettingDataService: RoleSettingDataService,
    private readonly alertService: AlertService
  ) {
    super();
  }

  refresh(): boolean {
    return null;
  }

  agInit(params): void {
    this.idRole = params.data.id;
    this.poleType = params.data.poleType;
    this.positionType = params.data.positionType;
  }

  onBtnClick() {
    this.roleWebservice
      .deleteRole(this.idRole)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (data) => {
          this.refreshFullRoleList();
          this.onSuccessAlert();
        },
        error: () => {
          this.onErrorAlert();
        }
      });
  }

  refreshFullRoleList() {
    this.roleWebservice
      .getAllRoles()
      .pipe(this.untilDestroyed())
      .subscribe((data: Role[]) => {
        if (data) {
          this.roleSettingDataService.changeFullRoleList(data);
        }
      });
  }

  onSuccessAlert() {
    this.clearAlert();
    let message: string;
    if (this.poleType) {
      message = 'Le role "' + this.poleType + ' - ' + this.positionType + '" a bien été supprimé';
    } else {
      message = 'Le role "' + this.positionType + '" a bien été supprimé';
    }
    this.alertService.success(message);
  }

  onErrorAlert() {
    this.clearAlert();
    this.alertService.error('une erreur est survenue');
  }

  clearAlert() {
    this.alertService.clear();
  }
}
