import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {UserRoleDTO} from '../../model/dto/user-role.dto';
import {UserPerRoleDTO} from '../../model/dto/user-per-role.dto';
import {RoleDTO} from '../../model/dto/role.dto';

@Injectable({
  providedIn: 'root'
})
export class RoleUserWebservice {
  private readonly root = `${environment.envApiUrl}/roleUsers`;

  constructor(private readonly http: HttpClient) {}

  /**
   * return roleUser
   * dependencies : no included role
   */
  getRoleUser(): Observable<UserRoleDTO[]> {
    return this.http.get(`${this.root}`).pipe(map((value) => value as UserRoleDTO[]));
  }

  /**
   * Add userRole in DB, no delete
   * @param listOfRole
   */
  postRoleUser(listOfRole: UserRoleDTO[]) {
    return this.http.post(`${this.root}`, listOfRole);
  }

  /**
   * Delete roleUser by id
   * @param idRoleUser
   */
  deleteRoleUser(idRoleUser: number) {
    return this.http.delete(`${this.root}/${idRoleUser}`);
  }

  /**
   * Delete multiple roleUser
   * @param listOfRoleUser
   */
  deleteListOfRoleUser(listOfRoleUser: UserRoleDTO[]) {
    return this.http.post(`${this.root}/remove`, listOfRoleUser);
  }

  /////////////////////// USER ////////////////////////////////////

  getRoleUserByUserId(idUser: number): Observable<UserPerRoleDTO[]> {
    return this.http
      .get(`${this.root}/user/${idUser}`)
      .pipe(map((value) => value as UserPerRoleDTO[]));
  }

  getAllRoleUser() {
    return this.http.get(`${this.root}/user/allRole`).pipe(map((value) => value as RoleDTO[]));
  }
}
