import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Router} from '@angular/router';
import {ProjectTableDTO} from '../../../../model/dto/project-table.dto';
import {GridYoungEnsembleDataService} from '../../../../services/grid-young-ensemble-data.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatCardModule} from '@angular/material/card';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-ensemble-subensemble',
  templateUrl: './grid-ensemble-subensemble.component.html',
  styleUrls: ['./grid-ensemble-subensemble.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, MatCardModule, AsyncPipe]
})
export class GridEnsembleSubEnsembleComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnEventDefs;
  isEventLoading = true;
  gridEventsOptions: GridOptions;
  rowData: unknown = null;
  eventList: ProjectTableDTO[] = [];
  domLayout: unknown = 'autoHeight';
  context;
  langue = agGridLanguageFr;

  constructor(
    private readonly formatService: FormatService,
    private readonly router: Router,
    private readonly gridEnsembleYoungService: GridYoungEnsembleDataService
  ) {
    super();

    // Definition column grid event
    this.columnEventDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: "Nom de l'activité",
        field: 'label',
        sortable: true,
        minWidth: 200
      },
      {
        headerName: "Type d'évènement",
        field: 'type',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Date',
        field: 'date',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Parcours',
        field: 'parcours',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Nb niveaux 1',
        field: 'numberLevel1',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Nb participations',
        field: 'nbparticipations',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Nb participants',
        field: 'nbYoungs',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Taux de présence',
        field: 'meanPresence',
        sortable: true,
        minWidth: 100
      }
    ];
    this.context = {componentParent: this};
  }

  ngOnInit() {
    // init grid Event
    this.gridEventsOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridEnsembleYoungService.subEnsembleListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        if (data) {
          this.eventList = data;
          this.setEventAffichage();
          if (this.eventList.length > 0) {
            this.isEventLoading = false;
          }
        }
      });
  }

  // event grid

  onGridSizeChanged(params) {
    this.gridEventsOptions.api.sizeColumnsToFit();
  }

  onEventGridReady(params) {
    this.gridEventsOptions.api.sizeColumnsToFit();
    this.gridEventsOptions.animateRows = true;
    this.gridEventsOptions.suppressCellFocus = true;
    this.gridEventsOptions.suppressHorizontalScroll = true;
    this.setEventAffichage();
  }

  setEventAffichage() {
    const returnRows = [];
    if (this.gridEventsOptions.api) {
      this.eventList.forEach((occu: ProjectTableDTO) => {
        returnRows.push(this.getRowFromEvent(occu));
      }, this);
      this.gridEventsOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un Event pour l'affichagé sur une ligne
   * @param projectDto, event à afficher
   */
  getRowFromEvent(projectDto: ProjectTableDTO) {
    return {
      id: projectDto.id,
      label: projectDto.label,
      type: projectDto.typeActivite,
      date: this.formatService.formatDate(projectDto.dateBegin),
      parcours: projectDto.parcours,
      numberLevel1: projectDto.nbEvents,
      nbparticipations: projectDto.nbParticipations,
      nbYoungs: projectDto.nbParticipants,
      meanPresence: projectDto.presencePercentage + '%'
    };
  }

  onRowClick(event) {
    if (event.colDef.colId !== 'btn') {
      this.router.navigate(['ensemble-detail', event.data.id]);
    }
  }
}
