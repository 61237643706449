import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Router} from '@angular/router';
import {SimpleUserPresenceDTO} from '../../../../model/dto/simple-user-presence.dto';
import {EnsembleWebservice} from '../../../../services/webservices/ensemble.webservice';
import {GridYoungEnsembleDataService} from '../../../../services/grid-young-ensemble-data.service';
import {ProjectYoungAndPresencesDTO} from '../../../../model/dto/ensemble.dto';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-young-ensemble',
  templateUrl: './grid-young-ensemble.component.html',
  styleUrls: ['./grid-young-ensemble.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridYoungEnsembleComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnEventDefs;
  isEventLoading = true;
  userPresenceDto: ProjectYoungAndPresencesDTO;
  youngList: SimpleUserPresenceDTO[];
  gridEventsOptions: GridOptions;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  gridEventApi: unknown;
  context;
  langue = agGridLanguageFr;

  constructor(
    private readonly formatService: FormatService,
    private readonly gridEnsembleYoungService: GridYoungEnsembleDataService,
    private readonly ensembleWebService: EnsembleWebservice,
    private readonly router: Router
  ) {
    super();

    this.context = {componentParent: this};
  }

  ngOnInit() {
    // Definition column grid event
    this.columnEventDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'Nom Prénom',
        field: 'name',
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        sortable: true,
        minWidth: 20,
        maxWidth: 50
      },
      {
        headerName: 'Age',
        field: 'age',
        sortable: true,
        minWidth: 25,
        maxWidth: 50
      },
      {
        headerName: 'RI',
        field: 'ri',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Statut',
        field: 'status',
        sortable: true,
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: 'Numéro de téléphone',
        field: 'phone',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Parcours',
        field: 'parcours',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: "Niveau d'étude",
        field: 'schoolLevel',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Présence',
        field: 'presence',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      }
    ];
    // init grid Event
    this.gridEventsOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridEnsembleYoungService.youngsListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        if (data) {
          this.isEventLoading = false;
          this.userPresenceDto = data;
          this.youngList = data.listJeunes;
          this.setEventAffichage();
        }
      });
  }

  // event grid

  onGridSizeChanged(params) {
    this.gridEventsOptions.api.sizeColumnsToFit();
  }

  onEventGridReady(params) {
    this.gridEventsOptions.api.sizeColumnsToFit();
    this.gridEventsOptions.animateRows = true;
    this.gridEventApi = params.api;
    this.gridEventsOptions.suppressCellFocus = true;
    this.gridEventsOptions.suppressHorizontalScroll = true;
    this.setEventAffichage();
  }

  setEventAffichage() {
    const returnRows = [];
    if (this.gridEventsOptions && this.gridEventsOptions.api && this.youngList) {
      this.youngList.forEach((occu: SimpleUserPresenceDTO) => {
        returnRows.push(this.getRowFromEvent(occu));
      }, this);
      this.gridEventsOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un Event pour l'affichagé sur une ligne
   * @param userPresDTO, event à afficher
   */
  getRowFromEvent(userPresDTO: SimpleUserPresenceDTO) {
    return {
      id: userPresDTO.id,
      name: userPresDTO.lastName + ' ' + userPresDTO.firstName,
      age: this.formatService.formatAge(userPresDTO.dateOfBirth),
      sex: this.formatService.formatSex(userPresDTO.sex, false),
      ri: userPresDTO.ri,
      status: userPresDTO.pMonitoringStatus.label,
      phone: userPresDTO.phone,
      email: userPresDTO.email,
      parcours: userPresDTO.listOfParcours[0],
      schoolLevel: userPresDTO.schoolLevel,
      presence: userPresDTO.presence
    };
  }

  onRowClick(event) {
    if (event.colDef.colId !== 'btn') {
      this.router.navigate(['young-info', event.data.id]);
    }
  }
}
