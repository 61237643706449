import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RegisteredResponsable} from '../model/event/registered-responsable.model';

@Injectable({
  providedIn: 'root'
})
export class RiOnEventService {
  private readonly dataRegisteredResponsable = new BehaviorSubject(
    new Array<RegisteredResponsable>()
  );
  public registeredResponsableMessager = this.dataRegisteredResponsable.asObservable();

  changeRegisteredResponsableList(registeredResponsable: RegisteredResponsable[]) {
    this.dataRegisteredResponsable.next(registeredResponsable);
  }
}
