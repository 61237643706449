<mat-card appearance="outlined" class="m-4">
  <div *ngIf="!isLoading && family?.length > 0" class="m-2 m-md-4 p-0 list-container" id="grid-wrapper">
    <ag-grid-angular (cellClicked)="onRowClick($event)"
                     (gridReady)="onGridReady($event)"
                     (gridSizeChanged)="onGridSizeChanged($event)"
                     [animateRows]="true"
                     [columnDefs]="columnFamilyDefs"
                     [domLayout]="domLayout"
                     [gridOptions]="gridOption"
                     [localeText]="langue"
                     [rowClassRules]="rowClassRules"
                     [rowData]="family"
                     [suppressDragLeaveHidesColumns]="true"
                     class="ag-theme-balham agGridResp">
    </ag-grid-angular>
  </div>

  <div *ngIf="isLoading">
    <div class="d-flex flex-row justify-content-center align-items-center py-5">
      <label>Chargement en cours...</label>
    </div>
  </div>

  <div *ngIf="family?.length === 0 && !isLoading">
    <mat-card appearance="outlined" class="m-4">
      <h5 class="m-2">Aucune donnée ne correspond à ces critères</h5>
    </mat-card>
  </div>

  <app-pagination [showItemPerPage]="true"
                  [itemPerPage]="itemPerPage"
                  (pageChange)="needUpdate()"></app-pagination>
</mat-card>
