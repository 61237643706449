import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CellClickedEvent, GridApi, GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {NameCellRendererComponent} from '../../../events/listing-young-in-event/list-jeune-by-activite/ag-grid/name-cell-renderer/name-cell-renderer.component';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatDialog} from '@angular/material/dialog';
import {SimpleMonitoringDTO} from '../../../../model/dto/simple-monitoring.dto';
import {GridYoungMonitoringDataService} from '../../../../services/grid-young-monitoring-data.service';
import {Monitoring} from '../../../../model/monitoring/monitoring.model';
import {DialogAddMonitoringComponent} from '../../dialog/dialog-add-monitoring/dialog-add-monitoring.component';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {ModuleSource, onlyAdmin} from '../../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../../services/current-user.service';
import {PaginationComponent} from '../../pagination/pagination.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {AlertComponent} from '../../alert/alert/alert.component';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-monitoring',
  templateUrl: './grid-monitoring.component.html',
  styleUrls: ['./grid-monitoring.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    NgIf,
    AgGridModule,
    MatProgressSpinnerModule,
    MatCardModule,
    PaginationComponent,
    AsyncPipe
  ]
})
export class GridMonitoringComponent extends Destroyed implements OnInit {
  @Input() allPMonitoringStatuses = [];
  @Input() isLoading;
  @Input() itemPerPage;
  @Output() pageChange = new EventEmitter<unknown>();
  listOfMonitoring: SimpleMonitoringDTO[];

  rowClassRules;
  gridMonitoringApi: GridApi;
  columnEntrepriseDefs;
  gridMonitoringOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly formatService: FormatService,
    private readonly gridMonitoringDataService: GridYoungMonitoringDataService,
    private readonly paramWebservice: ParamWebservice,
    private readonly currentUserService: CurrentUserService,
    private readonly dialog: MatDialog
  ) {
    super();
    this.columnEntrepriseDefs = [
      {
        headerName: 'Date du suivi',
        field: 'date',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Statut',
        field: 'status',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Motif de changement de statut',
        field: 'motifChange',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Situation à date',
        field: 'situationAtDate',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Complément de situation',
        field: 'situationComplement',
        sortable: true,
        minWidth: 100
      }
    ];

    this.context = {componentParent: this};
    this.frameworkComponents = {
      nameCellRenderer: NameCellRendererComponent
    };
  }

  ngOnInit() {
    this.gridMonitoringOption = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,
      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',
      suppressRowClickSelection: true,

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridMonitoringDataService.monitoringList
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<SimpleMonitoringDTO>) => {
        if (data) {
          this.listOfMonitoring = data.items;
          this.setAffichage();
        }
      });
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridMonitoringReady(params) {
    this.gridMonitoringOption.api.sizeColumnsToFit();
    this.gridMonitoringOption.animateRows = true;
    this.gridMonitoringApi = params.api;
    this.gridMonitoringOption.suppressCellFocus = true;
    this.gridMonitoringOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridMonitoringOption.api) {
      this.listOfMonitoring.forEach((simpleMonitoringDTO: SimpleMonitoringDTO) => {
        returnRows.push(this.getRowFromMonitoring(simpleMonitoringDTO));
      }, this);

      this.gridMonitoringOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param simpleMonitoringDTO
   */
  getRowFromMonitoring(simpleMonitoringDTO: SimpleMonitoringDTO) {
    return {
      id: simpleMonitoringDTO.id,
      date: this.formatService.formatDate(simpleMonitoringDTO.dateMonitoringChange),
      status: simpleMonitoringDTO.pMonitoringStatusLabel,
      motifChange: isNotNullOrUndefined(simpleMonitoringDTO.motifChange)
        ? simpleMonitoringDTO.motifChange
        : '/',
      situationAtDate: isNotNullOrUndefined(simpleMonitoringDTO.pSituationAtDateLabel)
        ? simpleMonitoringDTO.pSituationAtDateLabel
        : '/',
      situationComplement: isNotNullOrUndefined(simpleMonitoringDTO.situationComplement)
        ? simpleMonitoringDTO.situationComplement
        : '/'
    };
  }

  rowSelected() {
    if (this.gridMonitoringApi) {
      return this.gridMonitoringApi.getSelectedNodes().length <= 0;
    } else {
      return true;
    }
  }

  onRowClick($event: CellClickedEvent) {
    if (this.currentUserService.checkModule(...onlyAdmin, ModuleSource.MODIFICATION_UTILISATEUR)) {
      const dialogRef = this.dialog.open(DialogAddMonitoringComponent, {
        panelClass: 'dialog-large'
      });

      dialogRef.componentInstance.allPMonitoringStatuses = this.allPMonitoringStatuses;

      const monitoring = new Monitoring();
      monitoring.id = $event.data.id;
      dialogRef.componentInstance.monitoring = monitoring;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => this.emitPageChange());
    }
  }

  emitPageChange() {
    this.pageChange.emit();
  }
}
