import {Component} from '@angular/core';
import {ConnectedStatusEnum} from '../../../../../model/enums/connected-status.enum';
import {Router} from '@angular/router';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-open-new-tab-btn',
  templateUrl: './open-new-tab-btn.component.html',
  styleUrls: ['./open-new-tab-btn.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class OpenNewTabBtnComponent {
  public data;

  constructor(private readonly router: Router) {}

  agInit(params): void {
    this.data = params.data;
  }

  openNewTab() {
    const baseUrl = window.location.href.replace(this.router.url, '');
    let url = '';
    if (this.data.isConnected !== ConnectedStatusEnum.WEB) {
      url = new URL(baseUrl + '/young-info/' + this.data.id).href;
    } else {
      url = new URL(baseUrl + '/young-validation/' + this.data.id).href;
    }
    window.open(url, '_blank');
  }
}
