import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {FormatService} from 'src/app/services/format.service';
import {SimpleInterviewDTO} from '../../../../../model/dto/simple-interview.dto';
import {ExportWebservice} from '../../../../../services/webservices/export.webservice';
import {Destroyed} from '../../../directives/destroyed.directive';
import {FileInfoDTO} from '../../../../../model/dto/file-info.dto';
import {FilesWebservice} from '../../../../../services/webservices/files.webservice';
import {MatDialog} from '@angular/material/dialog';
import {DialogAddDocComponent} from '../../../dialog/dialog-add-doc/dialog-add-doc.component';
import {AnalyzeDocEnum} from '../../../../../model/enums/analyze-doc.enum';
import {FileReloadService} from '../../../../../services/file-reload.service';
import {
  ModuleSource,
  onlyAdmin,
  superAdmin
} from '../../../../../model/enums/source/module-source.enum';
import {NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {HasModuleDirective} from '../../../directives/has-module.directive';
import {CustomNavigator} from '../../../../../model/custom.navigator';

@Component({
  selector: 'app-download-cell-renderer',
  templateUrl: './download-cell-renderer.component.html',
  styleUrls: ['./download-cell-renderer.component.scss'],
  standalone: true,
  imports: [HasModuleDirective, MatButtonModule, MatTooltipModule, MatIconModule, NgIf]
})
export class DownloadCellRendererComponent extends Destroyed implements ICellRendererAngularComp {
  isAlreadyClickedDownload: boolean;
  params;

  public readonly canShowDocUnsigned = [
    ...onlyAdmin,
    ModuleSource.VISUALISATION_UTILISATEUR,
    ModuleSource.MODIFICATION_UTILISATEUR,
    ModuleSource.VISUALISATION_DOCUMENT,
    ModuleSource.ADMINISTRATION_DOCUMENT
  ];
  public readonly canShowDropDoc = [...superAdmin, ModuleSource.ADMINISTRATION_DOCUMENT];
  public readonly canShowDocSigned = [
    ...onlyAdmin,
    ModuleSource.VISUALISATION_DOCUMENT,
    ModuleSource.ADMINISTRATION_DOCUMENT
  ];

  constructor(
    private readonly formatService: FormatService,
    private readonly exportWebService: ExportWebservice,
    private readonly fileWebService: FilesWebservice,
    private readonly dialog: MatDialog,
    private readonly fileReloadService: FileReloadService
  ) {
    super();
  }

  agInit(params): void {
    this.params = params;
  }

  downloadFile(simpleInterviewDTOList: SimpleInterviewDTO) {
    const interviewList: SimpleInterviewDTO[] = [];
    interviewList.push(simpleInterviewDTOList);
    if (!this.isAlreadyClickedDownload) {
      const filename = 'Interview.pdf';
      this.isAlreadyClickedDownload = true;
      this.exportWebService
        .postExportInterview(interviewList)
        .pipe(this.untilDestroyed())
        .subscribe((data: Blob) => {
          const file = new Blob([data], {type: 'application/pdf'});
          if ((window.navigator as CustomNavigator).msSaveOrOpenBlob) {
            (window.navigator as CustomNavigator).msSaveOrOpenBlob(file, filename);
          } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = URL.createObjectURL(file);
            a.download = filename;
            a.click();
          }
          this.isAlreadyClickedDownload = false;
        });
    }
  }

  downloadSigned(simpleInterviewDTO: SimpleInterviewDTO) {
    if (!this.isAlreadyClickedDownload) {
      this.isAlreadyClickedDownload = true;
      this.fileWebService
        .downloadInterview(simpleInterviewDTO.id, simpleInterviewDTO.idYoung, [
          simpleInterviewDTO.document.path
        ])
        .pipe(this.untilDestroyed())
        .subscribe((data: FileInfoDTO) => {
          const bytesArray = this.base64ToByteArray(data.fileContent);
          const blob = new Blob([bytesArray], {type: 'application/pdf'});
          const fileObjectURL = URL.createObjectURL(blob);
          window.open(fileObjectURL);
          this.isAlreadyClickedDownload = false;
        });
    }
  }

  uploadDoc(simpleInterviewDTO) {
    const dialogRef = this.dialog.open(DialogAddDocComponent, {
      panelClass: 'dialog-large'
    });

    dialogRef.componentInstance.isInterview = true;

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const formData = new FormData();
        formData.append('file', result[0]);
        formData.append('idYoung', simpleInterviewDTO.idYoung);
        formData.append('idInterview', simpleInterviewDTO.id);
        formData.append('analyzeDocEnum', AnalyzeDocEnum.INTERVIEW.toString());

        this.fileWebService
          .upload(formData)
          .pipe(this.untilDestroyed())
          .subscribe(() => {
            this.fileReloadService.triggerRefresh();
          });
      }
    });
  }

  refresh(): boolean {
    return false;
  }

  base64ToByteArray(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }
}
