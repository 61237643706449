import {Component, OnInit} from '@angular/core';
import {Module} from 'src/app/model/role/module.model';
import {GridOptions, RowNode} from '@ag-grid-community/core';
import {RoleSettingDataService} from 'src/app/services/role-setting-data.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-module',
  templateUrl: './grid-module.component.html',
  styleUrls: ['./grid-module.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridModuleComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnModuleDefs;
  isModuleLoading = true;
  gridModuleOptions: GridOptions;
  rowData: unknown = null;
  fullModuleList: Module[] = [];
  domLayout: unknown = 'autoHeight';
  gridModuleApi: unknown;
  context;
  rowSelection;

  idCurrentPage: number;
  currentModulesList: Module[];
  langue = agGridLanguageFr;

  constructor(private readonly roleSettingDataService: RoleSettingDataService) {
    super();
    this.context = {componentParent: this};
  }

  ngOnInit() {
    // init grid module
    this.gridModuleOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.roleSettingDataService.fullModuleListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Module[]) => {
        this.fullModuleList = data;
        if (this.fullModuleList && this.fullModuleList.length > 0) {
          this.isModuleLoading = false;
        }
        this.setModuleAffichage();
      });

    this.roleSettingDataService.currentPageMessager
      .pipe(this.untilDestroyed())
      .subscribe((idPage: number) => {
        this.idCurrentPage = idPage;
        if (this.idCurrentPage === 0) {
          // Definition column grid module
          this.columnModuleDefs = [
            {
              headerName: 'Id',
              field: 'id',
              hide: true,
              sortable: true
            },
            {
              headerName: 'Nom',
              field: 'label',
              filter: 'agTextColumnFilter',
              filterParams: {filterOptions: ['contains']},
              suppressMenu: true,
              floatingFilterComponentParams: {suppressFilterButton: true},
              sortable: true,
              minWidth: 250,
              maxWidth: 300
            },
            {
              headerName: 'Code',
              field: 'code',
              filter: 'agTextColumnFilter',
              filterParams: {filterOptions: ['contains']},
              suppressMenu: true,
              floatingFilterComponentParams: {suppressFilterButton: true},
              sortable: true,
              minWidth: 250,
              maxWidth: 300
            },
            {
              headerName: 'Autorisé',
              sortable: true,
              headerCheckboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              checkboxSelection: true,
              minWidth: 100
            }
          ];
          this.rowSelection = 'multiple';
          this.setSelection();
        } else {
          // Definition column grid module
          this.columnModuleDefs = [
            {
              headerName: 'Id',
              field: 'id',
              hide: true,
              sortable: true
            },
            {
              headerName: 'Nom',
              field: 'label',
              filter: 'agTextColumnFilter',
              filterParams: {filterOptions: ['contains']},
              suppressMenu: true,
              floatingFilterComponentParams: {suppressFilterButton: true},
              sortable: true,
              minWidth: 250,
              maxWidth: 400
            },
            {
              headerName: 'Code',
              field: 'code',
              filter: 'agTextColumnFilter',
              filterParams: {filterOptions: ['contains']},
              suppressMenu: true,
              floatingFilterComponentParams: {suppressFilterButton: true},
              sortable: true,
              minWidth: 250,
              maxWidth: 300
            }
          ];

          this.resetSelection();
        }
      });

    this.roleSettingDataService.currentModuleListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Module[]) => {
        this.currentModulesList = data;
        this.setSelection();
      });
  }

  onGridSizeChanged() {
    this.gridModuleOptions.api.sizeColumnsToFit();
  }

  onModuleGridReady(params) {
    this.gridModuleOptions.api.sizeColumnsToFit();
    this.gridModuleOptions.animateRows = true;
    this.gridModuleApi = params.api;
    this.gridModuleOptions.suppressCellFocus = true;
    this.gridModuleOptions.suppressHorizontalScroll = true;
    this.setModuleAffichage();
    this.setSelection();
  }

  setModuleAffichage() {
    if (this.gridModuleOptions.api) {
      this.gridModuleOptions.api.setRowData(this.fullModuleList);
    }
  }

  onSelectionChanged() {
    if (this.idCurrentPage === 0) {
      const chosenModuleList: Module[] = this.gridModuleOptions.api.getSelectedRows();
      if (chosenModuleList) {
        this.roleSettingDataService.changeChosenModuleList(chosenModuleList);
      }
    }
  }

  setSelection() {
    this.resetSelection();
    if (this.gridModuleOptions.api) {
      this.gridModuleOptions.api.getRenderedNodes().forEach((node: RowNode) => {
        if (!!this.currentModulesList.find((mod) => mod.id === node.data.id)) {
          node.setSelected(true);
        }
      });
    }
  }

  resetSelection() {
    if (this.gridModuleOptions.api) {
      this.gridModuleOptions.api.getRenderedNodes().forEach((node: RowNode) => {
        node.setSelected(false);
      });
    }
  }
}
