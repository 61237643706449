import {Component, Input, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {StatutPlayoutCellRendererComponent} from '../custom-cell/statut-playout-cell-renderer/statut-playout-cell-renderer.component';
import {RegisteredResponsable} from '../../../../model/event/registered-responsable.model';
import {RiOnEventService} from '../../../../services/ri-on-event.service';
import {NumberInputRiCellRendererComponent} from '../custom-cell/number-input-ri-cell-renderer/number-input-ri-cell-renderer.component';
import {DeleteRiBtnRendererComponent} from '../custom-cell/delete-ri-btn-renderer/delete-ri-btn-renderer.component';
import {UserWebservice} from '../../../../services/webservices/user.webservice';
import {CompareService} from '../../../../services/compare.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';

@Component({
  selector: 'app-grid-ri-with-delete',
  templateUrl: './grid-ri-with-delete.component.html',
  styleUrls: ['./grid-ri-with-delete.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridRiWithDeleteComponent extends Destroyed implements OnInit {
  @Input() idEvent: number;

  listOfRegisteredResponsable: RegisteredResponsable[];

  rowClassRules;
  columnRiDefs;
  gridRisOptions: GridOptions;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  isLoading = true;
  context;
  langue = agGridLanguageFr;
  private gridRiApi: unknown;

  constructor(
    private readonly riOnEventService: RiOnEventService,
    private readonly userWebservice: UserWebservice,
    private readonly compareService: CompareService
  ) {
    super();
    // Definition column grid playout
    this.columnRiDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'IdUser',
        field: 'idUser',
        hide: true,
        sortable: false
      },
      {
        headerName: "Responsable d'insertion",
        field: 'ri',
        sortable: true,
        minWidth: 150
      },
      {
        headerName: 'Nombre de jeunes',
        field: 'numbersYoungs',
        cellRenderer: NumberInputRiCellRendererComponent,
        sortable: true,
        minWidth: 75
      },
      {
        headerName: 'Statut inscrits',
        field: 'status',
        cellRenderer: StatutPlayoutCellRendererComponent,
        minWidth: 75,
        sortable: true
      },
      {
        headerName: '',
        cellRenderer: DeleteRiBtnRendererComponent,
        colId: 'delete',
        minWidth: 50
      }
    ];
    this.context = {componentParent: this};
  }

  ngOnInit() {
    // init grid PlayOut
    this.gridRisOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      context: {
        componentParent: this
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'aucune donnée',

      onGridReady: (e) => {
        setTimeout(() => {
          e.columnApi.autoSizeAllColumns();
        }, 0);
      },

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.riOnEventService.registeredResponsableMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.listOfRegisteredResponsable = data;
        this.setRiAffichage();
        this.isLoading = !(
          this.listOfRegisteredResponsable && this.listOfRegisteredResponsable.length > 0
        );
      });
  }

  // playOut grid
  onGridSizeChanged(params) {
    this.gridRisOptions.api.sizeColumnsToFit();
  }

  onRiGridReady(params) {
    this.gridRisOptions.api.sizeColumnsToFit();
    this.gridRisOptions.animateRows = true;
    this.gridRiApi = params.api;
    this.gridRisOptions.suppressCellFocus = true;
    this.gridRisOptions.suppressHorizontalScroll = true;
    this.setRiAffichage();
  }

  setRiAffichage() {
    const returnRows = [];
    if (this.gridRisOptions && this.gridRisOptions.api) {
      this.listOfRegisteredResponsable.forEach(
        (registeredResponsable: RegisteredResponsable, index) => {
          if (registeredResponsable.idUser > 0 && isNotNullOrUndefined(this.idEvent)) {
            this.userWebservice
              .getYoungByRi(this.idEvent, registeredResponsable.idUser)
              .subscribe((nbInscrits) => {
                returnRows.push(this.getRowFromRi(registeredResponsable, nbInscrits));
                const sorted = [...returnRows].sort((a, b) =>
                  this.compareService.orderByAsc(a.ri, b.ri)
                );
                this.gridRisOptions.api.setRowData(sorted);
              });
          } else {
            // Si en creation (idEvent null), 0 inscrits sur event + centre
            returnRows.push(this.getRowFromRi(registeredResponsable, 0));
            this.gridRisOptions.api.setRowData(returnRows);
          }
        },
        this
      );
    }
  }

  /**
   * Extrait les infos d'un PlayOut pour l'affichagé sur une ligne
   * @param registeredResponsable
   * @param nombreInscrits
   */
  getRowFromRi(registeredResponsable: RegisteredResponsable, nombreInscrits: number) {
    if (isNotNullOrUndefined(registeredResponsable.user)) {
      return {
        id: registeredResponsable.id,
        idUser: registeredResponsable.idUser,
        ri: registeredResponsable.user.firstName + ' ' + registeredResponsable.user.lastName,
        numbersYoungs: registeredResponsable.capacity,
        status: nombreInscrits
      };
    }
    return null;
  }

  onNumbersYoungsChange(id: number, nbYoungs: number, idRi: number) {
    for (const ri of this.listOfRegisteredResponsable) {
      // Tri par Id mais si le playout a été généré après le chargement de l'ecran il n'a pas d'Id
      // Sinon tri sur l'id place
      if ((ri.id && ri.id === id) || (!ri.id && ri.idUser === idRi)) {
        if (nbYoungs) {
          ri.capacity = nbYoungs;
        } else {
          ri.capacity = 0;
        }
        this.riOnEventService.changeRegisteredResponsableList(this.listOfRegisteredResponsable);
        break;
      }
    }
  }
}
