import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SimpleBinomeDto} from 'src/app/model/dto/simple-binome.dto';
import {Paged} from 'src/app/model/response/paged.model';

@Injectable({
  providedIn: 'root'
})
export class GridYoungBinomeDataService {
  private readonly dataYoungList = new BehaviorSubject(null);
  public youngList = this.dataYoungList.asObservable();

  changeYoungList(listOfYoung: Paged<SimpleBinomeDto>) {
    this.dataYoungList.next(listOfYoung);
  }
}
