<h2 mat-dialog-title>Modification de masse</h2>
<mat-dialog-content class="mat-typography">
  <p>Vous allez modifier {{massModification.listOfYoung.length}} jeunes
  <div class="d-flex flex-row align-items-center my-2 mx-2">
    <mat-form-field appearance="outline">
      <mat-label>Responsable d'insertion</mat-label>
      <mat-select [(ngModel)]="massModification.ri">
        <mat-option>
          <ngx-mat-select-search [formControl]="riMultiFilterCtrl"
                                 noEntriesFoundLabel="Aucun résultat correspondant"
                                 placeholderLabel="Trouvez un Ri"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let user of filteredRiObservable | async" [value]="user.id">
          {{user.firstName}} {{user.lastName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="onDismiss()" mat-button>Annuler</button>
  <ng-container *ngIf="!isBtnLoading; else btnLoading">
    <button (click)="updateYoung()" cdkFocusInitial mat-button>Confirmer</button>
  </ng-container>
</mat-dialog-actions>

<ng-template #btnLoading>
  <button class="mat-button m-4" mat-button>
    <mat-spinner [diameter]="30" class="custom-theme" color="primary"></mat-spinner>
  </button>
</ng-template>

