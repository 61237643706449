<ng-container *ngIf="!isLoading; else loading">
  <div *ngIf="listOfYoung && listOfYoung.items.length > 0; else noData" id="grid-wrapper">
    <ag-grid-angular #agGrid
                     (cellClicked)="onRowClick($event)"
                     (gridReady)="onGridYoungReady($event)"
                     (gridSizeChanged)="onGridSizeChanged($event)"
                     [animateRows]="true"
                     [columnDefs]="columnYoungDefs"
                     [context]="context"
                     [domLayout]="domLayout"

                     [getRowHeight]="getRowHeight"
                     [gridOptions]="gridYoungOption"
                     [localeText]="langue"
                     [rowClassRules]="rowClassRules"
                     [rowData]="rowData | async"
                     [rowSelection]="rowSelection"
                     [suppressDragLeaveHidesColumns]="true"
                     class="ag-theme-balham">
    </ag-grid-angular>
  </div>
</ng-container>
<app-pagination (pageChange)="emitPageChange()" [itemPerPage]="itemPerPage"></app-pagination>

<ng-template #loading>
  <div class="d-flex flex-row justify-content-center align-items-center py-5">
    <label>Chargement en cours...</label>
  </div>
</ng-template>

<ng-template #noData>
  <mat-card appearance="outlined" class="m-4">
    <h5 class="m-2">Aucun binôme</h5>
  </mat-card>
</ng-template>
