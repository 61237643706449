import {SponsorChoiceFilter} from '../enums/sponsor-choice-filter.enum';

export class FilterYoungJob {
  nom: string;
  prenom: string;
  zipcode: string;
  ageMin: number;
  ageMax: number;
  sexe: number[];
  idRis: number[];
  booster: boolean[];
  statutJob: number[];
  priorityDistrictZoning: number[];
  listIdParcours: number[];
  isEligibleFse: number[];
  idPSchoolLevel: number[];
  idJobbeurNeeds: number[];
  idJobbeurAccompaniments: number[];
  choixParrain: SponsorChoiceFilter[];
  places: number[];
  year: number;
  ldlv?: boolean;
  eventType?: string;
  idEnsembles: number[];
  idActivityTypes: number[];
  idEventThemes: number[];
  level?: number;
}
