import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {OccurrencePlayoutCurrentlyViewedService} from 'src/app/services/occurrence-playout-currently-viewed.service';
import {FormatService} from 'src/app/services/format.service';
import {PlayOut} from 'src/app/model/event/play-out.model';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';

@Component({
  selector: 'app-grid-playout',
  templateUrl: './grid-playout.component.html',
  styleUrls: ['./grid-playout.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridPlayoutComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnPlayOutDefs;
  isPlayOutLoading = true;
  gridPlayOutsOptions: GridOptions;
  rowData: unknown = null;
  playOutList: PlayOut[] = [];
  domLayout: unknown = 'autoHeight';
  context;
  langue = agGridLanguageFr;
  private gridPlayOutApi: unknown;

  constructor(
    private readonly formatService: FormatService,
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService
  ) {
    super();

    // Definition column grid playout
    this.columnPlayOutDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'Place',
        field: 'place',
        sortable: true,
        minWidth: 200,
        maxWidth: 500
      },
      {
        headerName: 'Nombre de jeunes',
        field: 'numbersYoungs',
        sortable: true,
        minWidth: 100
      }
    ];
    this.context = {componentParent: this};
  }

  ngOnInit() {
    // init grid PlayOut
    this.gridPlayOutsOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.occurrencePlayoutCurrentlyViewedService.playOutMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.playOutList = data;
        this.setPlayOutAffichage();
        if (this.playOutList.length > 0) {
          this.isPlayOutLoading = false;
        }
      });
  }

  // playOut grid

  onGridSizeChanged(params) {
    this.gridPlayOutsOptions.api.sizeColumnsToFit();
  }

  onPlayOutGridReady(params) {
    this.gridPlayOutsOptions.api.sizeColumnsToFit();
    this.gridPlayOutsOptions.animateRows = true;
    this.gridPlayOutApi = params.api;
    this.gridPlayOutsOptions.suppressCellFocus = true;
    this.gridPlayOutsOptions.suppressHorizontalScroll = true;
    this.setPlayOutAffichage();
  }

  setPlayOutAffichage() {
    const returnRows = [];
    if (this.gridPlayOutsOptions.api) {
      this.playOutList.forEach((occu: PlayOut) => {
        returnRows.push(this.getRowFromPlayOut(occu));
      }, this);
      this.gridPlayOutsOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un PlayOut pour l'affichagé sur une ligne
   * @param playOut
   */
  getRowFromPlayOut(playOut: PlayOut) {
    if (isNotNullOrUndefined(playOut.place)) {
      return {
        id: playOut.id,
        place: playOut.place.nameCenter,
        numbersYoungs: playOut.numbersYoungs
      };
    }
    return null;
  }
}
