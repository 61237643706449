import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Ensemble} from '../../model/event/ensemble.model';
import {FilterYoungJob} from '../../model/filter/filter-young-job.model';
import {Observable} from 'rxjs';
import {PageRequest} from 'src/app/model/request/pageRequest';
import {ProjectYoungAndPresencesDTO} from '../../model/dto/ensemble.dto';
import {TreeItemNode} from 'src/app/model/event/treeData/tree-item-node.model';
import {Paged} from 'src/app/model/response/paged.model';

@Injectable({
  providedIn: 'root'
})
export class EnsembleWebservice {
  private readonly root = `${environment.envApiUrl}/ensemble`;

  constructor(private readonly http: HttpClient) {}

  getAllEnsembleTree() {
    return this.http.get(`${this.root}/tree`);
  }

  getEnsembleTree(pageRequest: PageRequest, allEnsemble: boolean = true) {
    return this.http.get(
      `${this.root}/tree/${allEnsemble}`,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getEnsembleEventTree() {
    return this.http.get(`${this.root}/event-tree`);
  }

  getEnsembleById(idEnsemble: number) {
    return this.http.get(`${this.root}/${idEnsemble}`);
  }

  ensembleHaveChildById(idEnsemble: number) {
    return this.http.get(`${this.root}/childs/${idEnsemble}`);
  }

  ensembleHaveLinkEventById(idEnsemble: number) {
    return this.http.get(`${this.root}/events/${idEnsemble}`);
  }

  createEnsemble(ensemble: Ensemble, pageRequest: PageRequest): Observable<Paged<TreeItemNode>> {
    return this.http.post<Paged<TreeItemNode>>(
      `${this.root}/create`,
      ensemble,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  editEnsemble(ensemble: Ensemble, pageRequest: PageRequest): Observable<Paged<TreeItemNode>> {
    return this.http.put<Paged<TreeItemNode>>(
      `${this.root}/edit`,
      ensemble,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  deleteEnsembleById(
    idEnsemble: number,
    pageRequest: PageRequest
  ): Observable<Paged<TreeItemNode>> {
    return this.http.delete<Paged<TreeItemNode>>(
      `${this.root}/delete/${idEnsemble}`,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getAllYoungsFromEnsemble(idEnsemble: number) {
    return this.http.get(`${this.root}/youngs/${idEnsemble}`);
  }

  getJobEnsemble(filter: FilterYoungJob, pageRequest: PageRequest) {
    return this.http.post(`${this.root}/jdlv`, filter, PageRequest.toQueryParams(pageRequest));
  }

  getEnsembleSummaryById(idEnsemble: number) {
    return this.http.get(`${this.root}/summary/${idEnsemble}`);
  }

  getSubDTOById(idEnsemble: number) {
    return this.http.get(`${this.root}/sub/${idEnsemble}`);
  }

  getSubEventDTO(listEventId: number[]) {
    return this.http.post(`${this.root}/events`, listEventId);
  }

  getPresenceYoungs(listEventId: number[]): Observable<ProjectYoungAndPresencesDTO> {
    return this.http.post<ProjectYoungAndPresencesDTO>(`${this.root}/presenceYoungs`, listEventId);
  }

  getAllEventsFromEnsemble(idEnsemble: number) {
    return this.http.get(`${this.root}/${idEnsemble}/events`);
  }
}
