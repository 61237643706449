import {Injectable} from '@angular/core';
import {Role} from '../model/role/role.model';
import {BehaviorSubject} from 'rxjs';
import {Module} from '../model/role/module.model';

@Injectable({
  providedIn: 'root'
})
export class RoleSettingDataService {
  private readonly dataCurrentPage = new BehaviorSubject(0);
  public currentPageMessager = this.dataCurrentPage.asObservable();

  private readonly dataFullRoleList = new BehaviorSubject(null);
  public fullRoleListMessager = this.dataFullRoleList.asObservable();

  private readonly dataRole = new BehaviorSubject(null);
  public roleMessager = this.dataRole.asObservable();

  // tous les modules
  private readonly dataFullModuleList = new BehaviorSubject(null);
  public fullModuleListMessager = this.dataFullModuleList.asObservable();

  // liste des module deja sur le role courant
  private readonly dataCurrentModuleList = new BehaviorSubject(null);
  public currentModuleListMessager = this.dataCurrentModuleList.asObservable();

  // tous les module sélectionné dans la grid
  private readonly dataChosenModuleList = new BehaviorSubject(null);
  public chosenModuleListMessager = this.dataChosenModuleList.asObservable();

  changeFullRoleList(listOfRoles: Role[]) {
    this.dataFullRoleList.next(listOfRoles);
  }

  changeRole(role: Role) {
    this.dataRole.next(role);
  }

  changeFullModuleList(listOfModules: Module[]) {
    this.dataFullModuleList.next(listOfModules);
  }

  changeCurrentModuleList(listOfModules: Module[]) {
    this.dataCurrentModuleList.next(listOfModules);
  }

  changeChosenModuleList(listOfModules: Module[]) {
    this.dataChosenModuleList.next(listOfModules);
  }

  changeCurrentPage(idPage: number) {
    this.dataCurrentPage.next(idPage);
  }
}
