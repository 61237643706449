import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GridOccurenceWithDeleteComponent} from './grid-occurence-with-delete/grid-occurence-with-delete.component';
import {GridPlayoutWithDeleteComponent} from './grid-playout-with-delete/grid-playout-with-delete.component';
import {DeletePlayOutBtnRendererComponent} from './custom-cell/delete-playout-btn-renderer/delete-playout-btn-renderer.component';
import {DeleteOccurenceBtnRendererComponent} from './custom-cell/delete-occurence-btn-renderer/delete-occurence-btn-renderer.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {SharedModule} from '../shared.modules';
import {GridOccurenceComponent} from './grid-occurence/grid-occurence.component';
import {GridPlayoutComponent} from './grid-playout/grid-playout.component';
import {GridEventComponent} from './grid-event/grid-event.component';
import {GridYoungComponent} from './grid-young/grid-young.component';
import {NameCellRendererComponent} from './custom-cell/name-cell-renderer/name-cell-renderer.component';
import {SexCellRendererComponent} from './custom-cell/sex-cell-renderer/sex-cell-renderer.component';
import {GridModuleComponent} from './grid-module/grid-module.component';
import {GridRoleComponent} from './grid-role/grid-role.component';
import {GridProfileYoungComponent} from './grid-profile-young/grid-profile-young.component';
import {EventTypeListCellRendererComponent} from './custom-cell/event-type-list-cell-renderer/event-type-list-cell-renderer.component';
import {StatusCellRendererComponent} from './custom-cell/status-cell-renderer/status-cell-renderer.component';
import {EditBtnRendererComponent} from './custom-cell/edit-btn-renderer/edit-btn-renderer.component';
import {RadioBtnRendererComponent} from './custom-cell/radio-btn-renderer/radio-btn-renderer.component';
import {GridFamilyComponent} from './grid-family/grid-family.component';
import {GridEventMultiSelectComponent} from './grid-event-multi-select/grid-event-multi-select.component';
import {GridRoleUserWithDeleteComponent} from './grid-role-user-with-delete/grid-role-user-with-delete.component';

import {ActionBtnRendererComponent} from './custom-cell/action-btn-renderer/action-btn-renderer.component';
import {NumberInputPlayoutCellRendererComponent} from './custom-cell/number-input-playout-cell-renderer/number-input-playout-cell-renderer.component';
import {StatutPlayoutCellRendererComponent} from './custom-cell/statut-playout-cell-renderer/statut-playout-cell-renderer.component';
import {GridUserPresenceComponent} from './grid-user-presence/grid-user-presence.component';
import {GridYoungEventComponent} from './grid-young-event/grid-young-event.component';
import {TauxCellRendererComponent} from './custom-cell/taux-cell-renderer/taux-cell-renderer.component';
import {UpdatePlayOutBtnRendererComponent} from './custom-cell/update-play-out-btn-renderer/update-play-out-btn-renderer.component';
import {GridFusionComponent} from './grid-fusion/grid-fusion.component';
import {GridFusionTutorComponent} from './grid-fusion-tutor/grid-fusion-tutor.component';
import {GridFamilySettingComponent} from './grid-family-setting/grid-family-setting.component';
import {GridProfileYoungJobComponent} from './grid-profile-young-job/grid-profile-young-job.component';
import {MassModificationModalStatusComponent} from '../../users/young/listing/mass-modification/mass-modification-modal-status/mass-modification-modal-status.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MassModificationModalRiComponent} from '../../users/young/listing/mass-modification/mass-modification-modal-ri/mass-modification-modal-ri.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {StatusMonitoringCellRendererComponent} from './custom-cell/status-monitoring-cell-renderer/status-monitoring-cell-renderer.component';
import {GridRiWithDeleteComponent} from './grid-ri-with-delete/grid-ri-with-delete.component';
import {NumberInputRiCellRendererComponent} from './custom-cell/number-input-ri-cell-renderer/number-input-ri-cell-renderer.component';
import {DeleteRiBtnRendererComponent} from './custom-cell/delete-ri-btn-renderer/delete-ri-btn-renderer.component';
import {GridRiComponent} from './grid-ri/grid-ri.component';
import {GridYoungJobComponent} from './grid-young-job/grid-young-job.component';
import {GridUserPresenceJobComponent} from './grid-user-presence-job/grid-user-presence-job.component';
import {GridEnsembleSubEnsembleComponent} from './grid-ensemble-subensemble/grid-ensemble-subensemble.component';
import {GridEnsembleSubEventComponent} from './grid-ensemble-subevent/grid-ensemble-subevent.component';
import {GridYoungEnsembleComponent} from './grid-young-ensemble/grid-young-ensemble.component';
import {GridEntrepriseComponent} from './grid-entreprise/grid-entreprise.component';
import {GridYoungRelationComponent} from './grid-young-relation/grid-young-relation.component';
import {GridWorkExperienceComponent} from './grid-work-experience/grid-work-experience.component';
import {GridInterviewComponent} from './grid-interview/grid-interview.component';
import {GridMonitoringComponent} from './grid-monitoring/grid-monitoring.component';
import {DownloadCellRendererComponent} from './custom-cell/download-cell-renderer/download-cell-renderer.component';
import {GridProfileYoungSportComponent} from './grid-profile-young-sport/grid-profile-young-sport.component';
import {GridProfileYoungLdlvComponent} from './grid-profile-young-ldlv/grid-profile-young-ldlv.component';
import {GridIntervenantComponent} from './grid-intervenant/grid-intervenant.component';
import {EditBtnTimeOccurenceComponent} from './custom-cell/edit-btn-time-occurence/edit-btn-time-occurence.component';
import {OpenNewTabBtnComponent} from './custom-cell/open-new-tab-btn/open-new-tab-btn.component';
import {DeleteRelationBtnComponent} from './custom-cell/delete-relation-btn/delete-relation-btn.component';
import {DownloadVisitBtnRendererComponent} from './custom-cell/download-visit-btn-renderer/download-visit-btn-renderer.component';
import {BooleanCellRendererComponent} from './custom-cell/boolean-cell-renderer/boolean-cell-renderer.component';
import {GenericAgGridComponent} from './generic-ag-grid/generic-ag-grid.component';
import {GridProfileComponent} from './grid-profile/grid-profile.component';
import {DeleteRoleBtnRendererComponent} from './custom-cell/delete-role-btn-renderer/delete-role-btn-renderer.component';
import {DeleteModuleBtnRendererComponent} from './custom-cell/delete-module-btn-renderer/delete-module-btn-renderer.component';

const GRID_COMPONENT = [
  GridOccurenceWithDeleteComponent,
  GridPlayoutWithDeleteComponent,
  GridOccurenceComponent,
  GridPlayoutComponent,
  GridEventComponent,
  GridYoungComponent,
  GridModuleComponent,
  GridRoleComponent,
  GridProfileComponent,
  GridProfileYoungComponent,
  GridProfileYoungJobComponent,
  GridFamilyComponent,
  GridEventMultiSelectComponent,
  GridRoleUserWithDeleteComponent,
  GridUserPresenceComponent,
  GridYoungEventComponent,
  GridFusionComponent,
  GridFusionTutorComponent,
  GridFamilySettingComponent,
  GridRiComponent,
  GridRiWithDeleteComponent,
  GridYoungJobComponent,
  GridEnsembleSubEnsembleComponent,
  GridEnsembleSubEventComponent,
  GridUserPresenceJobComponent,
  GridYoungEnsembleComponent,
  GridEntrepriseComponent,
  GridYoungRelationComponent,
  GridWorkExperienceComponent,
  GridInterviewComponent,
  GridMonitoringComponent,
  GridProfileYoungSportComponent,
  GridProfileYoungLdlvComponent,
  GridIntervenantComponent
];

const CUSTOM_CELLS = [
    DeletePlayOutBtnRendererComponent,
    DeleteOccurenceBtnRendererComponent,
    DeleteRoleBtnRendererComponent,
    DeleteModuleBtnRendererComponent,
    ActionBtnRendererComponent,
    SexCellRendererComponent,
    NameCellRendererComponent,
    EventTypeListCellRendererComponent,
    StatusCellRendererComponent,
    EditBtnRendererComponent,
    NumberInputPlayoutCellRendererComponent,
    StatutPlayoutCellRendererComponent,
    RadioBtnRendererComponent,
    TauxCellRendererComponent,
    UpdatePlayOutBtnRendererComponent,
    MassModificationModalStatusComponent,
    MassModificationModalRiComponent,
    StatusMonitoringCellRendererComponent,
    NumberInputRiCellRendererComponent,
    DeleteRiBtnRendererComponent,
    DownloadCellRendererComponent,
    EditBtnTimeOccurenceComponent,
    OpenNewTabBtnComponent,
    DeleteRelationBtnComponent,
    DownloadVisitBtnRendererComponent,
    BooleanCellRendererComponent
];

@NgModule({
    imports: [
    CommonModule,
    AgGridModule,
    MatDialogModule,
    SharedModule,
    NgxMatSelectSearchModule,
    ...CUSTOM_CELLS, ...GRID_COMPONENT, GenericAgGridComponent
],
    exports: [...GRID_COMPONENT]
})
export class CustomAgGridModule {}
