<div *ngIf="!isEventLoading; else noValue" class="m-2 m-md-4 p-0 list-container" id="grid-wrapper">
  <ag-grid-angular #agGrid2
                   (cellClicked)="onRowClick($event)"
                   (gridReady)="onEventGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   [animateRows]="true"
                   [columnDefs]="columnEventDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridEventsOptions"
                   [localeText]="langue"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>
<ng-template #noValue>
  <mat-card appearance="outlined" class="m-4">
    <h5 class="m-2">Aucun ensemble</h5>
  </mat-card>
</ng-template>
