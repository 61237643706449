import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-action-btn-renderer',
  templateUrl: './action-btn-renderer.component.html',
  styleUrls: ['./action-btn-renderer.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule]
})
export class ActionBtnRendererComponent implements ICellRendererAngularComp {
  seeAction: (data: unknown) => {} = undefined;
  deleteAction: (data: unknown) => {} = undefined;
  editAction: (data: unknown) => {} = undefined;
  duplicateAction: (data: unknown) => {} = undefined;
  data;

  refresh(params): boolean {
    this.agInit(params);
    return true;
  }

  agInit(params): void {
    if (params && params.data) {
      if (params.data.deleteAction) {
        this.deleteAction = params.data.deleteAction;
      }
      if (params.data.editAction) {
        this.seeAction = params.data.seeAction;
      }
      if (params.data.editAction) {
        this.editAction = params.data.editAction;
      }
      if (params.data.duplicateAction) {
        this.duplicateAction = params.data.duplicateAction;
      }
      this.data = params.data;
    }
  }

  onSeeClick() {
    this.seeAction(this.data);
  }

  onDeleteClick() {
    this.deleteAction(this.data);
  }

  onEditClick() {
    this.editAction(this.data);
  }

  onDuplicateClick() {
    this.duplicateAction(this.data);
  }
}
