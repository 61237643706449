import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {UserProfileDTO} from '../model/dto/user-profile.dto';

@Injectable({
  providedIn: 'root'
})
export class ProfileSettingDataServiceService {
  private readonly dataProfileList = new BehaviorSubject(new Array<UserProfileDTO>());
  public profileListMessager = this.dataProfileList.asObservable();

  changeProfileList(profileList: UserProfileDTO[]) {
    this.dataProfileList.next(profileList);
  }
}
