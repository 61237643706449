import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {Router} from '@angular/router';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {ConnectedStatusEnum} from '../../../../model/enums/connected-status.enum';
import {ProfileYoungJobService} from '../../../users/young/listing/profile-young/profile-young-job/profile-young-job.service';
import {MassModificationModalRiComponent} from '../../../users/young/listing/mass-modification/mass-modification-modal-ri/mass-modification-modal-ri.component';
import {FilterYoungService} from '../../../../services/filter-young.service';
import {RegisteredYoungJobItemDTO} from '../../../../model/dto/registered-young-job-item.dto';
import {StatusMonitoringCellRendererComponent} from '../custom-cell/status-monitoring-cell-renderer/status-monitoring-cell-renderer.component';
import {AlertService} from '../../../../services/alert.service';
import {PaginationService} from '../../../../services/pagination.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatDialog} from '@angular/material/dialog';
import {OpenNewTabBtnComponent} from '../custom-cell/open-new-tab-btn/open-new-tab-btn.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PaginationComponent} from '../../pagination/pagination.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {AlertComponent} from '../../alert/alert/alert.component';
import {MatCardModule} from '@angular/material/card';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-profile-young-job',
  templateUrl: './grid-profile-young-job.component.html',
  styleUrls: ['./grid-profile-young-job.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    AlertComponent,
    MatButtonModule,
    NgIf,
    AgGridModule,
    PaginationComponent,
    MatProgressSpinnerModule,
    AsyncPipe
  ]
})
export class GridProfileYoungJobComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Output() pageChange = new EventEmitter<unknown>();
  itemsYoung: Paged<RegisteredYoungJobItemDTO>;
  rowClassRules;
  columnYoungDefs;
  gridYoungOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly gridProfileyoungJobDataService: ProfileYoungJobService,
    private readonly formatService: FormatService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly filterYoungJobService: FilterYoungService,
    private readonly alertService: AlertService,
    private readonly paginationService: PaginationService
  ) {
    super();
    this.columnYoungDefs = [
      {
        headerName: '',
        colId: 'btn',
        headerCheckboxSelection: true,
        checkboxSelection: true,
        width: 50,
        flex: 0
      },
      {
        headerName: '',
        colId: 'btn',
        cellRenderer: OpenNewTabBtnComponent,
        suppressSizeToFit: true,
        width: 70,
        flex: 0
      },
      {
        headerName: 'Nom',
        field: 'name',
        colId: 'name',
        sortable: true,
        cellRenderer: NameCellRendererComponent
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        width: 70
      },
      {
        headerName: 'Age',
        field: 'age',
        sortable: true,
        width: 70
      },
      {
        headerName: 'Téléphone',
        field: 'tel',
        width: 120
      },
      {
        headerName: 'Centre',
        field: 'nameCenter'
      },
      {
        headerName: 'Statut',
        field: 'idPMonitoringStatus',
        cellRenderer: StatusMonitoringCellRendererComponent,
        width: 70
      },
      {
        headerName: 'Parcours',
        field: 'parcours',
        sortable: true
      },
      {
        headerName: 'Resp. Insertion',
        field: 'ri'
      },
      {
        headerName: "Niveau d'études",
        field: 'label'
      },
      {
        headerName: 'Dernier entretien',
        field: 'interview'
      },
      {
        headerName: 'Dernière action co.',
        field: 'collective'
      }
    ];

    this.context = {componentParent: this};
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.gridYoungOption = {
      defaultColDef: {
        flex: 1,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',
      suppressRowClickSelection: true,
      rowSelection: 'multiple',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridProfileyoungJobDataService.youngListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<RegisteredYoungJobItemDTO>) => {
        this.itemsYoung = data;

        this.setAffichage();
      });
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridYoungReady(params) {
    this.gridYoungOption.api.sizeColumnsToFit();
    this.gridYoungOption.animateRows = true;
    this.gridYoungOption.suppressCellFocus = true;
    this.gridYoungOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridYoungOption.api) {
      this.itemsYoung.items.forEach((young: RegisteredYoungJobItemDTO) => {
        returnRows.push(this.getRowFromYoung(young));
      }, this);

      this.gridYoungOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param young
   */
  getRowFromYoung(young: RegisteredYoungJobItemDTO) {
    return {
      id: young.id,
      name: this.formatService.formatName(young),
      firstName: young.firstName,
      lastName: young.lastName,
      sex: young.sex,
      age: young.age + ' ans',
      address: young.address,
      nameCenter: young.nameCenter,
      nameRegion: young.nameRegion,
      isValidated: young.isValidated,
      tel: young.tel,
      isConnected: young.isConnected,
      parcours: young.parcoursEnum,
      ri: this.formatService.formatNameRi(young.lastNameRi, young.firstNameRi),
      label: young.label,
      idPMonitoringStatus: young.idPMonitoringStatus,
      interview: isNotNullOrUndefined(young.lastInterview)
        ? this.formatService.formatDate(young.lastInterview)
        : '/',
      collective: isNotNullOrUndefined(young.lastCollectiveAction)
        ? this.formatService.formatDate(young.lastCollectiveAction)
        : '/'
    };
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  onRowClick(event) {
    if (event.colDef.colId !== 'btn') {
      if (event.data.isConnected !== ConnectedStatusEnum.WEB) {
        this.router.navigate(['young-info', event.data.id]);
      } else {
        this.router.navigate(['young-validation', event.data.id]);
      }
    }
  }

  openModalUpdateYoungRI() {
    const dialogRef = this.dialog.open(MassModificationModalRiComponent, {
      panelClass: 'dialog'
    });
    if (this.gridYoungOption?.api?.getSelectedNodes()?.length > 0) {
      dialogRef.componentInstance.listOfId = this.gridYoungOption.api
        .getSelectedNodes()
        .map((item) => item.data.id);
      dialogRef.componentInstance.filter = this.filterYoungJobService.filterYoungJob;
    }
    dialogRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((result) => {
        if (!result.dismiss && result.data.value) {
          this.itemsYoung = result.data.value;
          this.paginationService.setPage(1);
          this.setAffichage();
          this.alertService.success('RI mis à jour avec succès');
        } else {
          this.alertService.warn('Opération annulée');
        }
      });
  }

  rowSelected() {
    if (this.gridYoungOption?.api?.getSelectedNodes()) {
      return this.gridYoungOption?.api?.getSelectedNodes()?.length <= 0;
    } else {
      return true;
    }
  }

  emitPageChange() {
    this.pageChange.emit();
  }
}
