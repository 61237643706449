import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {PlayOut} from '../../../../../model/event/play-out.model';
import {OccurrencePlayoutCurrentlyViewedService} from '../../../../../services/occurrence-playout-currently-viewed.service';
import {AlertService} from '../../../../../services/alert.service';
import {TreeItemFlatNode} from '../../../../../model/event/treeData/tree-item-flat-node.model';
import {SelectTreeComponent} from '../../../form-parts/form-items/select-tree/select-tree.component';
import {TreeContentType, TreeDataService} from '../../../../../services/tree-data.service';
import {MatDialog} from '@angular/material/dialog';
import {PlaceWebservice} from '../../../../../services/webservices/place.webservice';
import {Place} from '../../../../../model/event/place/place.model';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-update-play-out-btn-renderer',
  templateUrl: './update-play-out-btn-renderer.component.html',
  styleUrls: ['./update-play-out-btn-renderer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class UpdatePlayOutBtnRendererComponent
  extends Destroyed
  implements OnInit, ICellRendererAngularComp
{
  data;
  playOutList = new Array<PlayOut>();

  constructor(
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService,
    private readonly treeDataService: TreeDataService,
    private readonly dialog: MatDialog,
    private readonly placeWebservice: PlaceWebservice,
    private readonly alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.occurrencePlayoutCurrentlyViewedService.playOutMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => (this.playOutList = data));
  }

  refresh(): boolean {
    return true;
  }

  agInit(params): void {
    this.data = params.data;
  }

  onBtnClick() {
    if (this.data) {
      if (this.data.status === 0) {
        this.openModal(this.data);
      }
    }
  }

  openModal(place) {
    this.treeDataService.currentContentType = TreeContentType.PLACE;
    this.treeDataService.currentPage = 'ModificationEvent';
    const dialogRef = this.dialog.open(SelectTreeComponent, {
      panelClass: 'dialog'
    });

    dialogRef.componentInstance.canCheckAll = false;
    dialogRef.componentInstance.isPlaceTree = true;

    dialogRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((result: TreeItemFlatNode) => {
        if (result) {
          if (this.playOutList.find((value) => value.idPlace === result.id)) {
            this.alertService.warn('Ce lieu a déjà été choisi.');
          } else {
            const selected = this.data.id;
            const index = this.playOutList.findIndex((obj) => obj.id === selected);
            this.playOutList[index].idPlace = result.id;
            this.placeWebservice
              .getPlaceById(result.id)
              .pipe(this.untilDestroyed())
              .subscribe((data: Place) => {
                this.playOutList[index].place = data;
                this.occurrencePlayoutCurrentlyViewedService.changePlayOutList(this.playOutList);
              });
          }
        }
      });
  }
}
