import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {ColDef, GridApi, GridOptions, RowClassRules} from '@ag-grid-community/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {FamilySettingDTO} from 'src/app/model/dto/family-setting.dto';
import {Destroyed} from '../../directives/destroyed.directive';
import {PaginationComponent} from '../../pagination/pagination.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AgGridModule} from '@ag-grid-community/angular';
import {NgIf} from '@angular/common';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-grid-family-setting',
  templateUrl: './grid-family-setting.component.html',
  styleUrls: ['./grid-family-setting.component.scss'],
  standalone: true,
  imports: [MatCardModule, NgIf, AgGridModule, MatProgressSpinnerModule, PaginationComponent]
})
export class GridFamilySettingComponent extends Destroyed implements OnInit {
  @Input() family: FamilySettingDTO[] = [];
  @Input() isLoading: boolean = false;
  @Input() itemPerPage: number;

  @Output() update: EventEmitter<void> = new EventEmitter<void>();

  rowClassRules: RowClassRules;
  gridApi: GridApi;
  columnFamilyDefs: ColDef[];
  gridOption: GridOptions;
  domLayout: 'normal' | 'autoHeight' | 'print' | undefined = 'autoHeight';
  langue = agGridLanguageFr;

  constructor(private readonly formatService: FormatService, private readonly router: Router) {
    super();
  }

  ngOnInit() {
    this.columnFamilyDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        cellRenderer: NameCellRendererComponent,
        sortable: true,
        minWidth: 275,
        maxWidth: 350
      },
      {
        headerName: 'Adresse',
        field: 'address',
        valueFormatter: this.formatAddress.bind(this),
        sortable: true,
        minWidth: 275,
        maxWidth: 400
      },
      {
        headerName: 'Territoire',
        field: 'territory',
        minWidth: 150,
        maxWidth: 200,
        sortable: true
      },
      {
        headerName: 'Téléphone',
        field: 'phone',
        sortable: true,
        minWidth: 130,
        maxWidth: 130 // 130 sinon le numero est coupé sur les derniers chiffres
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true,
        minWidth: 150,
        maxWidth: 350
      },
      {
        headerName: 'Nombre de filles',
        field: 'girlsNumber',
        sortable: true,
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: 'Nombre de garçons',
        field: 'boysNumber',
        sortable: true,
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: 'Date de la dernière visite',
        field: 'lastVisit',
        valueFormatter: this.formatDate.bind(this),
        sortable: true,
        minWidth: 100,
        maxWidth: 200
      }
    ];

    this.gridOption = {
      defaultColDef: {
        flex: 1,
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: false,
      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;
  }

  formatAddress(params): string {
    return this.formatService.formatAddress(params.value);
  }

  formatDate(params): string {
    return this.formatService.formatDate(params.value);
  }

  onGridReady(params) {
    // Recupere les données sur les familles
    this.gridOption.api.sizeColumnsToFit();
    this.gridOption.animateRows = true;
    this.gridApi = params.api;
    this.gridOption.suppressCellFocus = true;
    this.gridOption.suppressHorizontalScroll = true;
  }

  onGridSizeChanged(params) {
    this.gridOption.api.sizeColumnsToFit();
  }

  onRowClick(event) {
    if (event.colDef.colId !== 'btn') {
      this.router.navigate(['family-setting/resp', event.data.id]);
    }
  }

  needUpdate(): void {
    this.update.emit();
  }
}
