import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {GridApi, GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Event} from '../../../../model/event/event.model';
import {ProgramEnum} from '../../../../model/enums/program.enum';
import {AsyncPipe} from '@angular/common';
import {AgGridModule} from '@ag-grid-community/angular';

@Component({
  selector: 'app-grid-event-multi-select',
  templateUrl: './grid-event-multi-select.component.html',
  styleUrls: ['./grid-event-multi-select.component.scss'],
  standalone: true,
  imports: [AgGridModule, AsyncPipe]
})
export class GridEventMultiSelectComponent implements OnInit, OnChanges {
  @Input() eventList: Event[] = [];
  @Output() selectionChange = new EventEmitter();
  @Output() selectionChangeUnregister = new EventEmitter();

  @Input() defaultSelection: Event[] = [];

  rowClassRules;
  columnEventDefs;
  isEventLoading = true;
  gridEventOptions: GridOptions;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  paginationSize = 10;
  context;
  langue = agGridLanguageFr;
  private gridEventApi: GridApi;

  constructor(private readonly formatService: FormatService) {
    this.context = {componentParent: this};
  }

  ngOnInit() {
    this.columnEventDefs = [
      {
        headerName: 'Type',
        field: 'eventType',
        sortable: true,
        minWidth: 100,
        headerCheckboxSelection: true,
        checkboxSelection: true
      },
      {
        headerName: 'Tranche',
        field: 'age',
        sortable: true,
        minWidth: 100
      }
    ];

    // init grid event
    this.gridEventOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60,

      isRowSelectable: (node) => {
        return node.data && true;
      },

      getRowClass: (params) => {
        if (!params.data) {
          return '';
        }
        if (params.data.hasMoreThanOneRegister) {
          // Si une date end existe, regEvent deja terminé donc pas de case cochée (sinon re-inscription a l'evenement)
          return '';
        }
        if (
          params.data &&
          (!this.defaultSelection ||
            !this.defaultSelection.some((value) => value.id === params.data.id))
        ) {
          return '';
        }

        return 'ag-row-selected';
      }
    } as GridOptions;

    this.setEventAffichage();
    if (this.eventList) {
      this.loadSelection();
    }
  }

  // Event grid
  onGridSizeChanged(params) {
    const grid = document.getElementById('grid-wrapper');
    if (grid) {
      const gridWidth = grid.offsetWidth;
      const columnsToShow = [];
      const columnsToHide = [];
      const allColumns = params.columnApi.getColumns();
      let totalColsWidth = 0;

      for (let i = 0; i < allColumns.length; i++) {
        const column = allColumns[i];
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      }
      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
    }

    params.api.sizeColumnsToFit();
    this.loadSelection();
  }

  onEventGridReady(params) {
    this.gridEventOptions.api.sizeColumnsToFit();
    this.gridEventOptions.animateRows = true;
    this.gridEventApi = params.api;
    this.gridEventOptions.suppressCellFocus = true;
    this.gridEventOptions.suppressHorizontalScroll = true;
    this.setEventAffichage();
  }

  setEventAffichage() {
    if (this.gridEventOptions && this.gridEventOptions.api) {
      this.gridEventOptions.api.setRowData(
        this.eventList ? this.eventList.map((value) => this.getRowFromEvent(value)) : []
      );
    }
  }

  /**
   * Extrait les infos d'un event pour l'affichagé sur une ligne
   * @param event
   */
  getRowFromEvent(event: Event) {
    return {
      id: event.id,
      eventType: event.eventType,
      start: this.formatService.formatDate(event.dateBegin),
      end: this.formatService.formatDate(event.dateEnd),
      status: this.formatService.formatStatusEnum(
        event.status,
        event.idProgram === ProgramEnum.JOBDANSLAVILLE
      ),
      age: event.session
        ? this.formatService.formatTrancheAge(event.session, true)
        : "Pas de tranche d'âge",
      hasMoreThanOneRegister:
        this.defaultSelection && this.defaultSelection.find((e) => e.id === event.id)
    };
  }

  onSelectionChanged() {
    const listOfNodeUnregistred: unknown[] = [];
    this.selectionChange.emit(this.gridEventApi.getSelectedRows());
    // Renvoie les non selectionnés pour en deduire les registeredEvent a clore
    this.gridEventApi.forEachNode((node) => {
      if (
        !node.isSelected() &&
        this.defaultSelection &&
        this.defaultSelection.find((df) => df.id === node.data.id)
      ) {
        // Si non selectionné et faisant partie des defaultSelection
        listOfNodeUnregistred.push(node.data); // sont a mettre en regEvent => date end sur date d'ajd
      }
    });
    this.selectionChangeUnregister.emit(listOfNodeUnregistred);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setEventAffichage();
    if (this.eventList) {
      this.isEventLoading = false;
      this.loadSelection();
    }
  }

  loadSelection(): boolean {
    let selectionChange = false;
    if (
      !this.gridEventOptions ||
      !this.gridEventOptions.api ||
      this.defaultSelection === undefined
    ) {
      return false;
    }

    this.gridEventOptions.api.forEachNode((rowNode) => {
      if (
        rowNode.data &&
        !rowNode.isSelected() &&
        this.defaultSelection &&
        this.defaultSelection.some((value) => value.id === rowNode.data.id) &&
        rowNode.data.hasMoreThanOneRegister
      ) {
        rowNode.setSelected(true);
        selectionChange = true;
      }
    });
    return selectionChange;
  }
}
