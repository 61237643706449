import {Young} from './young.model';
import {User} from './user.model';
import {InterviewTheme} from './interview-theme.model';
import {PInterviewPlace} from '../param/p-interview-place.model';
import {Intervenant} from './intervenant.model';

export class Interview {
  id: number;
  idYoung: number;
  interviewDate: Date;
  duration?: number;
  idDoneBy: number;
  idPInterviewPlace?: number;
  familyPresence?: boolean;
  comment: string;
  idCreateBy: number;
  idLastUpdateBy?: number;
  dateCreate: Date;
  dateUpdate?: Date;
  idIntervenant?: number;

  young: Young;
  doneByUser: User;
  intervenant: Intervenant;
  listOfInterviewTheme: InterviewTheme[];
  pInterviewPlace: PInterviewPlace;
  lastUpdateBy: User;
  createBy: User;
}
