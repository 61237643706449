import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {RegisteredResponsable} from '../../../../model/event/registered-responsable.model';
import {RiOnEventService} from '../../../../services/ri-on-event.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';

@Component({
  selector: 'app-grid-ri',
  templateUrl: './grid-ri.component.html',
  styleUrls: ['./grid-ri.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridRiComponent extends Destroyed implements OnInit {
  listOfRegisteredResponsable: RegisteredResponsable[];

  rowClassRules;
  columnRiDefs;
  gridRisOptions: GridOptions;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  isLoading = true;
  context;
  langue = agGridLanguageFr;
  private gridRiApi: unknown;

  constructor(private readonly riOnEventService: RiOnEventService) {
    super();
    // Definition column grid playout
    this.columnRiDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'IdUser',
        field: 'idUser',
        hide: true,
        sortable: false
      },
      {
        headerName: "Responsable d'insertion",
        field: 'ri',
        sortable: true,
        minWidth: 150
      },
      {
        headerName: 'Nombre de jeunes max',
        field: 'numbersYoungs',
        sortable: true,
        minWidth: 75
      }
    ];
    this.context = {componentParent: this};
  }

  ngOnInit() {
    // init grid PlayOut
    this.gridRisOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      context: {
        componentParent: this
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'aucune donnée',

      onGridReady: (e) => {
        setTimeout(() => {
          e.columnApi.autoSizeAllColumns();
        }, 0);
      },

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.riOnEventService.registeredResponsableMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.listOfRegisteredResponsable = data;
        this.setRiAffichage();
        if (this.listOfRegisteredResponsable && this.listOfRegisteredResponsable.length > 0) {
          this.isLoading = false;
        } else {
          this.isLoading = true;
        }
      });
  }

  // playOut grid
  onGridSizeChanged(params) {
    this.gridRisOptions.api.sizeColumnsToFit();
  }

  onRiGridReady(params) {
    this.gridRisOptions.api.sizeColumnsToFit();
    this.gridRisOptions.animateRows = true;
    this.gridRiApi = params.api;
    this.gridRisOptions.suppressCellFocus = true;
    this.gridRisOptions.suppressHorizontalScroll = true;
    this.setRiAffichage();
  }

  setRiAffichage() {
    const returnRows = [];
    if (this.gridRisOptions && this.gridRisOptions.api) {
      this.listOfRegisteredResponsable.forEach((registeredResponsable: RegisteredResponsable) => {
        returnRows.push(this.getRowFromRi(registeredResponsable));
        this.gridRisOptions.api.setRowData(returnRows);
      }, this);
    }
  }

  /**
   * Extrait les infos d'un PlayOut pour l'affichagé sur une ligne
   * @param registeredResponsable
   */
  getRowFromRi(registeredResponsable: RegisteredResponsable) {
    if (isNotNullOrUndefined(registeredResponsable.user)) {
      return {
        id: registeredResponsable.id,
        idUser: registeredResponsable.idUser,
        ri: `${registeredResponsable.user.firstName} ${registeredResponsable.user.lastName}`,
        numbersYoungs:
          registeredResponsable.capacity && registeredResponsable.capacity > 0
            ? registeredResponsable.capacity
            : "Capacité de l'event"
      };
    }
    return {};
  }
}
