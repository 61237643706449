import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {ListActiviteDataService} from 'src/app/services/list-activite-data.service';
import {SimpleEventListDTO} from 'src/app/model/dto/simple-event-list.dto';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Destroyed} from '../../directives/destroyed.directive';
import {ProgramEnum} from '../../../../model/enums/program.enum';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-event',
  templateUrl: './grid-event.component.html',
  styleUrls: ['./grid-event.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridEventComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnEventDefs;
  isEventLoading = true;
  gridEventOptions: GridOptions;
  rowData: unknown = null;
  eventList: SimpleEventListDTO[] = [];
  domLayout: unknown = 'autoHeight';
  isMobile: boolean;
  context;
  langue = agGridLanguageFr;
  private gridEventApi: unknown;

  constructor(
    private readonly formatService: FormatService,
    private readonly listActiviteDataService: ListActiviteDataService
  ) {
    super();
    this.context = {componentParent: this};

    this.columnEventDefs = [
      {
        headerName: 'Type',
        field: 'eventType',
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: 'Centres',
        field: 'playOuts',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Tranche',
        field: 'age',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Début',
        field: 'start',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Fin',
        field: 'end',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Statut',
        field: 'status',
        sortable: true,
        minWidth: 100
      }
    ];
  }

  ngOnInit() {
    // init grid event
    this.gridEventOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.listActiviteDataService.activiteMessager.pipe(this.untilDestroyed()).subscribe((data) => {
      this.eventList = data;
      this.setEventAffichage();
      if (this.eventList.length > 0) {
        this.isEventLoading = false;
      }
    });
  }

  // Event grid

  onGridSizeChanged(params) {
    const gridWidth = document.getElementById('grid-wrapper').offsetWidth;
    const columnsToShow = [];
    const columnsToHide = [];
    const allColumns = params.columnApi.getColumns();
    let totalColsWidth = 0;
    for (let i = 0; i < allColumns.length; i++) {
      const column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  onEventGridReady(params) {
    this.gridEventOptions.api.sizeColumnsToFit();
    this.gridEventOptions.animateRows = true;
    this.gridEventApi = params.api;
    this.gridEventOptions.suppressCellFocus = true;
    this.gridEventOptions.suppressHorizontalScroll = true;
    this.setEventAffichage();
  }

  setEventAffichage() {
    const returnRows = [];
    if (this.gridEventOptions.api) {
      this.eventList.forEach((event: SimpleEventListDTO) => {
        returnRows.push(this.getRowFromEvent(event));
      }, this);
      this.gridEventOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un event pour l'affichagé sur une ligne
   * @param Event, event à afficher
   */
  getRowFromEvent(event: SimpleEventListDTO) {
    return {
      id: event.id,
      eventType: event.eventType,
      start: this.formatService.formatDate(event.dateBegin),
      end: this.formatService.formatDate(event.dateEnd),
      playOuts: this.formatService.formatListPlayoutDTO(event.listOfPlayOut),
      status: this.formatService.formatStatusEnum(
        event.status,
        event.idProgram === ProgramEnum.JOBDANSLAVILLE
      ),
      age: this.formatService.formatTrancheAge(event, false)
    };
  }

  isMobileDevice() {
    const screenSize = document.documentElement.clientWidth;
    const breakpoint = 992;
    this.isMobile = screenSize < breakpoint;
  }
}
