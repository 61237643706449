<button (click)="onSeeClick()" *ngIf="seeAction" aria-label="voir" mat-icon-button>
  <mat-icon>remove_red_eye</mat-icon>
</button>
<button (click)="onEditClick()" *ngIf="editAction" aria-label="modifier" mat-icon-button>
  <mat-icon>edit</mat-icon>
</button>
<button (click)="onDeleteClick()" *ngIf="deleteAction" aria-label="supprimer" mat-icon-button>
  <mat-icon>delete</mat-icon>
</button>
<button (click)="onDuplicateClick()" *ngIf="duplicateAction" aria-label="dupliquer" mat-icon-button>
  <mat-icon>file_copy</mat-icon>
</button>
