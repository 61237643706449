<ag-grid-angular #agGrid
                 (cellClicked)="onRowClick($event)"
                 (gridReady)="onEventGridReady($event)"
                 (gridSizeChanged)="onGridSizeChanged($event)"
                 [animateRows]="true"
                 [columnDefs]="columnEventDefs"
                 [context]="context"
                 [domLayout]="domLayout"
                 [gridOptions]="gridOptions"
                 [localeText]="langue"
                 [rowClassRules]="rowClassRules"
                 [rowData]="rowData | async"
                 [suppressDragLeaveHidesColumns]="true"
                 class="ag-theme-balham">
</ag-grid-angular>
