import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CellClickedEvent, GridApi, GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Interview} from '../../../../model/user/interview.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {GridYoungInterviewDataService} from '../../../../services/grid-young-interview-data.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogAddInterviewComponent} from '../../dialog/dialog-add-interview/dialog-add-interview.component';
import {SimpleInterviewDTO} from '../../../../model/dto/simple-interview.dto';
import {DownloadCellRendererComponent} from '../custom-cell/download-cell-renderer/download-cell-renderer.component';
import {FileReloadService} from '../../../../services/file-reload.service';
import {ModuleSource, onlyAdmin} from '../../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../../services/current-user.service';
import {DeleteRelationBtnComponent} from '../custom-cell/delete-relation-btn/delete-relation-btn.component';
import {DialogConfirmSuppressionRelationComponent} from '../../dialog/dialog-confirm-suppression-relation/dialog-confirm-suppression-relation.component';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {PaginationComponent} from '../../pagination/pagination.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {AlertComponent} from '../../alert/alert/alert.component';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-interview',
  templateUrl: './grid-interview.component.html',
  styleUrls: ['./grid-interview.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    NgIf,
    AgGridModule,
    MatProgressSpinnerModule,
    MatCardModule,
    PaginationComponent,
    AsyncPipe
  ]
})
export class GridInterviewComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Input() itemPerPage;
  @Output() pageChange = new EventEmitter<unknown>();
  listOfInterview: SimpleInterviewDTO[];
  rowClassRules;
  gridInterviewApi: GridApi;
  columnEntrepriseDefs;
  gridInterviewOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly formatService: FormatService,
    private readonly gridInterviewDataService: GridYoungInterviewDataService,
    private readonly dialog: MatDialog,
    private readonly fileReloadService: FileReloadService,
    private readonly currentUserService: CurrentUserService,
    private readonly youngWS: YoungWebservice
  ) {
    super();

    this.context = {componentParent: this};
    this.columnEntrepriseDefs = [
      {
        headerName: "Date de l'entretien",
        field: 'date',
        sortable: true,
        minWidth: 20
      },
      {
        headerName: 'Réalisé par',
        field: 'doneBy',
        sortable: true,
        minWidth: 50
      },
      {
        headerName: 'Intervenant',
        field: 'intervenantName',
        sortable: true,
        minWidth: 50
      },
      {
        headerName: "Thèmes de l'entretien",
        field: 'themes',
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Documents',
        colId: 'btn',
        field: 'download',
        sortable: false,
        cellRenderer: DownloadCellRendererComponent,
        suppressSizeToFit: false,
        pinned: 'right'
      },
      {
        headerName: '',
        cellRenderer: DeleteRelationBtnComponent,
        cellRendererParams: {
          action: this.deleteRelation.bind(this)
        },
        colId: 'btn',
        width: 75,
        pinned: 'right',
        hide: !this.isAdmin()
      }
    ];
  }

  ngOnInit() {
    this.gridInterviewOption = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,
      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',
      suppressRowClickSelection: true,

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridInterviewDataService.interviewList
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<SimpleInterviewDTO>) => {
        if (data) {
          this.listOfInterview = data.items;
          this.setAffichage();
        }
      })
      .add(() => {
        this.isLoading = false;
      });

    this.gridInterviewDataService.isLoadingMessager.pipe(this.untilDestroyed()).subscribe((val) => {
      this.isLoading = val;
    });

    this.fileReloadService.reload.pipe(this.untilDestroyed()).subscribe(() => {
      this.emitPageChange();
    });
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridInterviewReady(params) {
    this.gridInterviewOption.api.sizeColumnsToFit();
    this.gridInterviewOption.animateRows = true;
    this.gridInterviewApi = params.api;
    this.gridInterviewOption.suppressCellFocus = true;
    this.gridInterviewOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridInterviewOption.api) {
      this.listOfInterview.forEach((simpleInterviewDTO: SimpleInterviewDTO) => {
        returnRows.push(this.getRowFromInterview(simpleInterviewDTO));
      }, this);

      this.gridInterviewOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param entreprise
   */
  getRowFromInterview(simpleInterviewDTO: SimpleInterviewDTO) {
    return {
      id: simpleInterviewDTO.id,
      date: this.formatService.formatDate(simpleInterviewDTO.interviewDate),
      doneBy: simpleInterviewDTO.doneByName ? simpleInterviewDTO.doneByName : '/',
      intervenantName: simpleInterviewDTO.intervenantName
        ? simpleInterviewDTO.intervenantName
        : '/',
      themes: simpleInterviewDTO.interviewThemes,
      download: simpleInterviewDTO
    };
  }

  rowSelected() {
    if (this.gridInterviewApi) {
      return this.gridInterviewApi.getSelectedNodes().length <= 0;
    } else {
      return true;
    }
  }

  modifyClick(event: CellClickedEvent) {
    const dialogRef = this.dialog.open(DialogAddInterviewComponent, {
      panelClass: 'dialog-large'
    });

    const interview = new Interview();
    interview.id = event.data.id;
    dialogRef.componentInstance.interview = interview;

    dialogRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((value) => this.emitPageChange());
  }

  emitPageChange() {
    this.pageChange.emit();
  }

  onRowClick(event: CellClickedEvent) {
    if (
      event.colDef.colId !== 'btn' &&
      this.currentUserService.checkModule(...onlyAdmin, ModuleSource.MODIFICATION_UTILISATEUR)
    ) {
      this.modifyClick(event);
    }
  }

  isAdmin(): boolean {
    return this.currentUserService.checkModule(...onlyAdmin);
  }

  deleteRelation(data) {
    if (data) {
      const POPUP_TITLE = "Suppression d'un entretien";
      const POPUP_MESSAGE = "Souhaitez-vous supprimer l'entretien ?";
      const POPUP_ERROR_MESSAGE =
        "Impossible de supprimer l'entretien car il y a un document signé enregistré.";
      //présence de document signé
      if (data.download.document) {
        const dialogRef = this.dialog.open(DialogConfirmSuppressionRelationComponent);
        dialogRef.componentInstance.canDelete = false;
        dialogRef.componentInstance.title = POPUP_TITLE;
        dialogRef.componentInstance.message = POPUP_ERROR_MESSAGE;
      } else {
        const dialogRef = this.dialog.open(DialogConfirmSuppressionRelationComponent);
        dialogRef.componentInstance.canDelete = this.isAdmin();
        dialogRef.componentInstance.title = POPUP_TITLE;
        dialogRef.componentInstance.message = POPUP_MESSAGE;

        dialogRef.afterClosed().subscribe((value) => {
          if (value) {
            this.youngWS
              .deleteRelationInterview(data.id)
              .pipe(this.untilDestroyed())
              .subscribe(() => {
                this.emitPageChange();
              });
          }
        });
      }
    }
  }
}
