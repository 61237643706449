<div class="d-flex flex-column">
  <div class="d-flex flex-row m-2">
    <h3 mat-dialog-title>{{this.interview && this.interview.id > 0 ? 'Modifier ' : 'Ajouter ' }} un entretien </h3>
    <span class="flex-fill separator"></span>
    <button (click)="dismiss()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-alert id="interviewAlert"></app-alert>
  <mat-dialog-content>
    <div [formGroup]="interviewFormGroup" class="d-flex flex-row my-2 mx-2">
      <div class="col-md-6">
        <div class="form-group">
          <label class="label-info spanForm star">Date de l'entretien</label>

          <div [ngClass]="{ 'disabled': fInterview.interviewDate.disabled}" class="input-group">
            <input [matDatepicker]="interviewDate"
                   [ngClass]="{'is-invalid': (fInterview.interviewDate.dirty || fInterview.interviewDate.touched) && fInterview.interviewDate.invalid}"
                   autocomplete="off" class="form-control"
                   formControlName="interviewDate">

            <div class="input-group-append">
              <button (click)="interviewDate.open()" class="btn btn-outline-secondary form-control">
                <mat-icon>calendar_month</mat-icon>
              </button>
            </div>
          </div>

          <mat-datepicker
            #interviewDate></mat-datepicker>
        </div>
      </div>
      <div class="col-md-6">
        <label class="star">Durée de l'entretien</label>
        <mat-select [compareWith]="compareWith"
                    [ngClass]="{'is-invalid': (fInterview.duration.dirty || fInterview.duration.touched) && fInterview.duration.invalid, 'disabled': fInterview.duration.disabled}"
                    class="custom-select"
                    formControlName="duration"
                    placeholder="Choisir un élément">
          <mat-option *ngFor="let item of interviewDurationSelect" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </div>
    </div>

    <div [formGroup]="interviewFormGroup" class="d-flex flex-row my-2 mx-2">
      <div class="col-md-6">
        <label class="star">Lieu de l'entretien</label>
        <mat-select [compareWith]="compareWith"
                    [ngClass]="{'is-invalid': (fInterview.interviewPlace.dirty || fInterview.interviewPlace.touched) && fInterview.interviewPlace.invalid, 'disabled': fInterview.interviewPlace.disabled}"
                    class="custom-select"
                    formControlName="interviewPlace"
                    placeholder="Choisir un élément">
          <mat-option *ngFor="let item of interviewPlaces" [value]="item.id">{{item.name}}</mat-option>
        </mat-select>
      </div>
      <div class="col-md-6">
        <label class="star">Thème(s) de l'entretien</label>
        <app-select-multiple *ngIf="interviewThemes && interviewThemes.length > 0"
                             [compare]="compareWith"
                             [error]="(fInterview.interviewThemes.dirty || fInterview.interviewThemes.touched) && fInterview.interviewThemes.invalid"
                             [group]="interviewFormGroup"
                             [hasCompare]="true"
                             [items]="interviewThemes"
                             fcName="interviewThemes"></app-select-multiple>
      </div>
    </div>

    <div [formGroup]="interviewFormGroup" class="d-flex flex-row my-2 mx-2">
      <label class="ml-3">Lien famille</label>
      <app-switch [(modelAttribute)]="interview.familyPresence"
                  [group]="interviewFormGroup"
                  class="col-8 d-flex"
                  fcName="familyPresence"
                  textLabel="Non"
                  textLabelChecked="Oui"></app-switch>
    </div>

    <div class="d-flex flex-row my-2 mx-2">
      <div *ngIf="this.interview && !this.interview.id; else doneByUserSet" class="col-md-6 form-group">
        <label>Réalisé par</label>
        <input
          (ngModelChange)="onChangeSdlvUsers($event)"
          (search)="getSdlvUser($event)"
          [(ngModel)]="filteredSdlvUser.name"
          [matAutocomplete]="sdlvUser"
          [ngClass]="fInterview.idDoneBy.value ? 'selected' : ''"
          class="form-control doneBy"
          placeholder="Saisir le nom de la personne ayant réalisé l'entretien"
          type="search">
        <mat-autocomplete #sdlvUser="matAutocomplete" (optionSelected)='getSdlvUser($event.option.value)'
                          [displayWith]="displayFn"
                          class="col-12">
          <mat-option *ngFor="let item of filteredSdlvUsersObservable | async;"
                      [value]="item">{{item.firstName + " " + item.lastName + " " + (item.entrepriseName ? " | " + item.entrepriseName : "") }} </mat-option>
        </mat-autocomplete>
      </div>

      <ng-template #doneByUserSet>
        <div class="col-md-6 form-group">
          <label>Réalisé par</label>
          <input *ngIf="interview && interview.doneByUser; else noUser"
                 [value]="interview.doneByUser.firstName + ' ' +interview.doneByUser.lastName" class="form-control"
                 disabled
                 type="search">
        </div>
      </ng-template>

      <div *ngIf="this.interview && !this.interview.id; else intervenantSet" class="col-md-6 form-group">
        <label>Intervenant (extérieur)</label>
        <input
          (ngModelChange)="onChangeIntervenant($event)"
          (search)="getIntervenant($event)"
          [(ngModel)]="filteredIntervenant.name"
          [matAutocomplete]="intervenant"
          [ngClass]="fInterview.idIntervenant.value ? 'selected' : ''"
          class="form-control doneBy"
          placeholder="Saisir le nom d'un intervenant"
          type="search">
        <mat-autocomplete #intervenant="matAutocomplete" (optionSelected)='getIntervenant($event.option.value)'
                          [displayWith]="displayFn"
                          class="col-12">
          <mat-option *ngFor="let item of (filteredIntervenantObservable | async)?.items"
                      [value]="item">{{item.firstName + " " + item.lastName + " " + (item.entrepriseName ? " | " + item.entrepriseName : "") }} </mat-option>
        </mat-autocomplete>
      </div>

      <ng-template #intervenantSet>
        <div class="col-md-6 form-group">
          <label>Intervenant (extérieur)</label>
          <input *ngIf="interview && interview.intervenant && interview.intervenant.user; else noUser"
                 [value]="interview.intervenant.user.firstName + ' ' +interview.intervenant.user.lastName"
                 class="form-control" disabled
                 type="search">
        </div>
      </ng-template>
    </div>

    <ng-template #noUser>
      <input class="form-control" readonly disabled value="Aucun renseigné"/>
    </ng-template>

    <div [formGroup]="interviewFormGroup" class="d-flex flex-column align-items-center my-2 mx-2">
      <div class="col-md-12">
        <label>Commentaire</label>
        <textarea [ngClass]="{'disabled': fInterview.comment.disabled}" class="col-lg-12 form-control"
                  formControlName="comment"></textarea>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <ng-container *ngIf="!isReadOnly else readOnly">
      <button (click)="saveInterview()" *ngIf="!isSaving else btnLoading" class="btn btn-primary m-2" mat-button>
        Enregistrer
      </button>
      <button (click)="toggleDisable(true)" class="m-2" mat-button>
        Annuler
      </button>
    </ng-container>

    <ng-template #readOnly>
      <button (click)="toggleDisable(false)" *ngIf="!isSaving else btnLoading" class="btn btn-primary m-2" mat-button>
        Modifier
      </button>
      <button (click)="dismiss()" class="m-2" mat-button>
        Fermer
      </button>
    </ng-template>
  </mat-dialog-actions>
</div>

<ng-template #btnLoading>
  <button class="mat-button m-4" mat-button>
    <mat-spinner [diameter]="50" class="custom-theme" color="primary"></mat-spinner>
  </button>
</ng-template>
