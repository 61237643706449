import {Component, OnInit} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {Occurrence} from 'src/app/model/event/occurrence.model';
import {FormatService} from 'src/app/services/format.service';
import {OccurrencePlayoutCurrentlyViewedService} from 'src/app/services/occurrence-playout-currently-viewed.service';
import {DeleteOccurenceBtnRendererComponent} from '../custom-cell/delete-occurence-btn-renderer/delete-occurence-btn-renderer.component';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Destroyed} from '../../directives/destroyed.directive';
import {EditBtnTimeOccurenceComponent} from '../custom-cell/edit-btn-time-occurence/edit-btn-time-occurence.component';
import {onlyAdmin} from '../../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../../services/current-user.service';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-occurence-with-delete',
  templateUrl: './grid-occurence-with-delete.component.html',
  styleUrls: ['./grid-occurence-with-delete.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridOccurenceWithDeleteComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnOccurenceDefs;
  isOccurenceLoading = true;
  gridOccurencesOptions: GridOptions;
  rowData: unknown = null;
  occurenceList: Occurrence[] = [];
  domLayout: unknown = 'autoHeight';
  context;
  langue = agGridLanguageFr;
  private gridOccurenceApi: unknown;

  constructor(
    private readonly formatService: FormatService,
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService,
    private readonly currentUserService: CurrentUserService
  ) {
    super();

    // Definition column grid Occurence
    this.columnOccurenceDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'Début',
        field: 'start',
        sortable: true,
        minWidth: 150,
        maxWidth: 200
      },
      {
        headerName: 'Fin',
        field: 'end',
        sortable: true,
        minWidth: 150,
        maxWidth: 200
      },
      {
        headerName: 'Durée',
        field: 'time',
        minWidth: 100,
        maxWidth: 200
      },
      {
        headerName: 'Statut',
        field: 'status',
        sortable: true,
        minWidth: 70,
        maxWidth: 100
      },
      {
        headerName: 'Heure de début',
        field: 'startTime',
        sortable: true,
        minWidth: 125,
        maxWidth: 125
      },
      {
        headerName: 'Lieu de rendez-vous',
        field: 'place',
        sortable: true,
        minWidth: 70
      },
      {
        headerName: '',
        cellRenderer: EditBtnTimeOccurenceComponent,
        colId: 'modify',
        minWidth: 50,
        maxWidth: 100,
        hide: !this.isAdminNiv1
      },
      {
        headerName: '',
        cellRenderer: DeleteOccurenceBtnRendererComponent,
        colId: 'delete',
        minWidth: 50,
        maxWidth: 100
      }
    ];
    this.context = {componentParent: this};
  }

  get isAdminNiv1(): boolean {
    return this.currentUserService.checkModule(...onlyAdmin);
  }

  ngOnInit() {
    // init grid occurence
    this.gridOccurencesOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.occurrencePlayoutCurrentlyViewedService.occurenceMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.occurenceList = data;
        this.setOccurenceAffichage();
        if (this.occurenceList && this.occurenceList.length > 0) {
          this.isOccurenceLoading = false;
        }
      });
  }

  // Occurence grid

  onGridSizeChanged(params) {
    this.gridOccurencesOptions.api.sizeColumnsToFit();
  }

  onOccurenceGridReady(params) {
    this.gridOccurencesOptions.api.sizeColumnsToFit();
    this.gridOccurencesOptions.animateRows = true;
    this.gridOccurenceApi = params.api;
    this.gridOccurencesOptions.suppressCellFocus = true;
    this.gridOccurencesOptions.suppressHorizontalScroll = true;
    this.setOccurenceAffichage();
  }

  setOccurenceAffichage() {
    const returnRows = [];
    if (this.gridOccurencesOptions.api) {
      this.occurenceList.forEach((occu: Occurrence) => {
        returnRows.push(this.getRowFromOccurence(occu));
      }, this);
      this.gridOccurencesOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'une occurence pour l'affichagé sur une ligne
   * @param Occurence, occurence à afficher
   */
  getRowFromOccurence(occurence: Occurrence) {
    return {
      id: occurence.id,
      start: this.formatService.formatLargeDate(occurence.dateStart),
      end: this.formatService.formatLargeDate(occurence.dateEnd),
      time: this.formatService.formatNbOfMinToTime(occurence.time),
      startTime: this.formatService.formatNbOfMinToTime(occurence.startTime),
      status: this.formatService.formatStatusEnum(occurence.status),
      place: occurence.titre,
      statusEnum: occurence.status
    };
  }
}
