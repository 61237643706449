import {Component, ViewChild} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

@Component({
  selector: 'app-statut-playout-cell-renderer',
  templateUrl: './statut-playout-cell-renderer.component.html',
  styleUrls: ['./statut-playout-cell-renderer.component.scss'],
  standalone: true
})
export class StatutPlayoutCellRendererComponent implements ICellRendererAngularComp {
  @ViewChild('spanStatus', {static: true}) span;

  status: string;

  agInit(params): void {
    if (params.data?.status === 0) {
      this.status = '0 Inscrits';
    } else {
      this.status = `${params.data.status} Inscrits`;

      // Change la couleur pour le rouge
      this.span.nativeElement.classList.remove('badge-success');
      this.span.nativeElement.classList.add('badge-danger');
    }
  }

  refresh(): boolean {
    return false;
  }
}
