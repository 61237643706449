import {StatusEnum} from '../enums/status.enum';
import {SimpleUserPresenceDTO} from './simple-user-presence.dto';

export class Ensemble {
  id: number;
  idParent: number;
  label: string;
  dateBegin: Date;
  dateEnd: Date;
  idCreatedBy: number;
  dateCreate: number;
  level: number;
}

export class EnsembleParcoursDTO {
  id: number;
  idParent: number;
  label: string;
  dateBegin: Date;
  dateEnd: Date;
  idCreatedBy: number;
  dateCreate: number;
  level: number;
  idParcours?: string[];
  idPThematique?: number;
  activityTypeLabel?: string;
  nbNiveau1?: number;
  nbNiveau2?: number;
  projectNiv3Label?: string;
  pThematique: string;
  projectNiv3Id?: number;
}

export class EnsembleDTO {
  id: number;
  label: string;
  eventType: string;
  typeActivite: string;
  level: number;
  status: StatusEnum;
  dateBegin: Date;
  dateEnd: Date;
  nameCenter: string;
  nameRI: string;
  listOfParcoursName: string[];
  eventDigital: boolean;
  boosterAlternance: boolean;
  flagLdlv: boolean;
  listOfPillarsName: string[];
  listOfEventIds: number[];
  pThematique: string;
  listInfoJeune: SimpleUserPresenceDTO[];
  presenceRate: number;
  places: string;
}

export class ProjectYoungAndPresencesDTO {
  tauxRemplissage: number;
  nombreParticipations: number;
  nombreParticipants: number;
  tauxPresence: number;
  sexStat: number;
  listJeunes: SimpleUserPresenceDTO[];
}
