import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CellClickedEvent, GridApi, GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {WorkExperience} from '../../../../model/user/work-experience.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {DialogAddWorkExperienceComponent} from '../../dialog/dialog-add-work-experience/dialog-add-work-experience.component';
import {MatDialog} from '@angular/material/dialog';
import {GridYoungWorkExperienceDataService} from '../../../../services/grid-young-work-experience-data.service';
import {SimpleWorkExperienceDTO} from '../../../../model/dto/simple-work-experience.dto';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';
import {DeleteRelationBtnComponent} from '../custom-cell/delete-relation-btn/delete-relation-btn.component';
import {ModuleSource, onlyAdmin} from '../../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../../services/current-user.service';
import {DialogConfirmSuppressionRelationComponent} from '../../dialog/dialog-confirm-suppression-relation/dialog-confirm-suppression-relation.component';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {PaginationComponent} from '../../pagination/pagination.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {AlertComponent} from '../../alert/alert/alert.component';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-work-experience',
  templateUrl: './grid-work-experience.component.html',
  styleUrls: ['./grid-work-experience.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    NgIf,
    AgGridModule,
    MatProgressSpinnerModule,
    MatCardModule,
    PaginationComponent,
    AsyncPipe
  ]
})
export class GridWorkExperienceComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Input() itemPerPage;
  @Output() pageChange = new EventEmitter<unknown>();
  listOfWorkExperience: SimpleWorkExperienceDTO[];
  pageMax: number;

  rowClassRules;
  gridWorkExperienceApi: GridApi;
  columnEntrepriseDefs;
  gridWorkExperienceOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly formatService: FormatService,
    private readonly dialog: MatDialog,
    private readonly gridWorkExperienceDataService: GridYoungWorkExperienceDataService,
    private readonly currentUserService: CurrentUserService,
    private readonly youngWS: YoungWebservice
  ) {
    super();
    this.columnEntrepriseDefs = [
      {
        headerName: 'Catégorie',
        field: 'category',
        sortable: true
      },
      {
        headerName: 'Type',
        field: 'type',
        sortable: true
      },
      {
        headerName: 'Structure',
        field: 'entreprise',
        sortable: true
      },
      {
        headerName: 'Date de début',
        field: 'dateBegin'
      },
      {
        headerName: 'Date de fin',
        field: 'dateEnd'
      },
      {
        headerName: 'Intitulé du poste / de la formation',
        field: 'intitule'
      },
      {
        headerName: 'Temps de travail',
        field: 'workDuration'
      },
      {
        headerName: 'Motif de fin',
        field: 'endMotif'
      },
      {
        headerName: '',
        cellRenderer: DeleteRelationBtnComponent,
        cellRendererParams: {
          action: this.deleteRelation.bind(this)
        },
        colId: 'btn',
        pinned: 'right',
        hide: !this.isAdmin(),
        width: 75
      }
    ];

    this.context = {componentParent: this};
    this.frameworkComponents = {
      deleteRelationBtnComponent: DeleteRelationBtnComponent
    };
  }

  ngOnInit() {
    this.gridWorkExperienceOption = {
      defaultColDef: {
        flex: 1,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,
      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',
      suppressRowClickSelection: true,

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridWorkExperienceDataService.workExperienceList
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<SimpleWorkExperienceDTO>) => {
        if (data) {
          this.listOfWorkExperience = data.items;
          this.setAffichage();
        }
      });

    this.gridWorkExperienceDataService.isLoadingMessager
      .pipe(this.untilDestroyed())
      .subscribe((val) => {
        this.isLoading = val;
      });
  }

  isAdmin(): boolean {
    return this.currentUserService.checkModule(...onlyAdmin);
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridWorkExperienceReady(params) {
    this.gridWorkExperienceOption.api.sizeColumnsToFit();
    this.gridWorkExperienceOption.animateRows = true;
    this.gridWorkExperienceApi = params.api;
    this.gridWorkExperienceOption.suppressCellFocus = true;
    this.gridWorkExperienceOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridWorkExperienceOption.api) {
      this.listOfWorkExperience.forEach((simpleWorkExperienceDTO: SimpleWorkExperienceDTO) => {
        returnRows.push(this.getRowFromWorkExperience(simpleWorkExperienceDTO));
      }, this);

      this.gridWorkExperienceOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param entreprise
   */
  getRowFromWorkExperience(simpleWorkExperienceDTO: SimpleWorkExperienceDTO) {
    return {
      id: simpleWorkExperienceDTO.id,
      category: simpleWorkExperienceDTO.category,
      type: simpleWorkExperienceDTO.type,
      entreprise: simpleWorkExperienceDTO.structureName,
      dateBegin: this.formatService.formatDate(simpleWorkExperienceDTO.dateBegin),
      dateEnd: isNotNullOrUndefined(simpleWorkExperienceDTO.dateEnd)
        ? this.formatService.formatDate(simpleWorkExperienceDTO.dateEnd)
        : '/',
      intitule: isNotNullOrUndefined(simpleWorkExperienceDTO.intitule)
        ? simpleWorkExperienceDTO.intitule
        : '/',
      workDuration: isNotNullOrUndefined(simpleWorkExperienceDTO.workDuration)
        ? this.formatService.formatWorkDuration(simpleWorkExperienceDTO.workDuration)
        : '/',
      endMotif: isNotNullOrUndefined(simpleWorkExperienceDTO.workEndMotif)
        ? simpleWorkExperienceDTO.workEndMotif
        : '/'
    };
  }

  rowSelected() {
    if (this.gridWorkExperienceApi) {
      return this.gridWorkExperienceApi.getSelectedNodes().length <= 0;
    } else {
      return true;
    }
  }

  onRowClick($event: CellClickedEvent) {
    if (
      $event.colDef.colId !== 'btn' &&
      this.currentUserService.checkModule(...onlyAdmin, ModuleSource.MODIFICATION_UTILISATEUR)
    ) {
      const dialogRef = this.dialog.open(DialogAddWorkExperienceComponent, {
        panelClass: 'dialog-large'
      });

      const workExperience = new WorkExperience();
      workExperience.id = $event.data.id;
      dialogRef.componentInstance.workExperience = workExperience;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => this.emitPageChange());
    }
  }

  emitPageChange() {
    this.pageChange.emit();
  }

  deleteRelation(data) {
    if (data) {
      const POPUP_TITLE = "Suppression d'une expérience";
      const POPUP_MESSAGE = `Souhaitez-vous supprimer l'expérience au sein de la structure ${data.entreprise} ?`;
      const dialogRef = this.dialog.open(DialogConfirmSuppressionRelationComponent);
      dialogRef.componentInstance.title = POPUP_TITLE;
      dialogRef.componentInstance.message = POPUP_MESSAGE;
      dialogRef.componentInstance.canDelete = this.isAdmin();

      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.youngWS.deleteWorkExperience(data.id).subscribe(() => {
            this.emitPageChange();
          });
        }
      });
    }
  }
}
