import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {PlaceNombreEventCreationService} from '../../../../../services/place-nombre-event-creation.service';
import {AlertService} from 'src/app/services/alert.service';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {ICellRendererParams} from '@ag-grid-community/core';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-number-input-playout-cell-renderer',
  templateUrl: './number-input-playout-cell-renderer.component.html',
  styleUrls: ['./number-input-playout-cell-renderer.component.scss'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule]
})
export class NumberInputPlayoutCellRendererComponent
  extends Destroyed
  implements ICellRendererAngularComp
{
  params;
  // Nombre total sur les playout de jeunes
  nombreJeunesTotal: number;
  // Nombre total sur l'evenement
  nombreJeunesEvent: number;
  // Input pour le nombre de jeune
  placeNombreJeuneControl = new UntypedFormControl({value: null, disabled: false});
  // Ancienne valeur si erronnee et corrigee
  _oldNombreJeune: number;

  constructor(
    private readonly placeNombreJeuneService: PlaceNombreEventCreationService,
    private readonly alertService: AlertService
  ) {
    super();
  }

  agInit(params: ICellRendererParams<number>): void {
    this.params = params;
    this.updatePlaceService();
    if (this.params.data && this.params.data.numbersYoungs) {
      this.placeNombreJeuneControl.setValue(this.params.data.numbersYoungs);
      this._oldNombreJeune = this.params.data.numbersYoungs;
    } else {
      this._oldNombreJeune = 0;
    }
  }

  onNumbersYoungsChange() {
    // Recupere les valeurs
    this.updatePlaceService();

    const newTotalPlayout = this.nombreJeunesTotal - this._oldNombreJeune;
    // Controle que le nombre de place attribuee ne soit pas superieure au nombre de jeune sur l'evenement
    if (this.nombreJeunesEvent - newTotalPlayout >= this.placeNombreJeuneControl.value) {
      // Met a jour le nombre de place pour tout les playouts
      this.placeNombreJeuneService.changeNombrePlaceTotalPlayoutList(
        newTotalPlayout + this.placeNombreJeuneControl.value
      );
      this.params.context.componentParent.onNumbersYoungsChange(
        this.params.data.id,
        this.placeNombreJeuneControl.value,
        this.params.data.idPlace
      );
      this._oldNombreJeune = this.placeNombreJeuneControl.value;
    } else {
      this.placeNombreJeuneControl.setValue(this._oldNombreJeune);
      this.alertService.clear('alert-place');
      this.alertService.warn(
        'Nombre trop important. Vérifiez à ne pas dépasser la capacité maximum pour cet événement',
        'alert-place'
      );
    }
  }

  updatePlaceService() {
    this.placeNombreJeuneService.nombrePlaceTotalPlayOutMessager
      .pipe(this.untilDestroyed())
      .subscribe((value) => {
        if (!value) {
          this.nombreJeunesTotal = 0;
        } else {
          this.nombreJeunesTotal = value;
        }
      });
    this.placeNombreJeuneService.nombrePlaceEventMessager
      .pipe(this.untilDestroyed())
      .subscribe((value) => {
        this.nombreJeunesEvent = value;
      });
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
