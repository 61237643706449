<button *ngIf="isConnected !== statusWeb && isConnected !== statusMobile" [matMenuTriggerFor]="menuSettings"
        aria-label="Paramètres"
        mat-icon-button>
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #menuSettings="matMenu">
  <button (click)="showInfos()" *ngIf="isConnected !== statusWeb && isConnected !== statusMobile" mat-menu-item>Voir les
    infos
  </button>
  <button (click)="edit()" *ngIf="canAccessCreateYoung() && (isConnected !== statusWeb && isConnected !== statusMobile)"
          mat-menu-item>Éditer
  </button>
  <button (click)="validation()" *ngIf="canAccessCreateYoung() && isConnected === statusMigration" mat-menu-item>
    Vérifier
  </button>
</mat-menu>
