import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {OccurrenceDTO} from 'src/app/model/dto/occurrence.dto';
import {OccurrenceChangeTimeDTO} from '../../model/dto/occurence-change-time.dto';
import {OccurrenceUpdatedDto} from '../../model/dto/occurrence-updated.dto';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OccurrenceWebservice {
  private readonly root = `${environment.envApiUrl}/occurrences`;

  constructor(private readonly http: HttpClient) {}

  putChangeStatus(occu: OccurrenceDTO) {
    return this.http.put(`${this.root}/status`, occu);
  }

  setNombreParticipant(id: number, nbParticipantPresent: number) {
    return this.http.get(`${this.root}/participant/${id}/${nbParticipantPresent}`);
  }

  putChangeTime(id: number, time: number): Observable<OccurrenceUpdatedDto> {
    const data: OccurrenceChangeTimeDTO = new OccurrenceChangeTimeDTO(id, time);
    return this.http.put<OccurrenceUpdatedDto>(`${this.root}/time`, data);
  }

  cancelPresence(id: number): Observable<void> {
    return this.http.get<void>(`${this.root}/${id}/reset-presence`);
  }
}
