<div *ngIf="!isLoading && listOfYoung && listOfYoung.length > 0" id="grid-wrapper">
  <ag-grid-angular #agGrid
                   (cellClicked)="onRowClick($event)"
                   (gridReady)="onGridYoungReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   [animateRows]="true"
                   [columnDefs]="columnYoungDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [getRowHeight]="getRowHeight"
                   [gridOptions]="gridYoungOption"
                   [localeText]="langue"
                   [paginationPageSize]="paginationSize"
                   [pagination]="true"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [rowSelection]="rowSelection"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>

  <button (click)="export()" mat-button>Export</button>
</div>

<div *ngIf="isLoading">
  <div class="d-flex flex-row justify-content-center align-items-center py-5">
    <label>Chargement en cours...</label>
  </div>
</div>

<div *ngIf="listOfYoung && listOfYoung.length === 0 && !isLoading">
  <mat-card appearance="outlined" class="m-4">
    <h5 class="m-2">Aucune donnée ne correspond à ces critères</h5>
  </mat-card>
</div>
