import {Component, OnInit} from '@angular/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';

import {OccurrencePlayoutCurrentlyViewedService} from 'src/app/services/occurrence-playout-currently-viewed.service';
import {PlaceWebservice} from '../../../../services/webservices/place.webservice';

import {PlayOut} from 'src/app/model/event/play-out.model';

import {DeletePlayOutBtnRendererComponent} from '../custom-cell/delete-playout-btn-renderer/delete-playout-btn-renderer.component';
import {NumberInputPlayoutCellRendererComponent} from '../custom-cell/number-input-playout-cell-renderer/number-input-playout-cell-renderer.component';
import {StatutPlayoutCellRendererComponent} from '../custom-cell/statut-playout-cell-renderer/statut-playout-cell-renderer.component';
import {UpdatePlayOutBtnRendererComponent} from '../custom-cell/update-play-out-btn-renderer/update-play-out-btn-renderer.component';
import {Destroyed} from '../../directives/destroyed.directive';
import {CompareService} from '../../../../services/compare.service';
import {GridOptions} from '@ag-grid-community/core';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';

@Component({
  selector: 'app-grid-playout-with-delete',
  templateUrl: './grid-playout-with-delete.component.html',
  styleUrls: ['./grid-playout-with-delete.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridPlayoutWithDeleteComponent extends Destroyed implements OnInit {
  rowClassRules;
  columnPlayOutDefs;
  isPlayOutLoading = true;
  gridPlayOutsOptions: GridOptions;
  rowData: unknown = null;
  playOutList: PlayOut[] = [];
  domLayout: unknown = 'autoHeight';
  context;
  langue = agGridLanguageFr;

  constructor(
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService,
    private readonly placeWebService: PlaceWebservice,
    private readonly compareService: CompareService
  ) {
    super();

    // Definition column grid playout
    this.columnPlayOutDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'IdPlace',
        field: 'idPlace',
        hide: true,
        sortable: false
      },
      {
        headerName: 'Lieu',
        field: 'place',
        sortable: true,
        minWidth: 150
      },
      {
        headerName: 'Nombre de jeunes',
        field: 'numbersYoungs',
        cellRenderer: NumberInputPlayoutCellRendererComponent,
        sortable: true,
        minWidth: 75
      },
      {
        headerName: 'Statut inscrits',
        field: 'status',
        cellRenderer: StatutPlayoutCellRendererComponent,
        minWidth: 75,
        sortable: true
      },
      {
        headerName: '',
        cellRenderer: UpdatePlayOutBtnRendererComponent,
        colId: 'update',
        minWidth: 50
      },
      {
        headerName: '',
        cellRenderer: DeletePlayOutBtnRendererComponent,
        colId: 'delete',
        minWidth: 50
      }
    ];
    this.context = {componentParent: this};
  }

  ngOnInit() {
    // init grid PlayOut
    this.gridPlayOutsOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      context: {
        componentParent: this
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'aucune donnée',

      onGridReady: (e) => {
        setTimeout(() => {
          e.columnApi.autoSizeAllColumns();
        }, 0);
      },

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.occurrencePlayoutCurrentlyViewedService.playOutMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.playOutList = data;
        this.setPlayOutAffichage();
        if (this.playOutList.length > 0) {
          this.isPlayOutLoading = false;
        }
      });
  }

  // playOut grid
  onGridSizeChanged(params) {
    this.gridPlayOutsOptions.api.sizeColumnsToFit();
  }

  onPlayOutGridReady(params) {
    this.gridPlayOutsOptions.api.sizeColumnsToFit();
    this.gridPlayOutsOptions.animateRows = true;
    this.gridPlayOutsOptions.suppressCellFocus = true;
    this.gridPlayOutsOptions.suppressHorizontalScroll = true;
    this.setPlayOutAffichage();
  }

  setPlayOutAffichage() {
    const returnRows = [];
    if (this.gridPlayOutsOptions.api) {
      if (this.playOutList.length > 0) {
        this.playOutList.forEach((occu: PlayOut, index) => {
          if (occu.idEvent) {
            this.placeWebService
              .getYoungByCenter(occu.idEvent, occu.idPlace)
              .pipe(this.untilDestroyed())
              .subscribe((nbInscrits) => {
                returnRows.push(this.getRowFromPlayOut(occu, nbInscrits));

                const sorted = [...returnRows].sort((a, b) =>
                  this.compareService.orderByAsc(a.place, b.place)
                );
                this.gridPlayOutsOptions.api.setRowData(sorted);
              });
          } else {
            // Si en creation (idEvent null), 0 inscrits sur event + centre
            returnRows.push(this.getRowFromPlayOut(occu, 0));
            const sorted = [...returnRows].sort((a, b) =>
              this.compareService.orderByAsc(a.place, b.place)
            );
            this.gridPlayOutsOptions.api.setRowData(sorted);
          }
        }, this);
      } else {
        this.gridPlayOutsOptions.api.setRowData([]);
      }
    }
  }

  /**
   * Extrait les infos d'un PlayOut pour l'affichagé sur une ligne
   * @param PlayOut, playOut à afficher
   */
  getRowFromPlayOut(playOut: PlayOut, nombreInscrits: number) {
    if (isNotNullOrUndefined(playOut.place)) {
      return {
        id: playOut.id,
        idPlace: playOut.idPlace,
        place: playOut.place.nameCenter,
        numbersYoungs: playOut.numbersYoungs,
        status: nombreInscrits
      };
    }
    return null;
  }

  onNumbersYoungsChange(id: number, nbYoungs: number, idPlace: number) {
    let playout: PlayOut;
    for (playout of this.playOutList) {
      // Tri par Id mais si le playout a été généré après le chargement de l'ecran il n'a pas d'Id
      // Sinon tri sur l'id place
      if ((playout.id && playout.id === id) || (!playout.id && playout.idPlace === idPlace)) {
        playout.numbersYoungs = nbYoungs;
        this.occurrencePlayoutCurrentlyViewedService.changePlayOutList(this.playOutList);
        break;
      }
    }
  }
}
