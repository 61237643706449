<div *ngIf="!isRoleLoading" class="m-2 m-md-4 p-0 list-container" id="grid-wrapper">
  <ag-grid-angular #agGrid2
                   (gridReady)="onRoleGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged()"
                   [animateRows]="true"
                   [columnDefs]="columnRoleDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridRoleOptions"
                   [localeText]="langue"
                   [paginationPageSize]="paginationSize"
                   [pagination]="true"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>
