import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Module} from 'src/app/model/role/module.model';

@Injectable({
  providedIn: 'root'
})
export class ModuleWebservice {
  private readonly root = `${environment.envApiUrl}/modules`;

  constructor(private readonly http: HttpClient) {}

  createModule(mod: Module) {
    return this.http.post(`${this.root}`, mod);
  }

  getAllModules() {
    return this.http.get<Module[]>(`${this.root}`);
  }

  deleteModule(idModule: number) {
    return this.http.delete(`${this.root}/${idModule}`);
  }
}
