<div *ngIf="!isOccurenceLoading" class="m-2 m-md-4 p-0 list-container" id="grid-wrapper">
  <ag-grid-angular #agGrid2
                   (gridReady)="onOccurenceGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   [animateRows]="true"
                   [columnDefs]="columnOccurenceDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridOccurencesOptions"
                   [localeText]="langue"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>
