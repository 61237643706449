<div *ngIf="listOfProfile?.length > 0 && !isLoading" class="m-2 m-md-4 p-0 list-container" id="grid-wrapper">
  <ag-grid-angular #agGrid
                   (cellClicked)="onClick($event)"
                   (gridReady)="onGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   [animateRows]="true"
                   [columnDefs]="columnProfileDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridProfileOptions"
                   [localeText]="langue"
                   [paginationPageSize]="paginationSize"
                   [pagination]="true"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [suppressDragLeaveHidesColumns]="true"
                   [suppressRowClickSelection]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>

<div *ngIf="isLoading">
  <div class="d-flex flex-row justify-content-center align-items-center py-5">
    <label>Chargement en cours...</label>
  </div>
</div>

<div *ngIf="listOfProfile && listOfProfile.length === 0 && !isLoading">
  <mat-card appearance="outlined" class="m-4">
    <h5 class="m-2">Aucune données ne correspond à ces critères</h5>
  </mat-card>
</div>
