import {TypeStructureEntrepriseEnum} from '../enums/type-structure-entreprise.enum';
import {EntrepriseUser} from './entreprise-user.model';
import {IntervenantEntreprise} from '../user/intervenant-entreprise.model';
import {EntrepriseInterestField} from './entreprise-interest-field.model';
import {Address} from '../event/place/address.model';
import {EntrepriseNeed} from './entreprise-need.model';
import {EntrepriseHistory} from './entreprise-history.model';

export class Entreprise {
  id: number;
  typeStructure: TypeStructureEntrepriseEnum;
  nom: string;
  idAddress?: number;
  entreprisePartenaire?: boolean;
  referent?: number;
  idEntrepriseMere?: number;
  convention?: boolean;
  interest?: number[];
  entrepriseMere?: Entreprise;
  address?: Address;
  listOfEntreprise: Entreprise[];
  listOfEntrepriseInterestField: EntrepriseInterestField[];
  listOfIntervenantEntreprise: IntervenantEntreprise[];
  listOfEntrepriseUser: EntrepriseUser[];
  listOfEntrepriseNeed: EntrepriseNeed[];
  listOfEntrepriseHistory: EntrepriseHistory[];
  dateBegin?: Date;
  dateEnd?: Date;
}
