import {Component, OnInit} from '@angular/core';
import {UserProfileDTO} from 'src/app/model/dto/user-profile.dto';
import {GridOptions} from '@ag-grid-community/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {ProfileSettingDataServiceService} from 'src/app/services/profile-setting-data-service.service';
import {Router} from '@angular/router';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Destroyed} from '../../directives/destroyed.directive';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';

@Component({
  selector: 'app-grid-profile',
  templateUrl: './grid-profile.component.html',
  styleUrls: ['./grid-profile.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, MatProgressSpinnerModule, MatCardModule, AsyncPipe]
})
export class GridProfileComponent extends Destroyed implements OnInit {
  listOfProfile: UserProfileDTO[];

  rowClassRules;
  gridProfileApi: unknown;
  columnProfileDefs;
  gridProfileOptions: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;
  paginationSize = 25;

  isLoading = true;
  langue = agGridLanguageFr;

  constructor(
    private readonly formatService: FormatService,
    private readonly profileSettingDataService: ProfileSettingDataServiceService,
    private readonly router: Router
  ) {
    super();
    this.columnProfileDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        cellRenderer: NameCellRendererComponent,
        suppressMenu: true,
        floatingFilterComponentParams: {suppressFilterButton: true},
        sortable: true,
        minWidth: 250,
        maxWidth: 400
      },
      {
        headerName: 'Roles',
        field: 'role',
        valueFormatter: this.formatStringArray.bind(this),
        minWidth: 200,
        tooltipField: 'role'
      },
      {
        headerName: 'Programmes',
        field: 'programme',
        valueFormatter: this.formatStringArray.bind(this),
        minWidth: 200,
        tooltipField: 'programme'
      },
      {
        headerName: 'Lieux',
        field: 'center',
        valueFormatter: this.formatStringArray.bind(this),
        minWidth: 100,
        tooltipField: 'center'
      }
    ];
    this.context = {componentParent: this};
    this.frameworkComponents = {
      nameCellRenderer: NameCellRendererComponent
    };
  }

  ngOnInit() {
    this.gridProfileOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.profileSettingDataService.profileListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: UserProfileDTO[]) => {
        this.listOfProfile = data;
        this.setAffichage();
        if (this.listOfProfile.length > 0) {
          this.isLoading = false;
        }
      });
  }

  onGridSizeChanged(params) {
    const gridWidth = document.getElementById('grid-wrapper').offsetWidth;
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.columnApi.getColumns();
    if (allColumns) {
      allColumns.forEach((column) => {
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      });
    }

    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
  }

  onGridReady(params) {
    this.gridProfileOptions.api.sizeColumnsToFit();
    this.gridProfileOptions.animateRows = true;
    this.gridProfileApi = params.api;
    this.gridProfileOptions.suppressCellFocus = true;
    this.gridProfileOptions.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridProfileOptions.api && this.listOfProfile) {
      this.gridProfileOptions.api.setRowData(
        this.listOfProfile.map((value) => this.getRowFromProfile(value))
      );
    }
  }

  /**
   * Extrait les infos d'un profil pour l'affichagé sur une ligne
   * @param UserProfileDTO, profil à afficher
   */
  getRowFromProfile(profile: UserProfileDTO) {
    return {
      id: profile.idUser,
      name: this.formatService.formatName(profile),
      firstName: profile.firstName,
      lastName: profile.lastName,
      role:
        isNotNullOrUndefined(profile.listOfRolesString) && profile.listOfRolesString.length > 0
          ? profile.listOfRolesString
          : ['Aucun'],
      center:
        isNotNullOrUndefined(profile.listOfPlacesString) && profile.listOfPlacesString.length > 0
          ? profile.listOfPlacesString
          : ['Aucun'],
      programme:
        isNotNullOrUndefined(profile.listOfProgramsString) &&
        profile.listOfProgramsString.length > 0
          ? profile.listOfProgramsString
          : ['Aucun']
    };
  }

  formatStringArray(params) {
    if (params && params.value && Array.isArray(params.value)) {
      return this.formatService.formatListDisplay(params.value);
    }
    return '';
  }

  onClick(event) {
    this.router.navigate(['profile-detail', event.data.id]);
  }
}
