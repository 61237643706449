<button *appHasModule="canShowDocUnsigned" (click)="downloadFile(this.params.data.download)" class="ml-2 " mat-mini-fab
        matTooltip="Document non signé">
  <mat-icon class="mat-18">cloud_download</mat-icon>
</button>

<button *appHasModule="canShowDropDoc" (click)="uploadDoc(this.params.data.download)" class="ml-2" color="primary"
        mat-mini-fab
        matTooltip="Déposer un document">
  <mat-icon class="mat-18">upload_file</mat-icon>
</button>

<ng-container *appHasModule="canShowDocSigned">
  <button (click)="downloadSigned(this.params.data.download)" *ngIf="this.params.data.download.document" class="ml-2"
          color="primary" mat-mini-fab matTooltip="Document signé">
    <mat-icon class="mat-18">download</mat-icon>
  </button>
</ng-container>



