import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Router} from '@angular/router';
import {GridOptions, IRowNode} from '@ag-grid-community/core';
import {RegisteredYoungListDTO} from 'src/app/model/dto/registered-young-list.dto';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {EventTypeListCellRendererComponent} from '../custom-cell/event-type-list-cell-renderer/event-type-list-cell-renderer.component';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {StatusCellRendererComponent} from '../custom-cell/status-cell-renderer/status-cell-renderer.component';
import {MatButtonModule} from '@angular/material/button';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-fusion',
  templateUrl: './grid-fusion.component.html',
  styleUrls: ['./grid-fusion.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, MatButtonModule, AsyncPipe]
})
export class GridFusionComponent implements OnInit, OnChanges {
  @Input() listOfYoung: RegisteredYoungListDTO[];
  @Output() selectionChange = new EventEmitter();

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() address: string;
  @Input() phone: string;

  selected: IRowNode[] = [];

  columnDefs: unknown;
  rowClassRules;
  rowSelection: string;
  gridOptions: GridOptions;
  gridApi: unknown;

  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;
  langue = agGridLanguageFr;
  paginationSize = 25;

  constructor(private readonly formatService: FormatService, private readonly router: Router) {
    this.columnDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        colId: 'name',
        cellRenderer: NameCellRendererComponent,
        checkboxSelection: true,
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        minWidth: 50,
        maxWidth: 70
      },
      {
        headerName: 'Naissance',
        field: 'birthday',
        sortable: true,
        minWidth: 180,
        maxWidth: 200
      },
      {
        headerName: 'Adresse',
        field: 'address',
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Centre',
        field: 'nameCenter',
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Région',
        field: 'nameRegion',
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Statut',
        field: 'isValidated',
        colId: 'status',
        cellRenderer: StatusCellRendererComponent,
        sortable: true,
        minWidth: 150,
        maxWidth: 200
      }
    ];

    this.context = {componentParent: this};
    this.frameworkComponents = {
      nameCellRenderer: NameCellRendererComponent,
      eventTypeListCellRendererComponent: EventTypeListCellRendererComponent,
      statusCellRendererComponent: StatusCellRendererComponent
    };
    this.rowSelection = 'multiple';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.listOfYoung?.previousValue?.length !== changes.listOfYoung?.currentValue?.length &&
      this.listOfYoung &&
      this.gridOptions
    ) {
      this.setAffichageYoung();
    }

    if (
      this.gridOptions?.api &&
      (changes.firstName || changes.lastName || changes.address || changes.phone)
    ) {
      this.gridOptions.api.onFilterChanged();
    }
  }

  ngOnInit() {
    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;
  }

  onGridSizeChanged(params) {
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.columnApi.getColumns();
    const statusWidth = allColumns.find((col) => col.colId === 'status').getMinWidth();
    const nameWidth = allColumns.find((col) => col.colId === 'name').getMinWidth();
    const gridWidth = document.getElementById('grid-wrapper').offsetWidth - statusWidth - nameWidth;

    if (allColumns) {
      allColumns.forEach((column) => {
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth && column.colId !== 'status' && column.colId !== 'name') {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      });
    }

    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  onGridReady(params) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.animateRows = true;
    this.gridApi = params.api;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.suppressHorizontalScroll = true;
    if (this.listOfYoung && this.listOfYoung.length > 0) {
      this.setAffichageYoung();
    }
    this.gridOptions.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
  }

  setAffichageYoung() {
    const returnRows = [];
    if (this.gridOptions.api) {
      this.listOfYoung.forEach((young: RegisteredYoungListDTO) => {
        returnRows.push(this.getRowFromYoung(young));
      }, this);

      this.gridOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param young
   */
  getRowFromYoung(young: RegisteredYoungListDTO) {
    return {
      id: young.id,
      name: this.formatService.formatName(young),
      firstName: young.firstName,
      lastName: young.lastName,
      sex: young.sex,
      birthday: this.formatService.formatDate(young.birthday),
      address: young.address,
      nameCenter: young.nameCenter,
      nameRegion: young.nameRegion,
      isValidated: young.isValidated,
      listOfPrograms: young.listOfPrograms,
      isConnected: young.isConnected
    };
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  onSelectionChanged() {
    const newSelection = this.gridOptions.api.getSelectedNodes();

    this.selected = newSelection.slice(0, 2);

    if (newSelection.length > 2) {
      newSelection.slice(2).forEach((select) => {
        select.setSelected(false);
      });
    }
  }

  goToFusion() {
    if (this.selected.length === 2) {
      const idUser: number = this.selected[0].data.id;
      const idRelative: number = this.selected[1].data.id;

      this.router.navigate([`/young-fusion/step1/${idUser}/${idRelative}/false`]);
    }
  }

  isExternalFilterPresent() {
    return true;
  }

  doesExternalFilterPass(node: {data: RegisteredYoungListDTO}) {
    return this.checkFirstname(node) && this.checkLastname(node) && this.checkAddress(node);
  }

  private checkFirstname(node: {data: RegisteredYoungListDTO}) {
    return (
      !this.firstName ||
      (node.data?.firstName &&
        this.formatService
          .clearAccent(node.data?.firstName.toLowerCase().trim())
          .includes(this.firstName))
    );
  }

  private checkLastname(node: {data: RegisteredYoungListDTO}) {
    return (
      !this.lastName ||
      (node.data?.lastName &&
        this.formatService
          .clearAccent(node.data?.lastName.toLowerCase().trim())
          .includes(this.lastName))
    );
  }

  private checkAddress(node: {data: RegisteredYoungListDTO}) {
    return (
      !this.address ||
      (node.data?.address &&
        this.formatService
          .clearAccent(node.data?.address.toLowerCase().trim())
          .includes(this.address))
    );
  }
}
