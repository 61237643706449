<div class="d-flex flex-column">
  <div class="d-flex flex-row m-2">
    <h3 mat-dialog-title>Ajouter un document</h3>
    <span class="flex-fill separator"></span>
    <button (click)="close()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <app-alert id="documentAlert"></app-alert>
  <mat-dialog-content>
    <div (fileDropped)="onFileAdded($event)" *ngIf="filesAdded.length === 0" appDragDrop class="m-1 p-3 dropzone">
      <div class="mt-4 align-items-center justify-content-center row-container flex-wrap align-content-center">
        <div class="my-4 text-center">
          <p class="tiny-text">Faites glisser vos documents ici <br> ou</p>
          </div>
          <div class="row align-items-center justify-content-center">
            <div class="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 text-center">
              <button (click)="fileInput.click()" class="col btn btn-primary" mat-raised-button>
                <mat-icon class="icon mr-3">folder_open</mat-icon>
                Importer un document
                <input #fileInput (change)="onFileAdded($event.target.files)" style="display:none;" type="file"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="filesAdded.length > 0" class="mt-4">
        <div class="row-container align-content-center">
          <div *ngFor="let file of filesAdded; let index = index" class="row flex-wrap mx-4 p-1">
            <mat-icon class="d-none d-md-inline col-1 align-self-center">insert_drive_file</mat-icon>
            <mat-form-field class="col-4 col-md-5">
              <mat-label>Nom du document</mat-label>
              <input (change)="onNameEdited(index)" [formControl]="getControl(index, 'name')" matInput
                     placeholder="Nom du document">
            </mat-form-field>
            <div class="taille col-3 col-md-2">
              <div class="tiny-text ">Taille :</div>
              <div>{{ file.size }} octets</div>
            </div>
            <div class="col-1">
              <mat-icon (click)="deleteFile(index)" class="align-self-center btn">close</mat-icon>
            </div>
          </div>
        </div>
        <div class="row align-items-center justify-content-center">
          <div class="col-8 col-sm-6 col-md-4 col-lg-3">
            <button (click)="saveFiles()" class="col btn btn-primary" mat-stroked-button>
              <mat-icon class="mr-3">cloud_upload</mat-icon>
              Enregistrer
            </button>
          </div>
        </div>
      </div>
    </mat-dialog-content>
</div>




