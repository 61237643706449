<div class="d-flex flex-column">
  <div class="d-flex flex-row m-2">
    <h3 mat-dialog-title>{{ this.workExperience && this.workExperience.id > 0 ? 'Modifier ' : 'Ajouter ' }} une
      expérience</h3>
    <span class="flex-fill separator"></span>
    <button (click)="dismiss()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <app-alert id="workExperienceAlert"></app-alert>
  <form [formGroup]="workExperienceFormGroup">
    <mat-dialog-content>
      <div *ngIf="experienceCategories" class="d-flex flex-row align-items-center my-2 mx-2">
        <div class="col-md-6">
          <label class="star">Categorie</label>
          <mat-select [compareWith]="compareWithId"
                      [ngClass]="{'is-invalid': (fWorkExperience.category.dirty || fWorkExperience.category.touched) && fWorkExperience.category.invalid, 'disabled': fWorkExperience.category.disabled }"
                      class="custom-select"
                      formControlName="category">
            <mat-option *ngFor="let item of experienceCategories" [value]="item">{{ item.label }}</mat-option>
          </mat-select>
        </div>
      </div>

      <ng-container *ngIf="fWorkExperience.category.value">
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <ng-container *ngIf="showFieldsWork">
            <div class="col-md-6">
              <label class="star">Type de contrat</label>
              <mat-select [compareWith]="compareWith"
                          [ngClass]="{'is-invalid': (fWorkExperience.contractType.dirty || fWorkExperience.contractType.touched) && fWorkExperience.contractType.invalid, 'disabled': fWorkExperience.contractType.disabled}"
                          class="custom-select"
                          formControlName="contractType">
                <mat-optgroup *ngFor="let item of contractTypes" [label]="item.label">
                  <ng-container
                    *ngIf="item.listOfPContractType && item.listOfPContractType.length > 0; else singleOption">
                    <mat-option *ngFor="let sItem of item.listOfPContractType" [value]="sItem.id" class="select-item">
                      {{ sItem.label }}
                    </mat-option>
                  </ng-container>

                  <ng-template #singleOption>
                    <mat-option [value]="item.id" class="select-item">
                      {{ item.label }}
                    </mat-option>
                  </ng-template>

                </mat-optgroup>
              </mat-select>
            </div>
            <div class="col-md-6">
              <label>Besoin lié ?</label>
              <mat-select *ngIf="jobbeurNeeds && jobbeurNeeds.length > 0; else noNeed" [compareWith]="compareWith"
                          class="custom-select"
                          formControlName="jobbeurNeed"
                          [ngClass]="{'disabled': fWorkExperience.jobbeurNeed.disabled}">
                <mat-option *ngFor="let item of jobbeurNeeds"
                            [value]="item.id">{{ formatJobbeurNeedName(item) }}
                </mat-option>
              </mat-select>

              <ng-template #noNeed>
                <input class="form-control" disabled value="Aucune expérience saisie" />
              </ng-template>
            </div>
          </ng-container>

          <ng-container *ngIf="showFieldsFormation">
            <div class="col-md-6">
              <label class="star">Type de formation</label>
              <mat-select [compareWith]="compareWithId"
                          [ngClass]="{'is-invalid': (fWorkExperience.formationType.dirty || fWorkExperience.formationType.touched) && fWorkExperience.formationType.invalid, 'disabled': fWorkExperience.formationType.disabled}"
                          class="custom-select"
                          formControlName="formationType">
                <mat-option *ngFor="let item of formationTypes" [value]="item.id">{{ item.label }}</mat-option>
              </mat-select>
            </div>
          </ng-container>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label class="star">Structure</label>
            <!--@if(isReadOnly) {
              <input class="form-control"
                     disabled
                     type="text"
                     value="{{ fWorkExperience.structure.value.nom }}"/>
            } @else {-->
            <mat-select
              [ngClass]="{'is-invalid': (fWorkExperience.structure.dirty || fWorkExperience.structure.touched) && fWorkExperience.structure.invalid, 'disabled': fWorkExperience.structure.disabled}"
              [formGroup]="workExperienceFormGroup"
              class="custom-select"
              formControlName="structure"
              [compareWith]="compareWithId">
              <mat-option class="select-item">
                <ngx-mat-select-search [formControl]="entrepriseMultiFilterCtrl"
                                       noEntriesFoundLabel=""
                                       placeholderLabel="Trouvez une entreprise"></ngx-mat-select-search>
              </mat-option>
              <mat-option *ngFor="let entreprise of filteredEntrepriseObservable | async" [value]="entreprise">
                {{ entreprise.nom }}
              </mat-option>

              <ng-container
                *ngIf="entrepriseMultiFilterCtrl.value && entrepriseMultiFilterCtrl.value !== ''; else infoEntreprise">
                <div (click)="addStructure()" class="pointer add-btn-entreprise">Ajouter une entreprise non
                  partenaire
                </div>
              </ng-container>
              <ng-template #infoEntreprise>
                <div class="no-entreprise">Saisissez le nom de la structure</div>
              </ng-template>
            </mat-select>
            <!--            }-->
          </div>
          <div class="col-md-6">
            <label>Partenaire opérationnel Job</label>
            <input *ngIf="isNotNullOrUndefined(fWorkExperience.structure.value); else noEntrepriseSelected"
                   class="form-control"
                   disabled
                   type="text"
                   value="{{ this.formatService.formatMandatory(fWorkExperience.structure.value.convention) }}" />
            <ng-template #noEntrepriseSelected>
              <input class="form-control" disabled type="text" value="Aucune structure selectionnée" />
            </ng-template>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label>Nom du tuteur</label>
            <input class="form-control" formControlName="tutorLastName" type="search" />
          </div>
          <div class="col-md-6">
            <label>Prénom du tuteur</label>
            <input class="form-control" formControlName="tutorFirstName" type="search" />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label>Téléphone du tuteur</label>
            <input
              [ngClass]="{'is-invalid': (fWorkExperience.tutorPhone.dirty || fWorkExperience.tutorPhone.touched) && fWorkExperience.tutorPhone.invalid, 'disabled': fWorkExperience.tutorPhone.disabled}"
              class="form-control" formControlName="tutorPhone"
              type="search" />
          </div>
          <div class="col-md-6">
            <label>Email du tuteur</label>
            <input
              [ngClass]="{'is-invalid': (fWorkExperience.tutorEmail.dirty || fWorkExperience.tutorEmail.touched) && fWorkExperience.tutorEmail.invalid, 'disabled': fWorkExperience.tutorEmail.disabled}"
              class="form-control" formControlName="tutorEmail"
              type="search" />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label class="label-info spanForm star">Date de début</label>
            <div [ngClass]="{'disabled': fWorkExperience.dateBegin.disabled}" class="input-group">
              <input (keyup)="checkDateBegin()" [matDatepicker]="workExperienceBegin"
                     [ngClass]="{'is-invalid': (fWorkExperience.dateBegin.dirty || fWorkExperience.dateBegin.touched) && fWorkExperience.dateBegin.invalid}"
                     autocomplete="off" class="form-control"
                     formControlName="dateBegin">
              <div class="input-group-append">
                <button (click)="workExperienceBegin.open()" class="btn btn-outline-secondary form-control">
                  <mat-icon>calendar_month</mat-icon>
                </button>
              </div>
            </div>
            <mat-datepicker #workExperienceBegin></mat-datepicker>
          </div>
          <div class="col-md-6">
            <label [ngClass]="{'star': showMandatoryDate}" class="label-info spanForm">Date de fin</label>
            <div [ngClass]="{'disabled': fWorkExperience.dateEnd.disabled}" class="input-group">
              <input (keyup)="checkDateEnd()" [matDatepicker]="workExperienceEnd"
                     [ngClass]="{'is-invalid': (fWorkExperience.dateEnd.dirty || fWorkExperience.dateEnd.touched) && fWorkExperience.dateEnd.invalid}"
                     autocomplete="off" class="form-control"
                     formControlName="dateEnd">
              <div class="input-group-append">
                <button (click)="workExperienceEnd.open()" class="btn btn-outline-secondary form-control">
                  <mat-icon>calendar_month</mat-icon>
                </button>
              </div>
            </div>
            <mat-datepicker #workExperienceEnd></mat-datepicker>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label>Durée</label>
            <input [value]="calculDate()" class="form-control" disabled type="text" />
          </div>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div *ngIf="showFieldsWork || showFieldsFormation" class="col-md-6">
            <label *ngIf="showFieldsWork">Intitulé du poste</label>
            <label *ngIf="showFieldsFormation">Intitulé de la formation</label>
            <input class="form-control" formControlName="intitule" type="search" />
          </div>
          <div class="col-md-6">
            <label>Domaine</label>
            <mat-select [compareWith]="compareWith" [ngClass]="{'disabled': fWorkExperience.domaineMetier.disabled}"
                        class="custom-select"
                        formControlName="domaineMetier">
              <mat-option class="select-item">
                <ngx-mat-select-search [formControl]="domainMultiFilterCtrl"
                                       noEntriesFoundLabel="Aucun résultat correspondant"
                                       placeholderLabel="Trouvez un domaine"></ngx-mat-select-search>
              </mat-option>
              <ng-container *ngIf="filteredDomaineObservable; else noEntriesInterest">
                <mat-option *ngFor="let pDomainMetier of filteredDomaineObservable | async"
                            [value]="pDomainMetier.id">
                  {{ pDomainMetier.label }}
                </mat-option>
              </ng-container>

              <ng-template #noEntriesInterest>
                <mat-option value="">Aucun domaine</mat-option>
              </ng-template>
            </mat-select>
          </div>
        </div>
        <div *ngIf="showFieldsWork" class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label>Temps de travail</label>
            <mat-select [ngClass]="{'disabled': fWorkExperience.workDuration.disabled}" class="custom-select"
                        formControlName="workDuration">
              <mat-option *ngFor="let item of workDurationForSelect" [value]="item.id">{{ item.name }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div *ngIf="showFieldsWork" class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label>Code postal de l'emploi</label>
            <input
              [ngClass]="{'is-invalid': (fWorkExperience.workPostalCode.dirty || fWorkExperience.workPostalCode.touched) && fWorkExperience.workPostalCode.invalid, 'disabled': fWorkExperience.workPostalCode.disabled}"
              class="form-control" formControlName="workPostalCode"
              type="text" />
          </div>
          <div class="col-md-6">
            <label class="star">Apport du contrat</label>
            <mat-select *ngIf="contributionContractsForSelect && contributionContractsForSelect.length > 0"
                        [compareWith]="compareWith"
                        [ngClass]="{'is-invalid': (fWorkExperience.contributionContract.dirty || fWorkExperience.contributionContract.touched) && fWorkExperience.contributionContract.invalid, 'disabled': fWorkExperience.contributionContract.disabled}"
                        class="custom-select"
                        formControlName="contributionContract"
                        multiple>
              <mat-option *ngFor="let contribution of contributionContractsForSelect"
                          [value]="contribution.id">{{ contribution.name }}
              </mat-option>
            </mat-select>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label>Motif de fin</label>
            <mat-select [compareWith]="compareWithId" [ngClass]="{'disabled': fWorkExperience.workDuration.disabled}"
                        class="custom-select"
                        formControlName="workExperienceMotifEnd">
              <mat-option [value]="null">--</mat-option>
              <mat-option *ngFor="let item of experienceMotifEnd" [value]="item">{{ item.label }}</mat-option>
            </mat-select>
          </div>
        </div>
        <div *ngIf="showFieldsWork" class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label class="star">Contrat de travail transmis</label>
            <app-switch [(modelAttribute)]="workExperience.workContractTransmitted" [group]="workExperienceFormGroup"
                        fcName="workContractTransmitted"
                        textLabel="Non"
                        textLabelChecked="Oui"></app-switch>
          </div>
        </div>
        <div *ngIf="showFieldsFormation" class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-6">
            <label class="star">Diplôme / Certificat validé</label>
            <app-switch [(modelAttribute)]="workExperience.validatedDiploma" [group]="workExperienceFormGroup"
                        fcName="validatedDiploma"
                        textLabel="Non" textLabelChecked="Oui"></app-switch>
          </div>
        </div>

        <div class="d-flex flex-row align-items-center my-2 mx-2">
          <div class="col-md-12">
            <label>Commentaire</label>
            <textarea [ngClass]="{'disabled': fWorkExperience.comment.disabled}" class="col-lg-12 form-control"
                      formControlName="comment"></textarea>
          </div>
        </div>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <ng-container *ngIf="!isReadOnly else readOnly">
        <button (click)="saveWorkExperience()" *ngIf="!isSaving else btnLoading" class="btn btn-primary m-2"
                mat-button>
          Enregistrer
        </button>
        <button (click)="toggleDisable(true)" class="m-2" mat-button>
          Annuler
        </button>
      </ng-container>

      <ng-template #readOnly>
        <button (click)="toggleDisable(false)" *ngIf="!isSaving else btnLoading" class="btn btn-primary m-2"
                mat-button>
          Modifier
        </button>
        <button (click)="dismiss()" class="m-2" mat-button>
          Fermer
        </button>
      </ng-template>
    </mat-dialog-actions>
  </form>
</div>

<ng-template #btnLoading>
  <button class="mat-button m-4" mat-button>
    <mat-spinner [diameter]="50" class="custom-theme" color="primary"></mat-spinner>
  </button>
</ng-template>
