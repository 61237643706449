import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {FormatService} from 'src/app/services/format.service';
import {Router} from '@angular/router';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {GridEntrepriseDataService} from '../../../../services/grid-entreprise-data.service';
import {Entreprise} from '../../../../model/entreprise/entreprise.model';
import {EntrepriseInterestField} from '../../../../model/entreprise/entreprise-interest-field.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {PaginationComponent} from '../../pagination/pagination.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {GenericAgGridComponent} from '../generic-ag-grid/generic-ag-grid.component';
import {NgIf} from '@angular/common';
import {AlertComponent} from '../../alert/alert/alert.component';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-entreprise',
  templateUrl: './grid-entreprise.component.html',
  styleUrls: ['./grid-entreprise.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    NgIf,
    GenericAgGridComponent,
    MatProgressSpinnerModule,
    MatCardModule,
    PaginationComponent
  ]
})
export class GridEntrepriseComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Input() itemPerPage;
  @Output() pageChange = new EventEmitter<unknown>();
  itemsEntreprise: Paged<Entreprise>;

  rowClassRules;
  columnEntrepriseDefs;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  getRowHeight;
  rowSelection;
  public readonly langue = agGridLanguageFr;

  constructor(
    private readonly gridEntrepriseDataService: GridEntrepriseDataService,
    private readonly formatService: FormatService,
    private readonly router: Router
  ) {
    super();
    this.columnEntrepriseDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        colId: 'name',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Entreprise mère',
        field: 'entrepriseMere',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: 'Adresse',
        field: 'address',
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Secteur',
        field: 'sector',
        minWidth: 250
      },
      {
        headerName: 'Partenaire',
        field: 'partner',
        minWidth: 100
      }
    ];

    this.context = {componentParent: this};
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.gridEntrepriseDataService.entrepriseListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<Entreprise>) => {
        this.itemsEntreprise = data;
      });
  }

  setData(gridOptions: GridOptions) {
    const returnRows = [];
    if (gridOptions.api) {
      this.itemsEntreprise.items.forEach((entreprise: Entreprise) => {
        returnRows.push(this.getRowFromEntreprise(entreprise));
      }, this);

      gridOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param entreprise
   */
  getRowFromEntreprise(entreprise: Entreprise) {
    return {
      id: entreprise.id,
      name: entreprise.nom,
      entrepriseMere: isNotNullOrUndefined(entreprise.entrepriseMere)
        ? entreprise.entrepriseMere.nom
        : '/',
      address: isNotNullOrUndefined(entreprise.address)
        ? this.formatService.formatAddress(entreprise.address)
        : '/',
      partner: this.formatService.formatMandatory(entreprise.entreprisePartenaire),
      sector:
        entreprise.listOfEntrepriseInterestField &&
        entreprise.listOfEntrepriseInterestField.length > 0
          ? this.formatSectors(entreprise.listOfEntrepriseInterestField)
          : 'Aucun secteur défini'
    };
  }

  formatSectors(entrepriseInterest: EntrepriseInterestField[]) {
    const sectors = [];
    entrepriseInterest.forEach((value) => sectors.push(value.pInterestField.label));
    return sectors.join(', ');
  }

  onRowClick(event) {
    this.router.navigate(['structures/detail/', event.data.id]);
  }

  emitPageChange() {
    this.pageChange.emit();
  }
}
