import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

import {OccurrencePlayoutCurrentlyViewedService} from 'src/app/services/occurrence-playout-currently-viewed.service';

import {PlayOut} from 'src/app/model/event/play-out.model';
import {PlaceNombreEventCreationService} from '../../../../../services/place-nombre-event-creation.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-delete-btn-renderer',
  templateUrl: './delete-playout-btn-renderer.component.html',
  styleUrls: ['./delete-playout-btn-renderer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class DeletePlayOutBtnRendererComponent
  extends Destroyed
  implements OnInit, ICellRendererAngularComp
{
  data;
  playOutList: PlayOut[] = [];
  nombreJeunesTotal: number;
  // Nombre total sur l'evenement
  nombreJeunesEvent: number;

  constructor(
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService,
    private readonly placeNombreJeuneService: PlaceNombreEventCreationService
  ) {
    super();
  }

  ngOnInit() {
    this.occurrencePlayoutCurrentlyViewedService.playOutMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => (this.playOutList = data));
  }

  refresh(): boolean {
    return true;
  }

  agInit(params): void {
    this.data = params.data;
  }

  onBtnClick() {
    if (this.data && this.data.status === 0) {
      const selected = this.data.idPlace;
      const index = this.playOutList.findIndex((obj) => obj.idPlace === selected);
      this.playOutList.splice(index, 1);
      this.occurrencePlayoutCurrentlyViewedService.changePlayOutList(this.playOutList);
      // Recupere les valeurs
      this.placeNombreJeuneService.nombrePlaceTotalPlayOutMessager
        .pipe(this.untilDestroyed())
        .subscribe((value) => {
          if (!value) {
            this.nombreJeunesTotal = 0;
          } else {
            this.nombreJeunesTotal = value;
          }
        });
      this.placeNombreJeuneService.nombrePlaceEventMessager.subscribe((value) => {
        this.nombreJeunesEvent = value;
      });

      this.placeNombreJeuneService.changeNombrePlaceTotalPlayoutList(
        this.nombreJeunesTotal - this.data.numbersYoungs
      );
    }
  }
}
