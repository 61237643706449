<h2 class="pb-4 text-center">Modifier une occurrence</h2>
<mat-dialog-content class="mat-typography">
  <form #f="ngForm">
  <div class="container">
    <div class="row">
      <div class="d-flex flex-row align-items-center col-md-6">
        <label>Durée de l'occurrence actuelle </label>
        <div class="d-flex flex-column col-5 justify-content-center  mb-2">{{this.occurrence.time}}</div>
      </div>
      <div class="d-flex flex-row align-items-center col-md-6">

          <label for="newTime">Nouvelle durée </label>
          <div class="d-flex flex-column col-5 justify-content-center align-items-center  mb-2">
            <input name="newTime" id="newTime" required
                   [(ngModel)]="newTimeOccurrence"
                   class="px-2 justify-content-center align-items-start"
                   [matTooltipPosition]="'right'"
                   matTooltip="Saisir la nouvelle durée de l'occurrence"
                   type="time">
          </div>
      </div>
    </div>
  </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="row m-top-2 col-12 d-flex justify-content-evenly h-auto">
    <button (click)="updateOccurrence()" class="btn btn-primary col-3" [disabled]="!f.valid">Modifier</button>
    <button (click)="cancelUpdateOccurrence()" class="btn btn-danger col-3">Annuler</button>
  </div>
</mat-dialog-actions>
