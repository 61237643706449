import {PInterestField} from '../param/p-interest-field.model';
import {Monitoring} from './monitoring.model';

export class MonitoringInterestField {
  id: number;
  idMonitoring: number;
  idPInterestField: number;

  pInterestField: PInterestField;
  monitoring: Monitoring;
}
