import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Router} from '@angular/router';
import {GridOptions, IRowNode} from '@ag-grid-community/core';
import {User} from 'src/app/model/user/user.model';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {AsyncPipe} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {AgGridModule} from '@ag-grid-community/angular';
import {ConnectedStatusEnum} from '../../../../model/enums/connected-status.enum';

@Component({
  selector: 'app-grid-fusion-tutor',
  templateUrl: './grid-fusion-tutor.component.html',
  styleUrls: ['./grid-fusion-tutor.component.scss'],
  standalone: true,
  imports: [AgGridModule, MatButtonModule, AsyncPipe]
})
export class GridFusionTutorComponent implements OnInit, OnChanges {
  @Input() listOfTutor: User[];

  @Input() firstName: string;
  @Input() lastName: string;
  @Input() address: string;
  @Input() phone: string;

  selected: IRowNode[];

  rowClassRules;
  columnDefs: unknown;
  rowSelection: string;
  gridOptions: GridOptions;
  gridApi: unknown;

  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;
  langue = agGridLanguageFr;
  paginationSize = 25;

  constructor(private readonly formatService: FormatService, public router: Router) {
    this.columnDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        colId: 'name',
        cellRenderer: NameCellRendererComponent,
        checkboxSelection: true,
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        minWidth: 50,
        maxWidth: 70
      },
      {
        headerName: 'Tel.',
        field: 'phone',
        sortable: true,
        minWidth: 180,
        maxWidth: 200
      },
      {
        headerName: 'Adresse',
        field: 'address',
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Vérifié',
        field: 'isConnected',
        colId: 'status',
        valueFormatter: this.formatIsConnected.bind(this),
        sortable: true,
        minWidth: 150,
        maxWidth: 200
      }
    ];

    this.context = {componentParent: this};
    this.frameworkComponents = {
      nameCellRenderer: NameCellRendererComponent
    };
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checkIfListTutorsChangeSize(changes)) {
      this.setAffichageListTuteurs();
    }

    if (
      this.gridOptions &&
      this.gridOptions.api &&
      (changes.firstName || changes.lastName || changes.address || changes.phone)
    ) {
      this.gridOptions.api.onFilterChanged();
    }
  }

  setAffichageListTuteurs() {
    if (this.listOfTutor && this.gridOptions) {
      this.setAffichage();
    }
  }

  checkIfListTutorsChangeSize(changes: SimpleChanges): boolean {
    return (
      changes.listOfTutor &&
      changes.listOfTutor.previousValue &&
      changes.listOfTutor.currentValue &&
      changes.listOfTutor.previousValue.length !== changes.listOfTutor.currentValue.length
    );
  }

  onGridSizeChanged(params) {
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.columnApi.getColumns();
    const statusWidth = allColumns.find((col) => col.colId === 'status').getMinWidth();
    const nameWidth = allColumns.find((col) => col.colId === 'name').getMinWidth();
    const gridWidth = document.getElementById('grid-wrapper').offsetWidth - statusWidth - nameWidth;

    if (allColumns) {
      allColumns.forEach((column) => {
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth && column.colId !== 'status' && column.colId !== 'name') {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      });
    }

    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  onGridReady(params) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.animateRows = true;
    this.gridApi = params.api;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.suppressHorizontalScroll = true;
    if (this.listOfTutor && this.listOfTutor.length > 0) {
      this.setAffichage();
    }

    this.gridOptions.isExternalFilterPresent = this.isExternalFilterPresent.bind(this);
    this.gridOptions.doesExternalFilterPass = this.doesExternalFilterPass.bind(this);
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridOptions.api) {
      this.listOfTutor.forEach((user: User) => {
        returnRows.push(this.getRow(user));
      }, this);

      this.gridOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un tuteur pour l'affichagé sur une ligne
   * @param user
   */
  getRow(user: User) {
    return {
      id: user.id,
      name: this.formatService.formatName(user),
      firstName: user.firstName,
      lastName: user.lastName,
      sex: user.sex,
      address: user.address ? this.formatService.formatAddress(user.address) : '',
      isConnected: user.isConnected,
      phone: user.phone
    };
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  formatIsConnected(params) {
    return ConnectedStatusEnum.format(params.value);
  }

  onSelectionChanged() {
    const newSelection = this.gridOptions.api.getSelectedNodes();

    this.selected = newSelection.slice(0, 2);

    if (newSelection.length > 2) {
      newSelection.slice(2).forEach((select) => {
        select.setSelected(false);
      });
    }
  }

  goToFusion() {
    if (this.selected.length === 2) {
      const idUser: number = this.selected[0].data.id;
      const idRelative: number = this.selected[1].data.id;

      this.router.navigate([`/young-fusion/step1/${idUser}/${idRelative}/true`]);
    }
  }

  isExternalFilterPresent() {
    return true;
  }

  doesExternalFilterPass(node) {
    const data = node.data;
    const formatService = this.formatService;

    return (
      (!this.firstName ||
        formatService.clearAccent(data.firstName?.toLowerCase().trim()).includes(this.firstName)) &&
      (!this.lastName ||
        formatService.clearAccent(data.lastName?.toLowerCase().trim()).includes(this.lastName)) &&
      (!this.address ||
        formatService.clearAccent(data.address?.toLowerCase().trim()).includes(this.address)) &&
      (!this.phone ||
        formatService.clearAccent(data.phone?.toLowerCase().trim()).includes(this.phone))
    );
  }
}
