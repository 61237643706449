import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridApi, GridOptions} from '@ag-grid-community/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {EditBtnRendererComponent} from '../custom-cell/edit-btn-renderer/edit-btn-renderer.component';
import {Router} from '@angular/router';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {ConnectedStatusEnum} from '../../../../model/enums/connected-status.enum';
import {Destroyed} from '../../directives/destroyed.directive';
import {RegisteredYoungLdlvDTO} from '../../../../model/dto/registered-young-ldlv.dto';
import {ProfileYoungLdlvService} from '../../../users/young/listing/profile-young/profile-young-ldlv/profile-young-ldlv.service';
import {OpenNewTabBtnComponent} from '../custom-cell/open-new-tab-btn/open-new-tab-btn.component';
import {BooleanCellRendererComponent} from '../custom-cell/boolean-cell-renderer/boolean-cell-renderer.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PaginationComponent} from '../../pagination/pagination.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-profile-young-ldlv',
  templateUrl: './grid-profile-young-ldlv.component.html',
  styleUrls: ['./grid-profile-young-ldlv.component.scss'],
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    NgIf,
    AgGridModule,
    PaginationComponent,
    MatProgressSpinnerModule,
    AsyncPipe
  ]
})
export class GridProfileYoungLdlvComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Output() pageChange = new EventEmitter<unknown>();
  listOfYoung: RegisteredYoungLdlvDTO[];
  itemsYoung: Paged<RegisteredYoungLdlvDTO>;

  rowClassRules;
  gridYoungLdlvApi: GridApi;
  columnYoungDefs;
  gridYoungLdlvOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly gridProfileYoungLdlvDataService: ProfileYoungLdlvService,
    private readonly formatService: FormatService,
    private readonly router: Router
  ) {
    super();
    this.columnYoungDefs = [
      {
        headerName: '',
        colId: 'btn',
        cellRenderer: OpenNewTabBtnComponent,
        suppressSizeToFit: true,
        width: 70,
        flex: 0
      },
      {
        headerName: 'Nom',
        field: 'name',
        colId: 'name',
        cellRenderer: NameCellRendererComponent
      },
      {
        headerName: 'Programme(s)',
        field: 'program'
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        width: 55
      },
      {
        headerName: 'Centre',
        field: 'nameCenter',
        sortable: true
      },
      {
        headerName: 'Naissance',
        field: 'birthday',
        sortable: true,
        width: 80
      },
      {
        headerName: 'Téléphone',
        field: 'phone',
        sortable: true,
        width: 80
      },
      {
        headerName: 'Email',
        field: 'email',
        sortable: true
      },
      {
        headerName: "Droit à l'image",
        headerTooltip: "Droit à l'image",
        headerComponentParams: {template: '<mat-icon class="header-icon-grid">portrait</mat-icon>'},
        field: 'imageRightAutorization',
        colId: 'imageRightAutorization',
        cellRenderer: BooleanCellRendererComponent,
        sortable: true,
        width: 55,
        flex: 0
      },
      {
        headerName: 'Droit de contact',
        headerTooltip: 'Droit de contact',
        headerComponentParams: {template: '<mat-icon class="header-icon-grid">phone</mat-icon>'},
        field: 'contactAccepted',
        colId: 'contactAccepted',
        cellRenderer: BooleanCellRendererComponent,
        sortable: true,
        width: 55,
        flex: 0
      },
      {
        headerName: '',
        colId: 'btn',
        cellRenderer: EditBtnRendererComponent,
        suppressSizeToFit: true,
        width: 70,
        pinned: 'right'
      }
    ];
    this.context = {componentParent: this};
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.gridYoungLdlvOption = {
      defaultColDef: {
        flex: 1,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridProfileYoungLdlvDataService.youngListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<RegisteredYoungLdlvDTO>) => {
        if (data) {
          this.itemsYoung = data;
          this.listOfYoung = data.items;
          this.setAffichage();
        }
      });
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridYoungReady(params) {
    this.gridYoungLdlvOption.api.sizeColumnsToFit();
    this.gridYoungLdlvOption.animateRows = true;
    this.gridYoungLdlvApi = params.api;
    this.gridYoungLdlvOption.suppressCellFocus = true;
    this.gridYoungLdlvOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridYoungLdlvOption.api) {
      this.itemsYoung.items.forEach((young: RegisteredYoungLdlvDTO) => {
        returnRows.push(this.getRowFromYoung(young));
      }, this);

      this.gridYoungLdlvOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param young
   */
  getRowFromYoung(young: RegisteredYoungLdlvDTO) {
    return {
      id: young.id,
      name: this.formatService.formatName(young),
      firstName: young.firstName,
      lastName: young.lastName,
      sex: young.sex,
      birthday: this.formatService.formatDate(young.birthday),
      nameCenter: young.nameCenter,
      phone: young.phone,
      email: young.email,
      program: young.programs,
      contactAccepted: !!young.contactAccepted,
      imageRightAutorization: !!young.imageRightAutorization
    };
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  onRowClick(event) {
    if (event.colDef.colId !== 'btn') {
      if (event.data.isConnected !== ConnectedStatusEnum.WEB) {
        this.router.navigate(['young-info', event.data.id]);
      } else {
        this.router.navigate(['young-validation', event.data.id]);
      }
    }
  }

  export() {
    const processCells = (cell) => {
      const cellVal = cell.value;

      if (cell.column.colId === 'status') {
        return this.formatService.formatIsValidatedStatus(cellVal);
      } else if (cell.column.colId === 'sex') {
        return this.formatService.formatSex(cellVal, true);
      } else {
        return cellVal;
      }
    };

    this.gridYoungLdlvOption.api.exportDataAsCsv({
      processCellCallback: (params) => processCells(params)
    });
  }

  rowSelected() {
    if (this.gridYoungLdlvApi) {
      return this.gridYoungLdlvApi.getSelectedNodes().length <= 0;
    } else {
      return true;
    }
  }

  emitPageChange() {
    this.pageChange.emit();
  }
}
