import {Component} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {MatChipsModule} from '@angular/material/chips';
import {NgIf} from '@angular/common';
import {MatProgressBarModule} from '@angular/material/progress-bar';

@Component({
  selector: 'app-taux-cell-renderer-custom',
  templateUrl: './taux-cell-renderer.component.html',
  styleUrls: ['./taux-cell-renderer.component.scss'],
  standalone: true,
  imports: [MatProgressBarModule, NgIf, MatChipsModule]
})
export class TauxCellRendererComponent implements ICellRendererAngularComp {
  public tauxPresence: number;
  public tauxIncrease: boolean;
  public additionnalRate: number;

  constructor() {
    this.tauxPresence = 0;
    this.tauxIncrease = true;
    this.additionnalRate = 0;
  }

  agInit(params): void {
    if (params.data.presence) {
      if (params.data.presence.tauxPresence) {
        this.tauxPresence = params.data.presence.tauxPresence;
      }
      if (params.data.presence.additionnalRate) {
        this.additionnalRate = params.data.presence.additionnalRate;
      }
      this.tauxIncrease = params.data.presence.tauxIncrease;
    }
  }

  refresh(params): boolean {
    return false;
  }
}
