import {WorkExperience} from './work-experience.model';
import {PContributionContract} from '../param/p-contribution-contract.model';

export class ContributionContract {
  id: number;
  idWorkExperience: number;
  idPContributionContract: number;

  workExperience: WorkExperience;
  pContributionContract: PContributionContract;
}
