import {Component} from '@angular/core';
import {Destroyed} from '../../../directives/destroyed.directive';
import {onlyAdmin} from '../../../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../../../services/current-user.service';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-delete-relation-btn',
  templateUrl: './delete-relation-btn.component.html',
  styleUrls: ['./delete-relation-btn.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule]
})
export class DeleteRelationBtnComponent extends Destroyed {
  public data;
  private action;

  constructor(private readonly currentUserService: CurrentUserService) {
    super();
  }

  get isAdminOrAdminLvl1(): boolean {
    return this.currentUserService.checkModule(...onlyAdmin);
  }

  refresh(): boolean {
    return true;
  }

  agInit(params): void {
    this.data = params.data;
    this.action = params.action;
  }

  deleteRelation() {
    if (this.action && this.data) {
      this.action(this.data);
    }
  }
}
