import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Paged} from 'src/app/model/response/paged.model';
import {RegisteredYoungJobItemDTO} from 'src/app/model/dto/registered-young-job-item.dto';

@Injectable()
export class ProfileYoungJobService {
  private readonly dataYoungList = new BehaviorSubject(null);
  public youngListMessager = this.dataYoungList.asObservable();

  changeYoungList(listOfYoung: Paged<RegisteredYoungJobItemDTO>) {
    this.dataYoungList.next(listOfYoung);
  }
}
