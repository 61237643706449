import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CellClickedEvent, GridOptions, GridSizeChangedEvent} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {AsyncPipe} from '@angular/common';
import {AgGridModule} from '@ag-grid-community/angular';

@Component({
  selector: 'app-generic-ag-grid',
  templateUrl: './generic-ag-grid.component.html',
  styleUrls: ['./generic-ag-grid.component.scss'],
  standalone: true,
  imports: [AgGridModule, AsyncPipe]
})
export class GenericAgGridComponent {
  @Input() columnEventDefs: unknown;
  @Output() rowClick = new EventEmitter();
  @Output() eventGridReady = new EventEmitter();
  rowClassRules: unknown;
  domLayout: unknown = 'autoHeight';
  gridApi: unknown;

  gridOptions = {
    defaultColDef: {
      // make all cols more narrow
      width: 100,
      resizable: true,
      filter: false,
      sortable: false,
      flex: 1
    },
    suppressMenuHide: true,

    overlayLoadingTemplate: 'Chargement des données...',
    overlayNoRowsTemplate: 'Aucune donnée',

    rowDragManaged: false,
    headerHeight: 50,
    rowHeight: 60
  } as GridOptions;
  rowData: unknown;
  context: unknown;
  public langue = agGridLanguageFr;

  onRowClick($event: CellClickedEvent<unknown>) {
    this.rowClick.emit($event);
  }

  onEventGridReady(params) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.animateRows = true;
    this.gridApi = params.api;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.suppressHorizontalScroll = true;
    this.eventGridReady.emit(this.gridOptions);
  }

  onGridSizeChanged($event: GridSizeChangedEvent<unknown>) {
    this.gridOptions.api.sizeColumnsToFit();
  }
}
