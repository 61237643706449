import {Component} from '@angular/core';
import {MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {AlertService} from '../../../../services/alert.service';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {formatDate, NgFor, NgIf} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DragDropDirective} from '../../directives/drag-drop.directive';
import {AlertComponent} from '../../alert/alert/alert.component';

@Component({
  selector: 'app-dialog-add-doc',
  templateUrl: './dialog-add-doc.component.html',
  styleUrls: ['./dialog-add-doc.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    AlertComponent,
    MatDialogContent,
    NgIf,
    DragDropDirective,
    MatButtonModule,
    MatIconModule,
    NgFor,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class DialogAddDocComponent extends Destroyed {
  filesAdded = [];

  validExtensions: string[] = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];

  controls: UntypedFormArray;

  isInterview = false;
  isEvent = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddDocComponent>,
    private readonly paramWebservice: ParamWebservice,
    private readonly alertService: AlertService
  ) {
    super();
  }

  close() {
    this.dialogRef.close();
  }

  onFileAdded($event: File[]) {
    const tempListOfFilesAdded = [];
    if ($event.length > 1) {
      this.alertService.error("Vous ne pouvez ajouter qu'un seul document", 'documentAlert');
    } else {
      tempListOfFilesAdded.push($event[0]);
    }
    if (this.checkExtension(tempListOfFilesAdded)) {
      this.alertService.error(
        "Format incorrect: Le fichier n'a pas le bon format. Seuls les types de fichiers suivants sont acceptés :\n● pdf\n● jpg\n● jpeg\n● png\n● pdf\n● word",
        'documentAlert'
      );
    } else {
      this.filesAdded = tempListOfFilesAdded;
      this.createForm();
    }
  }

  createForm() {
    const toGroups = this.filesAdded.map((file, index) => {
      // On retire l'extension du fichier
      //const fileNameWithoutExtArray = file.name.split('.');
      //fileNameWithoutExtArray.pop();
      const date = new Date();
      let newFileName = `${formatDate(date, 'yyyyMMddHHmmss', 'fr-FR')}_`;

      if (this.isInterview) {
        newFileName += 'Entretien';
      }

      if (this.isEvent) {
        newFileName += 'FichePresenceFSE';
      }

      // On recrée le nom à partir de l'array (dans le cas où le nom contient des .)
      //const fileNameWithoutExt = fileNameWithoutExtArray.join('.');
      return new UntypedFormGroup({
        name: new UntypedFormControl(newFileName, Validators.required),
        idPDocument: new UntypedFormControl(file.idPDocument, Validators.required)
      });
    });
    this.controls = new UntypedFormArray(toGroups);
  }

  checkExtension(listOfFiles) {
    let isExtensionInvalid = false;
    listOfFiles.forEach((f) => {
      const ext: string = f.name.split('.').pop();
      if (!this.validExtensions.find((e) => e === ext.toLowerCase())) {
        isExtensionInvalid = true;
      }
    });
    return isExtensionInvalid;
  }

  getControl(index: number, field: string) {
    return this.controls.at(index).get(field);
  }

  onNameEdited(index) {
    // On récupère l'extension du fichier
    const fileExt = this.filesAdded[index].name.split('.').pop();

    // On crée un nouveau File car la classe File n'a pas de setter .-.
    const myFile = this.filesAdded[index];
    const myNewFile = new File([myFile], this.controls.controls[index].value.name + '.' + fileExt, {
      type: myFile.type
    });
    this.filesAdded[index] = myNewFile;
  }

  deleteFile(index: number) {
    this.filesAdded.splice(index, 1);
    this.createForm();
  }

  saveFiles() {
    if (this.filesAdded && this.filesAdded.length > 0) {
      for (let i = 0; i < this.filesAdded.length; i++) {
        this.onNameEdited(i);
      }
      this.dialogRef.close(this.filesAdded);
    } else {
      this.alertService.warn("Aucun document n'est importé", 'documentAlert');
    }
  }
}
