import {Component, OnInit} from '@angular/core';
import {RoleSettingDataService} from 'src/app/services/role-setting-data.service';
import {Role} from 'src/app/model/role/role.model';
import {GridOptions} from '@ag-grid-community/core';
import {DeleteRoleBtnRendererComponent} from '../custom-cell/delete-role-btn-renderer/delete-role-btn-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {Destroyed} from '../../directives/destroyed.directive';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-role',
  templateUrl: './grid-role.component.html',
  styleUrls: ['./grid-role.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridRoleComponent extends Destroyed implements OnInit {
  listOfRoles: Role[] = [];

  rowClassRules;
  columnRoleDefs;
  isRoleLoading = true;
  gridRoleOptions: GridOptions;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  gridRoleApi: unknown;
  context;
  paginationSize = 10;
  langue = agGridLanguageFr;

  constructor(
    private readonly roleSettingDataService: RoleSettingDataService,
    private readonly formatService: FormatService
  ) {
    super();
    this.columnRoleDefs = [
      {
        headerName: 'Id',
        field: 'id',
        hide: true,
        sortable: true
      },
      {
        headerName: 'Pole',
        field: 'poleType',
        filter: 'agTextColumnFilter',
        filterParams: {filterOptions: ['contains']},
        suppressMenu: true,
        floatingFilterComponentParams: {suppressFilterButton: true},
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Position',
        field: 'positionType',
        filter: 'agTextColumnFilter',
        filterParams: {filterOptions: ['contains']},
        suppressMenu: true,
        floatingFilterComponentParams: {suppressFilterButton: true},
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Statut',
        field: 'status',
        sortable: true,
        minWidth: 100
      },
      {
        headerName: '',
        cellRenderer: DeleteRoleBtnRendererComponent,
        colId: 'delete',
        minWidth: 50
      }
    ];
    this.context = {componentParent: this};
  }

  ngOnInit() {
    this.gridRoleOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.roleSettingDataService.fullRoleListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Role[]) => {
        this.listOfRoles = data;
        this.isRoleLoading = false;
        this.setRoleAffichage();
      });
  }

  onGridSizeChanged() {
    this.gridRoleOptions.api.sizeColumnsToFit();
  }

  onRoleGridReady(params) {
    this.gridRoleOptions.api.sizeColumnsToFit();
    this.gridRoleOptions.animateRows = true;
    this.gridRoleApi = params.api;
    this.gridRoleOptions.suppressCellFocus = true;
    this.gridRoleOptions.suppressHorizontalScroll = true;
    this.setRoleAffichage();
  }

  setRoleAffichage() {
    const returnRows = [];
    if (this.gridRoleOptions.api) {
      if (this.listOfRoles) {
        this.listOfRoles.forEach((role: Role) => {
          returnRows.push(this.getRowFromRole(role));
        }, this);
        this.gridRoleOptions.api.setRowData(returnRows);
      }
    }
  }

  getRowFromRole(role: Role) {
    return {
      id: role.id,
      poleType: role.poleType,
      positionType: role.positionType,
      status: this.formatService.formatStatusEnum(role.status)
    };
  }
}
