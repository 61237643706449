import {WorkDurationEnum} from '../enums/work-duration.enum';
import {Young} from './young.model';
import {Entreprise} from '../entreprise/entreprise.model';
import {User} from './user.model';
import {PExperienceCategory} from '../param/p-experience-category.model';
import {PContractType} from '../param/p-contract-type.model';
import {PFormationType} from '../param/p-formation-type.model';
import {PExperienceEndMotif} from '../param/p-experience-end-motif.model';
import {ContributionContract} from './contribution-contract.model';
import {PDomaineMetier} from '../param/p-domaine-metier.model';

export class WorkExperience {
  id: number;
  idYoung: number;
  idPExperienceCategory: number;
  idPContractType?: number;
  idPFormationType?: number;
  idEntreprise: number;
  firstAndLastNameTuteur: string;
  mailTutor: string;
  phoneTutor: string;
  dateBegin: Date;
  dateEnd?: Date;
  duration?: number;
  intitule: string;
  idPDomainMetier?: number;
  workDuration?: WorkDurationEnum;
  workPostalCode: string;
  idPExperienceEndMotif?: number;
  validatedDiploma?: boolean;
  comment: string;
  workContractTransmitted?: boolean;
  idCreateBy: number;
  idLastUpdateBy?: number;
  dateCreate: Date;
  dateUpdate?: Date;
  idJobbeurNeed?: number;

  young: Young;
  pExperienceCategory: PExperienceCategory;
  pContractType: PContractType;
  pFormationType: PFormationType;
  pExperienceEndMotif: PExperienceEndMotif;
  listOfContributionContract: ContributionContract[];
  pDomaineMetier: PDomaineMetier;
  entreprise: Entreprise;

  lastUpdateBy: User;
  createBy: User;
}
