<button [matMenuTriggerFor]="menuSettings"
        aria-label="Paramètres"
        mat-icon-button>
  <mat-icon>more_horiz</mat-icon>
</button>
<mat-menu #menuSettings="matMenu">
  <button *appHasModule="canShowBtn" (click)="onShowVisitBtnClick()" mat-menu-item>
    Voir la visite
  </button>
  <button *appHasModule="onlyAdmin" (click)="onDeleteBtnClick()" mat-menu-item>
    Supprimer la visite
  </button>
</mat-menu>
