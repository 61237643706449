import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {PlaceNombreEventCreationService} from '../../../../../services/place-nombre-event-creation.service';
import {RiOnEventService} from '../../../../../services/ri-on-event.service';
import {RegisteredResponsable} from '../../../../../model/event/registered-responsable.model';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-delete-ri-btn-renderer',
  templateUrl: './delete-ri-btn-renderer.component.html',
  styleUrls: ['./delete-ri-btn-renderer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class DeleteRiBtnRendererComponent
  extends Destroyed
  implements OnInit, ICellRendererAngularComp
{
  data;
  registeredResponsableList = new Array<RegisteredResponsable>();
  nombreJeunesTotal: number;
  // Nombre total sur l'evenement
  nombreJeunesEvent: number;

  constructor(
    private readonly riOnEventService: RiOnEventService,
    private readonly placeNombreJeuneService: PlaceNombreEventCreationService
  ) {
    super();
  }

  ngOnInit() {
    this.riOnEventService.registeredResponsableMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => (this.registeredResponsableList = data));
  }

  refresh(): boolean {
    return true;
  }

  agInit(params): void {
    this.data = params.data;
  }

  onBtnClick() {
    if (this.data) {
      if (this.data.status === 0) {
        const selected = this.data.idUser;
        const index = this.registeredResponsableList.findIndex((obj) => obj.idUser === selected);
        this.registeredResponsableList.splice(index, 1);
        this.riOnEventService.changeRegisteredResponsableList(this.registeredResponsableList);
        // Recupere les valeurs
        this.placeNombreJeuneService.nombrePlaceTotalPlayOutMessager
          .pipe(this.untilDestroyed())
          .subscribe((value) => {
            if (!value) {
              this.nombreJeunesTotal = 0;
            } else {
              this.nombreJeunesTotal = value;
            }
          });
        this.placeNombreJeuneService.nombrePlaceEventMessager
          .pipe(this.untilDestroyed())
          .subscribe((value) => {
            this.nombreJeunesEvent = value;
          });

        this.placeNombreJeuneService.changeNombrePlaceTotalPlayoutList(
          this.nombreJeunesTotal - this.data.numbersYoungs
        );
      }
    }
  }
}
