<ng-container *ngIf="massModification.listOfYoung">
  <h2 mat-dialog-title>Modification de masse</h2>
  <mat-dialog-content class="mat-typography">
    <p>Vous allez modifier {{massModification.listOfYoung.length}} jeunes
    <div class="d-flex flex-row align-items-center my-2 mx-2">
      <mat-form-field appearance="outline">
        <mat-label>Modification du statut</mat-label>
        <mat-select [(ngModel)]="massModification.statut">
          <mat-option *ngFor="let item of statusList" [value]="item.id">{{item.label}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button (click)="onDismiss()" mat-button mat-dialog-close>Annuler</button>
    <button (click)="updateYoung()" [disabled]="massModification.statut === undefined" cdkFocusInitial mat-button>
      Confirmer
    </button>
  </mat-dialog-actions>
</ng-container>


