<app-alert></app-alert>
<ng-container *ngIf="!isLoading; else loading">
  <div *ngIf="itemsEntreprise && itemsEntreprise.items.length > 0; else noData" id="grid-wrapper">
    <app-generic-ag-grid
      (eventGridReady)="setData($event)"
      (rowClick)="onRowClick($event)"
      [columnEventDefs]="columnEntrepriseDefs"
    ></app-generic-ag-grid>
  </div>
</ng-container>


<ng-template #loading>
  <div class="d-flex flex-row justify-content-center align-items-center py-5">
    <label>Chargement en cours...</label>
  </div>
</ng-template>

<ng-template #noData>
  <mat-card appearance="outlined" class="m-4">
    <h5 class="m-2">Aucune donnée ne correspond à ces critères</h5>
  </mat-card>
</ng-template>


<app-pagination (pageChange)="emitPageChange()" [itemPerPage]="itemPerPage"></app-pagination>
