import {Component, OnInit} from '@angular/core';
import {FormatService} from '../../../../../../services/format.service';
import {YoungWebservice} from '../../../../../../services/webservices/young.webservice';
import {MassModificationRiDto} from '../../../../../../model/dto/mass-modification-ri.dto';
import {UserWebservice} from '../../../../../../services/webservices/user.webservice';
import {FormsModule, ReactiveFormsModule, UntypedFormControl} from '@angular/forms';
import {ReplaySubject} from 'rxjs';
import {User} from '../../../../../../model/user/user.model';
import {FilterYoungJob} from '../../../../../../model/filter/filter-young-job.model';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {Destroyed} from '../../../../../shared/directives/destroyed.directive';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
  selector: 'app-mass-modification-modal',
  templateUrl: './mass-modification-modal-ri.component.html',
  styleUrls: ['./mass-modification-modal-ri.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    MatOptionModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    NgFor,
    MatDialogActions,
    MatButtonModule,
    NgIf,
    MatProgressSpinnerModule,
    AsyncPipe
  ]
})
export class MassModificationModalRiComponent extends Destroyed implements OnInit {
  listOfId: number[];
  searchYear: number;
  massModification: MassModificationRiDto;
  filter: FilterYoungJob;

  // RI
  public riMultiFilterCtrl = new UntypedFormControl();
  public filteredRiObservable: ReplaySubject<User[]> = new ReplaySubject<User[]>(1);
  public listRi: User[];
  public isBtnLoading: boolean = false;

  constructor(
    private readonly dialogRef: MatDialogRef<MassModificationModalRiComponent>,
    private readonly formatService: FormatService,
    private readonly youngService: YoungWebservice,
    private readonly userWS: UserWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.massModification = new MassModificationRiDto();
    this.massModification.listOfYoung = this.listOfId;
    this.massModification.filter = this.filter;
    this.initRI();
    this.onSearchRi();
  }

  updateYoung() {
    this.isBtnLoading = true;
    this.youngService
      .massModificationRi(this.massModification)
      .pipe(this.untilDestroyed())
      .subscribe(
        (item) => {
          this.isBtnLoading = false;
          this.dialogRef.close({dismiss: false, data: item});
        },
        (err) => {
          this.dialogRef.close({dismiss: true, data: null});
        }
      );
  }

  onDismiss(): void {
    this.dialogRef.close({dismiss: true, data: null});
  }

  private onSearchRi() {
    this.riMultiFilterCtrl.valueChanges.pipe(this.untilDestroyed()).subscribe(() => {
      this._filterRi();
    });
  }

  private _filterRi() {
    if (!this.listRi) {
      return;
    }
    // get the search keyword
    let search = this.riMultiFilterCtrl.value;
    if (!search) {
      this.filteredRiObservable.next(this.listRi.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredRiObservable.next(
      this.listRi.filter(
        (user) =>
          (user.firstName.toLowerCase() + ' ' + user.lastName.toLowerCase()).indexOf(search) > -1
      )
    );
  }

  private initRI() {
    this.userWS
      .getAllRi()
      .pipe(this.untilDestroyed())
      .subscribe(
        (res: User[]) => {
          this.listRi = res;
        },
        (err) => {},
        () => {
          this.filteredRiObservable.next(this.listRi.slice());
        }
      );
  }
}
