import {Component, OnInit} from '@angular/core';
import {OccurrencePlayoutCurrentlyViewedService} from '../../../../../services/occurrence-playout-currently-viewed.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {Occurrence} from '../../../../../model/event/occurrence.model';
import {DialogEditOccurrenceComponent} from '../../../dialog/dialog-edit-occurrence/dialog-edit-occurrence.component';
import {MatDialog} from '@angular/material/dialog';
import {GridOccurenceWithDeleteComponent} from '../../grid-occurence-with-delete/grid-occurence-with-delete.component';
import {CurrentUserService} from '../../../../../services/current-user.service';
import {onlyAdmin} from '../../../../../model/enums/source/module-source.enum';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-edit-btn-time-occurence',
  templateUrl: './edit-btn-time-occurence.component.html',
  styleUrls: ['./edit-btn-time-occurence.component.scss'],
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule]
})
export class EditBtnTimeOccurenceComponent extends Destroyed implements OnInit {
  public data;
  isAdmin = false;
  private occurenceList = new Array<Occurrence>();

  constructor(
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService,
    private readonly dialog: MatDialog,
    private readonly grid: GridOccurenceWithDeleteComponent,
    private readonly currentUserService: CurrentUserService
  ) {
    super();
  }

  ngOnInit(): void {
    this.occurrencePlayoutCurrentlyViewedService.occurenceMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.occurenceList = data;
      });
  }

  agInit(params): void {
    this.isAdmin = this.currentUserService.checkModule(...onlyAdmin);

    this.data = params.data;
  }

  onBtnClick() {
    if (this.data) {
      const occurrence = this.data;
      const idOccurrence = this.data.id;

      const dialogRef = this.dialog.open(DialogEditOccurrenceComponent, {
        panelClass: 'dialog'
      });

      dialogRef.componentInstance.occurrence = occurrence;
      dialogRef.afterClosed().subscribe((value) => {
        if (value !== undefined) {
          for (const occu of this.occurenceList) {
            if (occu.id === idOccurrence) {
              occu.time = value;
            }
          }
          this.grid.setOccurenceAffichage();
        }
      });
    }
  }
}
