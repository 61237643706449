import {Component, OnInit} from '@angular/core';
import {FormatService} from '../../../../services/format.service';
import {Destroyed} from '../../directives/destroyed.directive';
import {WorkExperience} from '../../../../model/user/work-experience.model';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {WorkExperienceMetaDto} from '../../../../model/dto/metaDto/work-experience-meta.dto';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {EntrepriseWebservice} from '../../../../services/webservices/entreprise.webservice';
import {SelectOption} from '../../../../model/form/select-option.model';
import {WorkDurationEnum} from '../../../../model/enums/work-duration.enum';
import {EntrepriseDTO} from '../../../../model/dto/entreprise.dto';
import {AlertService} from '../../../../services/alert.service';
import {ContributionContract} from '../../../../model/user/contribution-contract.model';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {
  MatDialog,
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {DialogAddStructureComponent} from '../dialog-add-structure/dialog-add-structure.component';
import {CompareService} from '../../../../services/compare.service';
import {PDomaineMetier} from '../../../../model/param/p-domaine-metier.model';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {CustomDateAdapter} from '../../../../utils/custom-date-adapter';
import {getEnumKeysAsNumber, isNotNullOrUndefined} from '../../../../utils/utils.static';
import {JobbeurNeed} from '../../../../model/user/jobbeur-need.model';
import {AsyncPipe, formatDate, NgClass, NgFor, NgIf} from '@angular/common';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatButtonModule} from '@angular/material/button';
import {SwitchComponent} from '../../form-parts/form-items/switch/switch.component';
import {MatIconModule} from '@angular/material/icon';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatSelectModule} from '@angular/material/select';
import {AlertComponent} from '../../alert/alert/alert.component';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-dialog-add-work-experience',
  templateUrl: './dialog-add-work-experience.component.html',
  styleUrls: ['./dialog-add-work-experience.component.scss'],
  providers: [{provide: DateAdapter, useClass: CustomDateAdapter}],
  standalone: true,
  imports: [
    MatDialogTitle,
    AlertComponent,
    FormsModule,
    ReactiveFormsModule,
    MatDialogContent,
    NgIf,
    MatSelectModule,
    NgClass,
    NgFor,
    MatOptionModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatIconModule,
    SwitchComponent,
    MatDialogActions,
    MatButtonModule,
    MatProgressSpinnerModule,
    AsyncPipe
  ]
})
export class DialogAddWorkExperienceComponent extends Destroyed implements OnInit {
  workExperienceFormGroup: UntypedFormGroup;

  workExperience: WorkExperience;
  idUser: number;

  experienceCategories = [];
  experienceMotifEnd = [];
  contractTypes = [];
  formationTypes = [];
  contributionContractsForSelect = [];
  workDurationForSelect = [];

  //Entreprise mère
  public entrepriseMultiFilterCtrl = new UntypedFormControl();
  public filteredEntrepriseObservable: Subject<EntrepriseDTO[]> = new Subject<EntrepriseDTO[]>();

  //Domaine field
  public domainMultiFilterCtrl = new UntypedFormControl();
  public filteredDomaineObservable: ReplaySubject<PDomaineMetier[]> = new ReplaySubject<
    PDomaineMetier[]
  >(1);
  public selectedDomaine: PDomaineMetier[];
  domaineMetierListForSelect = [];

  showFieldsWork = false;
  showFieldsFormation = false;

  isStructureSelected = false;
  isReadOnly: boolean;
  showMandatoryDate: boolean;
  isSaving: boolean = false;
  jobbeurNeeds: JobbeurNeed[];
  protected readonly isNotNullOrUndefined = isNotNullOrUndefined;

  constructor(
    private readonly dialogRef: MatDialogRef<DialogAddWorkExperienceComponent>,
    private readonly fb: UntypedFormBuilder,
    public readonly formatService: FormatService,
    private readonly paramWebservice: ParamWebservice,
    private readonly entrepriseWebservice: EntrepriseWebservice,
    private readonly alertService: AlertService,
    private readonly youngWebservice: YoungWebservice,
    private readonly compareService: CompareService,
    private readonly dialog: MatDialog
  ) {
    super();
    this.workExperienceFormGroup = this.fb.group({
      category: [null, Validators.required],
      contractType: [null],
      formationType: [null],
      structure: [null, Validators.required],
      tutorFirstName: null,
      tutorLastName: null,
      tutorPhone: [
        null,
        [
          Validators.pattern('(^0[0-9]{9}$)|(^[1-9][0-9]{8}$)'),
          Validators.maxLength(10),
          Validators.minLength(9)
        ]
      ],
      tutorEmail: [null, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
      dateBegin: [null, Validators.required],
      dateEnd: null,
      duration: null,
      intitule: null,
      domaineMetier: [null],
      workDuration: null,
      workPostalCode: [null, Validators.pattern('^[0-9]+')],
      contributionContract: [null],
      workExperienceMotifEnd: null,
      workContractTransmitted: null,
      validatedDiploma: null,
      comment: null,
      jobbeurNeed: null
    });
    this.initOnValueChangesFormGroup();
    this.entrepriseMultiFilterCtrl.setValue('');
    this.domainMultiFilterCtrl.setValue('');
    this.initEntreprise();
    this.initAllEnum();
    this.initWorkTime();
    this.onSearchEntreprise();
    this.onSearchDomain();

    this.fWorkExperience.contractType.valueChanges
      .pipe(this.untilDestroyed())
      .subscribe((value) => {
        if (this.isCDI(value)) {
          this.fWorkExperience.dateEnd.clearValidators();
          this.showMandatoryDate = false;
        } else {
          this.fWorkExperience.dateEnd.setValidators(Validators.required);
          this.showMandatoryDate = true;
        }
      });
  }

  get fWorkExperience() {
    return this.workExperienceFormGroup.controls;
  }

  ngOnInit() {
    if (this.workExperience && this.workExperience.id > 0) {
      this.youngWebservice
        .getWorkExperience(this.workExperience.id)
        .pipe(this.untilDestroyed())
        .subscribe((value: WorkExperience) => {
          this.workExperience = value;
          this.setForm();
          this.toggleDisable(true);
          this.idUser = this.workExperience.idYoung;
          this.initJobbeurNeed();
        });
    } else {
      this.workExperience = new WorkExperience();
      this.isReadOnly = false;
      this.initJobbeurNeed();
    }
  }

  initOnValueChangesFormGroup() {
    this.fWorkExperience.category.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      if (value) {
        if (value.id === 1) {
          this.showFieldsWork = true;
          this.showFieldsFormation = false;
          this.setValidators(value.id);
        } else if (value.id === 2) {
          this.showFieldsWork = false;
          this.showFieldsFormation = true;
          this.setValidators(value.id);
        } else {
          this.showFieldsWork = false;
          this.showFieldsFormation = false;
          this.setValidators(value.id);
        }

        this.experienceMotifEnd = value.listOfPExperienceEndMotif;
      }
    });

    this.fWorkExperience.dateBegin.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      this.checkDateBegin();
    });

    this.fWorkExperience.dateEnd.valueChanges.pipe(this.untilDestroyed()).subscribe((value) => {
      this.checkDateEnd();
    });
  }

  setValidators(value) {
    if (value === 1) {
      // Experience pro
      this.fWorkExperience.contractType.setValidators(Validators.required);
      this.fWorkExperience.contributionContract.setValidators(Validators.required);

      this.fWorkExperience.formationType.reset();
      this.fWorkExperience.formationType.clearValidators();
    } else if (value === 2) {
      // Formation
      this.fWorkExperience.contractType.reset();
      this.fWorkExperience.contributionContract.reset();
      this.fWorkExperience.contractType.clearValidators();
      this.fWorkExperience.contributionContract.clearValidators();

      this.fWorkExperience.formationType.setValidators(Validators.required);
    } else {
      // Dispositif d'accompagnement
      this.fWorkExperience.contractType.reset();
      this.fWorkExperience.contributionContract.reset();
      this.fWorkExperience.contractType.clearValidators();
      this.fWorkExperience.contributionContract.clearValidators();

      this.fWorkExperience.formationType.reset();
      this.fWorkExperience.formationType.clearValidators();
    }
  }

  initAllEnum() {
    this.paramWebservice
      .getAllEnumForWorkExperience()
      .pipe(this.untilDestroyed())
      .subscribe((content: WorkExperienceMetaDto) => {
        this.experienceCategories = content.pExperienceCategories;
        this.contractTypes = content.pContractTypes;
        this.formationTypes = content.pFormationTypes;
        this.selectedDomaine = content.pDomainesMetiers;
        this.filteredDomaineObservable.next(this.selectedDomaine.slice());
        content.pContributionContracts.forEach((value) => {
          const so = new SelectOption();
          so.id = value.id;
          so.name = value.label;
          this.contributionContractsForSelect.push(so);
        });
      });
  }

  initWorkTime() {
    this.workDurationForSelect = [];
    getEnumKeysAsNumber(WorkDurationEnum).forEach((i) =>
      this.workDurationForSelect.push({id: i, name: this.formatService.formatWorkDuration(i)})
    );
  }

  calculDate() {
    if (this.fWorkExperience.dateBegin.value && this.fWorkExperience.dateEnd.value) {
      // conversion en Date
      const startDate = new Date(this.fWorkExperience.dateBegin.value);
      const endDate = new Date(this.fWorkExperience.dateEnd.value);

      if (endDate >= startDate) {
        const [yearDiff, monthDiff, dayDiff] = this.getDifferenceBetweenDates(startDate, endDate);
        let betweenDate = '';

        const yearsDisplay = this.getFormatYear(yearDiff);
        betweenDate += yearsDisplay;
        const monthsDisplay = this.getFormatMonth(monthDiff);
        betweenDate += monthsDisplay;
        const daysDisplay = this.getFormatDay(dayDiff);
        betweenDate += daysDisplay;
        return betweenDate;
      } else {
        return 'La date de fin est inférieure à la date de début';
      }
    } else {
      return this.handleContratDureeDet();
    }
  }

  getFormatDay(dayDiff: number) {
    return dayDiff > 0 ? dayDiff + (dayDiff === 1 ? ' jour' : ' jours') : '';
  }

  getFormatMonth(monthDiff: number) {
    return monthDiff > 0 ? monthDiff + ' mois ' : '';
  }

  getFormatYear(yearDiff: number) {
    return yearDiff > 0 ? yearDiff + (yearDiff === 1 ? ' année ' : ' années ') : '';
  }

  getDifferenceBetweenDates(startDate, endDate) {
    let yearDiff = endDate.getFullYear() - startDate.getFullYear();
    let monthDiff = endDate.getMonth() - startDate.getMonth();

    // commence à 0
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();

    // pour compter le dernier jour
    dayDiff++;
    if (dayDiff === 31) {
      dayDiff = 0;
      monthDiff++;
      if (monthDiff === 12) {
        yearDiff++;
        monthDiff = 0;
      }
    }
    return [yearDiff, monthDiff, dayDiff];
  }

  handleContratDureeDet() {
    if (this.isCDI(this.fWorkExperience.contractType.value)) {
      return 'Contrat à Durée Indeterminée';
    } else {
      return 'Une des deux dates est manquante';
    }
  }

  checkBeforeSubmit() {
    const errors = [];

    const propertysMap = [
      {property: 'category', message: 'Categorie'},
      {property: 'structure', message: 'Structure'},
      {property: 'tutorPhone', message: 'Téléphone du tuteur'},
      {property: 'tutorEmail', message: 'Email du tuteur'},
      {property: 'dateBegin', message: 'Date de début'},
      {property: 'dateEnd', message: 'Date de fin'}
    ];

    for (const property of propertysMap) {
      if (this.fWorkExperience[property.property].invalid) {
        errors.push(property.message);
      }
    }

    if (this.isCDI(this.fWorkExperience.contractType.value)) {
      this.checkIfDateBeginIsBeforeDateEnd(errors);
    } else {
      if (this.fWorkExperience.dateBegin.value > this.fWorkExperience.dateEnd.value) {
        errors.push('La date de début doit être inférieure à la date de fin');
      }
    }

    if (this.showFieldsWork) {
      const showFieldsWorkMap = [
        {property: 'contractType', message: 'Type de contrat'},
        {property: 'contributionContract', message: 'Apport du contrat'},
        {property: 'workContractTransmitted', message: 'Contrat de travail transmis'},
        {property: 'workPostalCode', message: "Code postal de l'emploi"}
      ];
      for (const item of showFieldsWorkMap) {
        if (this.fWorkExperience[item.property].invalid) {
          errors.push(item.message);
        }
      }
    }

    if (this.showFieldsFormation) {
      this.handleFormationFields(errors);
    }

    if (errors.length > 0) {
      const message = 'Les champs suivants sont manquants ou erronés : ' + errors.join(', ');

      this.alertService.clear('workExperienceAlert');
      this.alertService.error(message, 'workExperienceAlert');
    }
  }

  handleFormationFields(errors: string[]) {
    if (this.fWorkExperience.formationType.invalid) {
      errors.push('Type de formation');
    }
    if (this.fWorkExperience.validatedDiploma.invalid) {
      errors.push('Diplôme / Certificat validé');
    }
  }

  checkIfDateBeginIsBeforeDateEnd(errors: string[]) {
    if (
      this.fWorkExperience.dateBegin &&
      this.fWorkExperience.dateBegin.value &&
      this.fWorkExperience.dateEnd &&
      this.fWorkExperience.dateEnd.value
    ) {
      if (this.fWorkExperience.dateBegin.value > this.fWorkExperience.dateEnd.value) {
        errors.push('La date de début doit être inférieure à la date de fin');
      }
    }
    return errors;
  }

  checkDate() {
    if (this.isCDI(this.fWorkExperience.contractType.value)) {
      if (
        this.fWorkExperience.dateBegin &&
        this.fWorkExperience.dateEnd &&
        this.fWorkExperience.dateBegin.value &&
        this.fWorkExperience.dateEnd.value
      ) {
        return new Date(this.fWorkExperience.dateBegin.value) <= new Date(this.fWorkExperience.dateEnd.value);
      } else {
        return !!this.fWorkExperience.dateBegin.value;
      }
    } else {
      return new Date(this.fWorkExperience.dateBegin.value) <= new Date(this.fWorkExperience.dateEnd.value);
    }
  }

  saveWorkExperience() {
    this.isSaving = true;
    this.checkBeforeSubmit();
    if (this.workExperienceFormGroup.valid && this.checkDate()) {
      this.getInfosWorkExperienceFields();
      this.youngWebservice
        .addOrUpdateWorkExperience(this.workExperience)
        .pipe(this.untilDestroyed())
        .subscribe({
          next: () => {
            if (this.workExperience.id > 0) {
              this.toggleDisable(true);
            } else {
              this.dialogRef.close();
            }
            this.isSaving = false;
          },
          error: () => {}
        });
    } else {
      this.isSaving = false;
    }
  }

  getInfosWorkExperienceFields() {
    this.workExperience.idYoung = this.idUser;
    this.workExperience.idPExperienceCategory = this.fWorkExperience.category.value.id;
    this.workExperience.idEntreprise = this.fWorkExperience.structure.value.id;
    this.workExperience.entreprise = this.fWorkExperience.structure.value;
    this.workExperience.dateBegin = this.fWorkExperience.dateBegin.value;
    this.workExperience.dateEnd = this.fWorkExperience.dateEnd.value;
    if (this.fWorkExperience.dateBegin.value && this.fWorkExperience.dateEnd.value) {
      this.workExperience.duration =
        Math.ceil(
          (new Date(this.fWorkExperience.dateEnd.value).getTime() -
            new Date(this.fWorkExperience.dateBegin.value).getTime()) /
            (1000 * 60 * 60 * 24)
        ) + 1;
    }
    this.workExperience.intitule = this.fWorkExperience.intitule.value;
    this.workExperience.idPDomainMetier = this.fWorkExperience.domaineMetier.value;
    if (this.fWorkExperience.tutorFirstName.value && this.fWorkExperience.tutorLastName.value) {
      this.workExperience.firstAndLastNameTuteur =
        this.fWorkExperience.tutorFirstName.value + ' ' + this.fWorkExperience.tutorLastName.value;
    }
    this.workExperience.phoneTutor = this.fWorkExperience.tutorPhone.value;
    this.workExperience.mailTutor = this.fWorkExperience.tutorEmail.value;

    if (this.fWorkExperience.workExperienceMotifEnd.value) {
      this.workExperience.idPExperienceEndMotif =
        this.fWorkExperience.workExperienceMotifEnd.value.id;
    }

    if (this.showFieldsWork) {
      this.workExperience.idPContractType = this.fWorkExperience.contractType.value;
      this.workExperience.workDuration = this.fWorkExperience.workDuration.value;
      this.workExperience.workPostalCode = this.fWorkExperience.workPostalCode.value;
      this.workExperience.listOfContributionContract = [];
      if (this.fWorkExperience.contributionContract.value) {
        this.fWorkExperience.contributionContract.value.forEach((value) => {
          const contributionContract = new ContributionContract();
          contributionContract.idPContributionContract = value;
          this.workExperience.listOfContributionContract.push(contributionContract);
        });
      }

      this.workExperience.workContractTransmitted =
        !!this.fWorkExperience.workContractTransmitted.value;

      if (this.fWorkExperience.jobbeurNeed.value) {
        this.workExperience.idJobbeurNeed = this.fWorkExperience.jobbeurNeed.value;
      }
    }

    if (this.showFieldsFormation) {
      this.workExperience.idPFormationType = this.fWorkExperience.formationType.value;
      this.workExperience.validatedDiploma = !!this.fWorkExperience.validatedDiploma.value;
    }

    this.workExperience.comment = this.fWorkExperience.comment.value;
  }

  setForm() {
    this.idUser = this.workExperience.idYoung;
    if (this.workExperience.pExperienceCategory) {
      this.handlePExperienceCategory();
    }

    this.fWorkExperience.comment.setValue(this.workExperience.comment);

    if (this.workExperience.entreprise) {
      const entrepriseDto = EntrepriseDTO.toDto(this.workExperience.entreprise);
      this.fWorkExperience.structure.setValue(entrepriseDto);
      this._filterEntreprise(entrepriseDto.nom);
    }

    if (this.workExperience.idJobbeurNeed) {
      this.fWorkExperience.jobbeurNeed.setValue(this.workExperience.idJobbeurNeed);
    }

    if (this.workExperience.firstAndLastNameTuteur) {
      const tutorNames = this.workExperience.firstAndLastNameTuteur.split(' ');

      if (isNotNullOrUndefined(tutorNames[0])) {
        this.fWorkExperience.tutorFirstName.setValue(tutorNames[0]);
      }

      if (isNotNullOrUndefined(tutorNames[1])) {
        this.fWorkExperience.tutorLastName.setValue(tutorNames[1]);
      }
    }

    const propertysMap = [
      {property: 'phoneTutor', setter: 'tutorPhone'},
      {property: 'mailTutor', setter: 'tutorEmail'},
      {property: 'dateBegin', setter: 'dateBegin'},
      {property: 'dateEnd', setter: 'dateEnd'},
      {property: 'intitule', setter: 'intitule'},
      {property: 'idPDomainMetier', setter: 'domaineMetier'},
      {property: 'workPostalCode', setter: 'workPostalCode'},
      {property: 'pExperienceEndMotif', setter: 'workExperienceMotifEnd'}
    ];

    for (const property of propertysMap) {
      if (this.workExperience[property.property]) {
        this.fWorkExperience[property.setter].setValue(this.workExperience[property.property]);
      }
    }

    this.checkValidWorkExperience();
  }

  handlePExperienceCategory() {
    this.fWorkExperience.category.setValue(this.workExperience.pExperienceCategory);
    if (this.workExperience.idPExperienceCategory === 1) {
      this.fWorkExperience.contractType.setValue(this.workExperience.idPContractType);
    } else if (this.workExperience.idPExperienceCategory === 2) {
      this.fWorkExperience.formationType.setValue(this.workExperience.idPFormationType);
    }
    this.experienceMotifEnd = this.workExperience.pExperienceCategory.listOfPExperienceEndMotif;
  }

  checkValidWorkExperience() {
    if (isNotNullOrUndefined(this.workExperience.workDuration)) {
      this.fWorkExperience.workDuration.setValue(this.workExperience.workDuration);
    }
    if (this.workExperience.listOfContributionContract) {
      this.fWorkExperience.contributionContract.setValue(
        this.workExperience.listOfContributionContract.map((item) => item.idPContributionContract)
      );
    }
    if (isNotNullOrUndefined(this.workExperience.workContractTransmitted)) {
      this.fWorkExperience.workContractTransmitted.setValue(
        this.workExperience.workContractTransmitted
      );
    }
    if (isNotNullOrUndefined(this.workExperience.validatedDiploma)) {
      this.fWorkExperience.validatedDiploma.setValue(this.workExperience.validatedDiploma);
    }
  }

  toggleDisable(value: boolean) {
    this.isReadOnly = value;
    if (this.isReadOnly) {
      if (this.workExperience.id > 0) {
        this.fWorkExperience.category.disable();
        this.fWorkExperience.contractType.disable();
        this.fWorkExperience.formationType.disable();
        this.fWorkExperience.structure.disable();
        this.fWorkExperience.tutorFirstName.disable();
        this.fWorkExperience.tutorLastName.disable();
        this.fWorkExperience.tutorPhone.disable();
        this.fWorkExperience.tutorEmail.disable();
        this.fWorkExperience.dateBegin.disable();
        this.fWorkExperience.dateEnd.disable();
        this.fWorkExperience.duration.disable();
        this.fWorkExperience.intitule.disable();
        this.fWorkExperience.domaineMetier.disable();
        this.fWorkExperience.workDuration.disable();
        this.fWorkExperience.workPostalCode.disable();
        this.fWorkExperience.contributionContract.disable();
        this.fWorkExperience.workExperienceMotifEnd.disable();
        this.fWorkExperience.workContractTransmitted.disable();
        this.fWorkExperience.validatedDiploma.disable();
        this.fWorkExperience.comment.disable();
        this.fWorkExperience.jobbeurNeed.disable();
      } else {
        this.dismiss();
      }
    } else {
      this.fWorkExperience.category.enable();
      this.fWorkExperience.contractType.enable();
      this.fWorkExperience.formationType.enable();
      this.fWorkExperience.structure.enable();
      this.fWorkExperience.tutorFirstName.enable();
      this.fWorkExperience.tutorLastName.enable();
      this.fWorkExperience.tutorPhone.enable();
      this.fWorkExperience.tutorEmail.enable();
      this.fWorkExperience.dateBegin.enable();
      this.fWorkExperience.dateEnd.enable();
      this.fWorkExperience.duration.enable();
      this.fWorkExperience.intitule.enable();
      this.fWorkExperience.domaineMetier.enable();
      this.fWorkExperience.workDuration.enable();
      this.fWorkExperience.workPostalCode.enable();
      this.fWorkExperience.contributionContract.enable();
      this.fWorkExperience.workExperienceMotifEnd.enable();
      this.fWorkExperience.workContractTransmitted.enable();
      this.fWorkExperience.validatedDiploma.enable();
      this.fWorkExperience.comment.enable();
      this.fWorkExperience.jobbeurNeed.enable();
    }
  }

  compareWith(e1, e2) {
    return e1 === e2;
  }

  compareWithId(e1, e2) {
    if (e1 && e2) {
      return e1.id === e2.id;
    }
    return false;
  }

  checkDateEnd() {
    if (this.fWorkExperience.dateBegin.value && this.fWorkExperience.dateEnd.value) {
      if (this.fWorkExperience.dateBegin.value > this.fWorkExperience.dateEnd.value) {
        return 'La date de fin est inférieure à la date de début';
      }
    }
    return null;
  }

  checkDateBegin() {
    if (this.fWorkExperience.dateBegin.value && this.fWorkExperience.dateEnd.value) {
      if (this.fWorkExperience.dateBegin.value > this.fWorkExperience.dateEnd.value) {
        return 'La date de début est supérieure à la date de fin';
      }
    }
    return null;
  }

  dismiss() {
    this.dialogRef.close();
  }

  addStructure() {
    const dialogRef = this.dialog.open(DialogAddStructureComponent, {
      panelClass: 'dialog'
    });

    dialogRef.componentInstance.entrepriseName = this.entrepriseMultiFilterCtrl.value;

    dialogRef
      .afterClosed()
      .pipe(this.untilDestroyed())
      .subscribe((entreprise: EntrepriseDTO) => {
        if (entreprise) {
          this.initEntreprise();
          this.fWorkExperience.structure.setValue(entreprise);
          this._filterEntreprise(entreprise.nom);
        }
      });
  }

  initJobbeurNeed() {
    this.youngWebservice
      .getAllJobbeurNeed(this.idUser)
      .pipe(this.untilDestroyed())
      .subscribe((content: JobbeurNeed[]) => {
        this.jobbeurNeeds = content;
      });
  }

  formatJobbeurNeedName(jobbeurNeed: JobbeurNeed) {
    let jobbeurNeedName = `${jobbeurNeed.pNeed.label} - ${formatDate(
      jobbeurNeed.expectedDeadline,
      'dd/MM/yyyy',
      'fr-FR'
    )}`;

    if (jobbeurNeed.pNeedStatus) {
      jobbeurNeedName = `${jobbeurNeed.pNeed.label} - ${
        jobbeurNeed.pNeedStatus.label
      } - ${formatDate(jobbeurNeed.expectedDeadline, 'dd/MM/yyyy', 'fr-FR')}`;
    }

    return jobbeurNeedName;
  }

  private initEntreprise() {
    this.filteredEntrepriseObservable.next([]);
  }

  private onSearchEntreprise() {
    this.entrepriseMultiFilterCtrl.valueChanges
      .pipe(debounceTime(300), this.untilDestroyed())
      .subscribe((search) => {
        this._filterEntreprise(search);
      });
  }

  private onSearchDomain() {
    this.domainMultiFilterCtrl.valueChanges
      .pipe(debounceTime(300), this.untilDestroyed())
      .subscribe(() => {
        this._filterDomaines();
      });
  }

  private _filterEntreprise(search: string) {
    if (!search) {
      return;
    } else {
      search = search.toLowerCase();
      this.entrepriseWebservice
        .getAllEntreprise(search)
        .pipe(this.untilDestroyed())
        .subscribe((lEntreprises: EntrepriseDTO[]) => {
          this.filteredEntrepriseObservable.next(
            lEntreprises
          );
        });
    }
  }

  private _filterDomaines() {
    if (!this.selectedDomaine) {
      return;
    }
    // get the search keyword
    let search = this.domainMultiFilterCtrl.value;
    if (!search) {
      this.filteredDomaineObservable.next(this.selectedDomaine.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredDomaineObservable.next(
      this.selectedDomaine.filter((domaine) => domaine.label.toLowerCase().indexOf(search) > -1)
    );
  }

  private isCDI(value) {
    // Les ID des entrées en CDI
    const idCDI = Array.of(5, 6, 19);
    return idCDI.includes(value);
  }
}
