import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {YoungDTO} from 'src/app/model/dto/young.dto';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {ActivityInfosService} from 'src/app/services/activity-infos.service';
import {SexCellRendererComponent} from '../custom-cell/sex-cell-renderer/sex-cell-renderer.component';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {StatusCellRendererComponent} from '../custom-cell/status-cell-renderer/status-cell-renderer.component';
import {Destroyed} from '../../directives/destroyed.directive';
import {AsyncPipe} from '@angular/common';
import {AgGridModule} from '@ag-grid-community/angular';
import {MatToolbarModule} from '@angular/material/toolbar';
import {PaginationComponent} from 'src/app/components/shared/pagination/pagination.component';

@Component({
  selector: 'app-grid-young',
  templateUrl: './grid-young.component.html',
  styleUrls: ['./grid-young.component.scss'],
  standalone: true,
  imports: [AgGridModule, AsyncPipe, MatToolbarModule, PaginationComponent]
})
export class GridYoungComponent extends Destroyed implements OnInit {
  @Input() useDate: boolean;
  @Input() seeEvent: boolean;
  @Input() isLDLV: boolean;

  @Output() pageChange: EventEmitter<void> = new EventEmitter<void>();

  youngList: YoungDTO[];

  rowClassRules;
  gridYoungApi: unknown;
  columnYoungDefs;
  isYoungLoading = true;
  gridYoungOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  rowSelection;
  langue = agGridLanguageFr;
  itemPerPage: number = 10;

  currentChosenYoungs: YoungDTO[] = [];

  constructor(
    private readonly formatService: FormatService,
    private readonly activityInfoService: ActivityInfosService
  ) {
    super();
    this.context = {componentParent: this};
    this.rowSelection = 'multiple';
  }

  ngOnInit() {
    this.columnYoungDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        cellRenderer: NameCellRendererComponent,
        filter: 'agTextColumnFilter',
        filterParams: {filterOptions: ['contains']},
        floatingFilterComponentParams: {suppressFilterButton: true},
        sortable: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        minWidth: 250,
        maxWidth: 400
      }
    ];

    this.columnYoungDefs.push(
      this.useDate
        ? {
            headerName: 'Date de naissance',
            field: 'birthday',
            sortable: true,
            hide: !this.useDate,
            filter: false
          }
        : {
            headerName: 'Âge',
            field: 'age',
            sortable: true,
            minWidth: 75,
            maxWidth: 100,
            hide: this.useDate,
            filter: false
          }
    );

    if (this.seeEvent) {
      this.columnYoungDefs.push({
        headerName: 'Autres sports',
        field: 'listOfEventType',
        sortable: true,
        minWidth: 200,
        filter: false
      });
    }

    this.columnYoungDefs.push({
      headerName: 'Sexe',
      cellRenderer: SexCellRendererComponent,
      field: 'sex',
      sortable: true,
      minWidth: 200,
      filter: false
    });

    if (!this.isLDLV) {
      this.columnYoungDefs.push({
        headerName: 'Statut',
        field: 'isConnected',
        cellRenderer: StatusCellRendererComponent,
        sortable: true,
        minWidth: 200,
        maxWidth: 200,
        filter: false
      });
    }

    this.gridYoungOption = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        domLayout: 'autoHeight',
        sortable: false,
        filter: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60,
      width: '100%',
      height: '100%'
    } as GridOptions;

    this.activityInfoService.currentUnregisteredYoungMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => {
        this.youngList = data;
        this.setAffichage();
        if (this.youngList.length > 0) {
          this.isYoungLoading = false;
        }
      });
  }

  onSelectionChanged() {
    const chosenYoungList: YoungDTO[] = this.gridYoungOption.api.getSelectedRows();
    // Mise à jour de currentChosenYoungs en fonction des deux autres listes
    for (const young of chosenYoungList) {
      // Vérifie si le jeune est déjà dans currentChosenYoungs
      if (!this.currentChosenYoungs.some((y) => y.id === young.id)) {
        this.currentChosenYoungs.push(young);
      }
    }
    // Tous les jeunes déselectionnés de la page actuelle
    const youngsUnselected = this.youngList.filter(
      (value) => !chosenYoungList.some((value1) => value1.id === value.id)
    );

    this.currentChosenYoungs = this.currentChosenYoungs.filter(
      (value) => !youngsUnselected.some((unselected) => unselected.id === value.id)
    );

    if (this.currentChosenYoungs) {
      this.activityInfoService.changeChosenYoungList(this.currentChosenYoungs);
    }
  }

  onGridSizeChanged(params) {
    const gridWidth = document.getElementById('grid-wrapper').offsetWidth;
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.columnApi.getColumns();
    if (allColumns) {
      allColumns.forEach((column) => {
        totalColsWidth += column.getMinWidth();
        if (totalColsWidth > gridWidth) {
          columnsToHide.push(column.colId);
        } else {
          columnsToShow.push(column.colId);
        }
      });
    }

    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
    params.api.sizeColumnsToFit();
    params.api.resetRowHeights();
  }

  onGridYoungReady(params) {
    this.gridYoungOption.api.sizeColumnsToFit();
    this.gridYoungOption.animateRows = true;
    this.gridYoungApi = params.api;
    this.gridYoungOption.suppressCellFocus = true;
    this.gridYoungOption.suppressHorizontalScroll = true;
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridYoungOption.api) {
      this.youngList.forEach((young) => {
        returnRows.push(this.getRowFromYoung(young));
      }, this);
      this.gridYoungOption.api.setRowData(returnRows);
      this.currentChosenYoungs = this.activityInfoService.getSelection();
      this.gridYoungOption.api.forEachNode((rowNode) => {
        if (this.activityInfoService.getSelection().some((value) => value.id === rowNode.data.id)) {
          rowNode.setSelected(true);
        }
      });
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichagé sur une ligne
   * @param YoungDTO, jeune à afficher
   */
  getRowFromYoung(young: YoungDTO) {
    const numberEvent = young.listOfEventType.length;
    return {
      id: young.id,
      name: this.formatService.formatName(young),
      firstName: young.firstName,
      lastName: young.lastName,
      sex: young.sex,
      age: young.age ? young.age : this.formatService.formatAge(new Date(young.birthday)),
      birthday: this.formatService.formatDate(new Date(young.birthday)),
      listOfEventType:
        young.listOfEventType.length > 1
          ? `${young.listOfEventType[0]} (+ ${numberEvent - 1} autre)`
          : young.listOfEventType.join(),
      isConnected: young.isConnected,
      isValidated: young.isValidated,
      idPlace: young.idPlace
    };
  }

  loadUnregisteredYoung() {
    this.pageChange.emit();
  }
}
