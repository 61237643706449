import {Injectable} from '@angular/core';
import {Occurrence} from '../model/event/occurrence.model';
import {PlayOut} from '../model/event/play-out.model';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OccurrencePlayoutCurrentlyViewedService {
  private readonly dataOccurence = new BehaviorSubject(new Array<Occurrence>());
  public occurenceMessager = this.dataOccurence.asObservable();
  private readonly dataPlayOut = new BehaviorSubject(new Array<PlayOut>());
  public playOutMessager = this.dataPlayOut.asObservable();

  changeOccurenceList(occurenceList: Occurrence[]) {
    this.dataOccurence.next(occurenceList);
  }

  changePlayOutList(playOut: PlayOut[]) {
    this.dataPlayOut.next(playOut);
  }
}
