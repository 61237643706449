import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ProjectYoungAndPresencesDTO} from '../model/dto/ensemble.dto';
import {EventTableDTO} from '../model/dto/event-table.dto';
import {ProjectTableDTO} from '../model/dto/project-table.dto';

@Injectable({
  providedIn: 'root'
})
export class GridYoungEnsembleDataService {
  private readonly dataEventIdList = new BehaviorSubject([]);
  public dataEventListMessager = this.dataEventIdList.asObservable();

  private readonly subEnsembleList = new BehaviorSubject(new Array<ProjectTableDTO>());
  public subEnsembleListMessager = this.subEnsembleList.asObservable();

  private readonly eventsList = new BehaviorSubject(new Array<EventTableDTO>());
  public eventsListMessager = this.eventsList.asObservable();

  private readonly youngsList = new BehaviorSubject(new ProjectYoungAndPresencesDTO());
  public youngsListMessager = this.youngsList.asObservable();

  private readonly ensembleLoaded = new BehaviorSubject(false);
  public ensembleLoadedMessager = this.ensembleLoaded.asObservable();

  private readonly subEnsembleLoaded = new BehaviorSubject(false);
  public subEnsembleLoadedMessager = this.subEnsembleLoaded.asObservable();

  private readonly eventsLoaded = new BehaviorSubject(false);
  public eventsLoadedMessager = this.eventsLoaded.asObservable();
  public youngsLoadedMessager = this.eventsLoaded.asObservable();
  private readonly youngsLoaded = new BehaviorSubject(false);

  changeDataEventIdList(listOfEventId: number[]) {
    this.dataEventIdList.next(listOfEventId);
  }

  changeSubEnsembleList(listOfSubEnsemble: ProjectTableDTO[]) {
    this.subEnsembleList.next(listOfSubEnsemble);
  }

  changeEventsList(listOfEvents: EventTableDTO[]) {
    this.eventsList.next(listOfEvents);
  }

  changeYoungsList(listOfYoungs: ProjectYoungAndPresencesDTO) {
    this.youngsList.next(listOfYoungs);
  }

  changeEnsembleLoaded(isLoading: boolean) {
    this.ensembleLoaded.next(isLoading);
  }

  changeSubEnsembleLoaded(isLoading: boolean) {
    this.subEnsembleLoaded.next(isLoading);
  }

  changeEventsLoaded(isLoading: boolean) {
    this.eventsLoaded.next(isLoading);
  }

  changeYoungsLoaded(isLoading: boolean) {
    this.youngsLoaded.next(isLoading);
  }

  resetGridYoungEmsembleWs() {
    this.ensembleLoaded.next(false);
    this.subEnsembleLoaded.next(false);
    this.eventsLoaded.next(false);
    this.changeDataEventIdList([]);
    this.changeEventsList([]);
    this.changeSubEnsembleList([]);
    this.changeYoungsList(new ProjectYoungAndPresencesDTO());
  }
}
