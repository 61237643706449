import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';
import {Occurrence} from 'src/app/model/event/occurrence.model';
import {OccurrencePlayoutCurrentlyViewedService} from 'src/app/services/occurrence-playout-currently-viewed.service';
import {Destroyed} from '../../../directives/destroyed.directive';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-delete-occurence-btn-renderer',
  templateUrl: './delete-occurence-btn-renderer.component.html',
  styleUrls: ['./delete-occurence-btn-renderer.component.scss'],
  standalone: true,
  imports: [MatButtonModule, MatIconModule]
})
export class DeleteOccurenceBtnRendererComponent
  extends Destroyed
  implements OnInit, ICellRendererAngularComp
{
  public data;
  private occurenceList = new Array<Occurrence>();

  constructor(
    private readonly occurrencePlayoutCurrentlyViewedService: OccurrencePlayoutCurrentlyViewedService
  ) {
    super();
  }

  ngOnInit() {
    this.occurrencePlayoutCurrentlyViewedService.occurenceMessager
      .pipe(this.untilDestroyed())
      .subscribe((data) => (this.occurenceList = data));
  }

  refresh(): boolean {
    return true;
  }

  agInit(params): void {
    this.data = params.data;
  }

  onBtnClick() {
    if (this.data) {
      const selected = this.data.id;
      const index = this.occurenceList.findIndex((obj) => obj.id === selected);
      this.occurenceList.splice(index, 1);
      this.occurrencePlayoutCurrentlyViewedService.changeOccurenceList(this.occurenceList);
    }
  }
}
