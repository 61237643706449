<div [formGroup]="entrepriseFormGroup" class="d-flex flex-column">
  <div class="d-flex flex-row m-2">
    <h3 mat-dialog-title>Ajouter une entreprise non-partenaire</h3>
    <span class="flex-fill separator"></span>
    <button (click)="dismiss()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="d-flex flex-row">
    <div class="form-group col-md-6">
      <label class="label-info star">Type de structure</label>
      <mat-select [ngClass]="{'is-invalid': (fEntreprise.choixTypeStructure.dirty || fEntreprise.choixTypeStructure.touched) && fEntreprise.choixTypeStructure.invalid }" class="custom-select"
                  formControlName="choixTypeStructure">
        <mat-option *ngFor="let type of structureForSelect" [value]="type.id"
                    class="select-item">{{type.name}}</mat-option>
      </mat-select>
      <div
        *ngIf="(fEntreprise.choixTypeStructure.dirty || fEntreprise.choixTypeStructure.touched) && fEntreprise.choixTypeStructure.invalid"
        class="invalid-submit">
        <div>Le type de structure est obligatoire</div>
      </div>
    </div>

    <div class="form-group col-md-6">
      <label class="label-info star">Nom de la structure</label>
      <input
        [matAutocomplete]="auto"
        class="form-control"
        formControlName="entrepriseName">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of (filteredNameMulti | async)" [value]="option">{{option}}</mat-option>
      </mat-autocomplete>
      <div *ngIf="entrepriseExisting && this.fEntreprise.entrepriseName.value.length > 2" class="col-md-6 red">Une
        structure existe déjà avec ce nom
      </div>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button (click)="dismiss()" class="mat-button m-4" mat-button>
      Annuler
    </button>
    <button (click)="checkExistingEntrepriseBeforeSave()" class="mat-button m-4" mat-button type="submit" [disabled]="isSaveBlocked">
      Enregistrer
    </button>
  </mat-dialog-actions>
</div>
