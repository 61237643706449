<div id="grid-wrapper">
  <ag-grid-angular #agGrid
                   (gridReady)="onGridYoungReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   (selectionChanged)="onSelectionChanged()"
                   [animateRows]="true"
                   [columnDefs]="columnYoungDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridYoungOption"
                   [localeText]="langue"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [rowSelection]="rowSelection"
                   [suppressDragLeaveHidesColumns]="true"
                   [suppressRowClickSelection]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>

<mat-toolbar class="sub_header">
  <app-pagination (pageChange)="loadUnregisteredYoung()" [itemPerPage]="itemPerPage" [showHugeItemPerPage]="true"></app-pagination>
</mat-toolbar>
