import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SimpleInterviewDTO} from 'src/app/model/dto/simple-interview.dto';
import {Paged} from 'src/app/model/response/paged.model';

@Injectable({
  providedIn: 'root'
})
export class GridYoungInterviewDataService {
  private readonly dataInterviewList = new BehaviorSubject(null);
  public interviewList = this.dataInterviewList.asObservable();

  private readonly dataIsLoading = new BehaviorSubject(true);
  public isLoadingMessager = this.dataIsLoading.asObservable();

  changeInterviewList(simpleInterviewListDTO: Paged<SimpleInterviewDTO>) {
    this.dataInterviewList.next(simpleInterviewListDTO);
  }

  changeIsLoading(isLoading: boolean) {
    this.dataIsLoading.next(isLoading);
  }
}
