export enum TypeStructureEntrepriseEnum {
  ENTREPRISE,
  ORGANISME_FORMATION,
  DISPOSITIF_ACCOMPAGNEMENT,
  PARTENAIRE_PUBLIC_ASSO
}

export namespace TypeStructureEntrepriseEnum {
  export function format(typeStructure: number) {
    switch (TypeStructureEntrepriseEnum[typeStructure]) {
      case 'ENTREPRISE':
        return 'Entreprise';
      case 'ORGANISME_FORMATION':
        return 'Organisme de formation';
      case 'DISPOSITIF_ACCOMPAGNEMENT':
        return "Dispositif d'accompagnement";
      case 'PARTENAIRE_PUBLIC_ASSO':
        return 'Partenaire public & association';
      default:
        return '';
    }
  }
}
