import {Injectable} from '@angular/core';
import {FilterYoungJob} from '../model/filter/filter-young-job.model';
import {FilterYoungSport} from '../model/filter/filter-young-sport.model';
import {FilterYoungLdlv} from '../model/filter/filter-young-ldlv.model';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilterYoungService {
  public filterBinome: FilterYoungJob;
  public filterEventJobChanged: Observable<void>;
  private readonly _filterEventJobChanged: Subject<void>;

  constructor() {
    this.filterEventJob = new FilterYoungJob();
    this.filterBinome = new FilterYoungJob();

    this._filterEventJobChanged = new Subject<void>();
    this.filterEventJobChanged = this._filterEventJobChanged.asObservable();
  }

  private _tabSelected;

  get tabSelected() {
    return this._tabSelected;
  }

  set tabSelected(value) {
    this._tabSelected = value;
  }

  private _tabSelectedEvent;

  get tabSelectedEvent() {
    return this._tabSelectedEvent;
  }

  set tabSelectedEvent(value) {
    this._tabSelectedEvent = value;
  }

  private _filterYoungSport: FilterYoungSport;

  get filterYoungSport(): FilterYoungSport {
    return this._filterYoungSport;
  }

  set filterYoungSport(value: FilterYoungSport) {
    this._filterYoungSport = value;
  }

  private _filterYoungLdlv: FilterYoungLdlv;

  get filterYoungLdlv(): FilterYoungLdlv {
    return this._filterYoungLdlv;
  }

  set filterYoungLdlv(value: FilterYoungLdlv) {
    this._filterYoungLdlv = value;
  }

  private _filterEventJob: FilterYoungJob;

  get filterEventJob() {
    return this._filterEventJob;
  }

  set filterEventJob(filter: FilterYoungJob) {
    this._filterEventJob = filter;
  }

  private _filterYoungJob: FilterYoungJob;

  get filterYoungJob() {
    return this._filterYoungJob;
  }

  set filterYoungJob(filter: FilterYoungJob) {
    this._filterYoungJob = filter;
  }

  public getFilterBinome() {
    return this.filterBinome;
  }

  public setFilterBinome(filter: FilterYoungJob) {
    this.filterBinome = filter;
  }

  public changeFilterEventJob() {
    this._filterEventJobChanged.next();
  }
}
