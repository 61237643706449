import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RegisteredYoungListDTO} from '../model/dto/registered-young-list.dto';

@Injectable({
  providedIn: 'root'
})
export class GridProfileYoungDataService {
  private readonly dataYoungList = new BehaviorSubject([]);
  public youngListMessager = this.dataYoungList.asObservable();

  private readonly dataIsLoading = new BehaviorSubject(true);
  public isLoadingMessager = this.dataIsLoading.asObservable();

  changeYoungList(listOfYoung: RegisteredYoungListDTO[]) {
    this.dataYoungList.next(listOfYoung);
  }

  changeIsLoading(isLoading: boolean) {
    this.dataIsLoading.next(isLoading);
  }
}
