<div *ngIf="!isLoading" id="grid-wrapper">
  <ag-grid-angular #agGrid
                   (gridReady)="onGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   (rowClicked)="onRowClick($event)"
                   [animateRows]="true"
                   [columnDefs]="columnDefs"
                   [context]="context"
                   [domLayout]="domLayout"

                   [gridOptions]="gridOptions"
                   [localeText]="langue"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [rowSelection]="rowSelection"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>
