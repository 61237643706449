import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {EventDTO} from '../model/dto/event.dto';

@Injectable({
  providedIn: 'root'
})
export class GridYoungEventDataService {
  private readonly dataOtherSportEventList = new BehaviorSubject([]);
  public otherSportEventListMessager = this.dataOtherSportEventList.asObservable();

  private readonly dataOtherJobEventList = new BehaviorSubject([]);
  public otherJobEventListMessager = this.dataOtherJobEventList.asObservable();

  private readonly ldlvEventList = new BehaviorSubject([]);
  public readonly ldlvEventListMessager = this.ldlvEventList.asObservable();

  changeOtherSportEventList(listOfEventDTO: EventDTO[]) {
    this.dataOtherSportEventList.next(listOfEventDTO);
  }

  changeOtherJobEventList(listOfEventDTO: EventDTO[]) {
    this.dataOtherJobEventList.next(listOfEventDTO);
  }

  changeLdlvEvents(listOfEventDTO: EventDTO[]) {
    this.ldlvEventList.next(listOfEventDTO);
  }
}
