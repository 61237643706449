import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridApi, GridOptions} from '@ag-grid-community/core';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {FormatService} from 'src/app/services/format.service';
import {Router} from '@angular/router';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {GridIntervenantDataService} from '../../../../services/grid-intervenant-data.service';
import {IntervenantDTO} from '../../../../model/dto/intervenant.dto';
import {Destroyed} from '../../directives/destroyed.directive';
import {isNotNullOrUndefined} from '../../../../utils/utils.static';
import {PaginationComponent} from '../../pagination/pagination.component';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {AlertComponent} from '../../alert/alert/alert.component';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-intervenant',
  templateUrl: './grid-intervenant.component.html',
  styleUrls: ['./grid-intervenant.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    NgIf,
    AgGridModule,
    MatProgressSpinnerModule,
    MatCardModule,
    PaginationComponent,
    AsyncPipe
  ]
})
export class GridIntervenantComponent extends Destroyed implements OnInit {
  @Input() isLoading;
  @Input() itemPerPage;
  @Output() pageChange = new EventEmitter<unknown>();
  itemsIntervenant: Paged<IntervenantDTO>;

  rowClassRules;
  gridIntervenantApi: GridApi;
  columnIntervenantDefs;
  gridIntervenantOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;
  rowSelection;
  getRowHeight;
  public readonly langue = agGridLanguageFr;

  constructor(
    private readonly gridIntervenantService: GridIntervenantDataService,
    private readonly formatService: FormatService,
    private readonly router: Router
  ) {
    super();
    this.columnIntervenantDefs = [
      {
        headerName: 'Nom',
        field: 'name',
        cellRenderer: NameCellRendererComponent,
        colId: 'name',
        sortable: true,
        minWidth: 220,
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      {
        headerName: 'Sexe',
        field: 'sex',
        valueFormatter: this.formatSex.bind(this),
        sortable: true,
        minWidth: 50,
        maxWidth: 55
      },
      {
        headerName: 'Adresse',
        field: 'address',
        sortable: true,
        minWidth: 250
      },

      {
        headerName: "Nom de l'entreprise",
        field: 'entreprise',
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Statut',
        field: 'statut',
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Fonction',
        field: 'fonction',
        sortable: true,
        minWidth: 250
      },
      {
        headerName: 'Domaine de compétences métiers',
        field: 'domainMetier',
        sortable: true,
        minWidth: 250
      }
    ];
    this.context = {componentParent: this};
    this.frameworkComponents = {
      nameCellRenderer: NameCellRendererComponent
    };
    this.rowSelection = 'multiple';
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  ngOnInit() {
    this.gridIntervenantOption = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',
      suppressRowClickSelection: true,
      rowSelection: 'multiple',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;
    this.gridIntervenantService.intervenantListMessager
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<IntervenantDTO>) => {
        this.itemsIntervenant = data;
        this.setAffichage();
      });
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridIntervenantReady(params) {
    this.gridIntervenantOption.api.sizeColumnsToFit();
    this.gridIntervenantOption.animateRows = true;
    this.gridIntervenantApi = params.api;
    this.gridIntervenantOption.suppressCellFocus = true;
    this.gridIntervenantOption.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridIntervenantOption.api) {
      this.itemsIntervenant.items.forEach((intervenant: IntervenantDTO) => {
        returnRows.push(this.getRowFromIntervenant(intervenant));
      }, this);
      this.gridIntervenantOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un intervenant pour l'affichagé sur une ligne
   * @param intervenant
   */
  getRowFromIntervenant(intervenant: IntervenantDTO) {
    return {
      id: intervenant.id,
      sex: intervenant.sex,
      address: isNotNullOrUndefined(intervenant.adresse) ? intervenant.adresse : '/',
      statut: isNotNullOrUndefined(intervenant.statut)
        ? this.formatService.formatStatusIntervenant(intervenant.statut)
        : '/',
      entreprise: isNotNullOrUndefined(intervenant.entrepriseNom)
        ? intervenant.entrepriseNom
        : 'Engagement à titre perso',
      name: this.formatService.formatNameIntervenant(intervenant),
      firstName: intervenant.prenom,
      lastName: intervenant.nom,
      fonction: isNotNullOrUndefined(intervenant.fonction) ? intervenant.fonction : '/',
      domainMetier:
        intervenant.listOfPDomaineMetier && intervenant.listOfPDomaineMetier.length > 0
          ? intervenant.listOfPDomaineMetier.map((item) => item.label).join(', ')
          : '/'
    };
  }

  onRowClick(event) {
    this.router.navigate(['speaker/', event.data.id]);
  }

  rowSelected() {
    if (this.gridIntervenantApi) {
      return this.gridIntervenantApi.getSelectedNodes().length <= 0;
    } else {
      return true;
    }
  }

  emitPageChange() {
    this.pageChange.emit();
  }
}
