import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileReloadService {
  public reload: Observable<void>;
  private readonly _reload: Subject<void>;

  constructor() {
    this._reload = new Subject<void>();
    this.reload = this._reload.asObservable();
  }

  triggerRefresh() {
    this._reload.next();
  }
}
