<div>
  <p style="width: 3.5rem">{{tauxPresence}}%</p>
  <mat-progress-bar [color]="" [value]="tauxPresence" mode="determinate"></mat-progress-bar>
  <mat-chip-option *ngIf="!tauxIncrease === true && additionnalRate" [disableRipple]="true"
            class="decreaseRate no-shadow">{{additionnalRate}}%
  </mat-chip-option>
  <mat-chip-option *ngIf="tauxIncrease === true && additionnalRate" [disableRipple]="true" class="increaseRate no-shadow">
    +{{additionnalRate}}%
  </mat-chip-option>
</div>

