import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Timesheet} from 'src/app/model/event/timesheet.model';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TimesheetWebservice {
  private readonly root = `${environment.envApiUrl}/timesheets`;

  constructor(private readonly http: HttpClient) {}

  putUpdateTimesheetList(list: Timesheet[]) {
    return this.http.put(`${this.root}`, list);
  }
}
