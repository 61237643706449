<mat-card appearance="outlined" class="m-4">
  <button (click)="export()" class="btn-fit m-2" mat-button>Export</button>
  <ng-container *ngIf="!isLoading; else loading">
    <div *ngIf="listOfYoung; else noData" id="grid-wrapper">
      <ag-grid-angular #agGrid
                       (cellClicked)="onRowClick($event)"
                       (gridReady)="onGridYoungReady($event)"
                       (gridSizeChanged)="onGridSizeChanged($event)"
                       [animateRows]="true"
                       [columnDefs]="columnYoungDefs"
                       [context]="context"
                       [domLayout]="domLayout"
                       [getRowHeight]="getRowHeight"
                       [gridOptions]="gridYoungLdlvOption"
                       [localeText]="langue"
                       [rowClassRules]="rowClassRules"
                       [rowData]="rowData | async"
                       [rowSelection]="rowSelection"
                       [suppressDragLeaveHidesColumns]="true"
                       class="ag-theme-balham">
      </ag-grid-angular>
    </div>
  </ng-container>

  <app-pagination (pageChange)="emitPageChange()" [itemPerPage]="10" [showItemPerPage]="true"></app-pagination>

  <ng-template #loading>
    <div class="d-flex flex-row justify-content-center align-items-center py-5">
      <label>Chargement en cours...</label>
    </div>
  </ng-template>

  <ng-template #noData>
    <mat-card appearance="outlined" class="m-4">
      <h5 class="m-2">Aucune donnée ne correspond à ces critères</h5>
    </mat-card>
  </ng-template>
</mat-card>
