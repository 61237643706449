<div *ngIf="!isLoading" class="m-0 p-0" id="grid-wrapper-event">
  <app-alert></app-alert>
    <ag-grid-angular #agGrid2
                     (gridReady)="onEventGridReady($event)"
                     (gridSizeChanged)="onGridSizeChanged($event)"
                     [animateRows]="true"
                     [columnDefs]="columnEventDefs"
                     [context]="context"
                     [domLayout]="domLayout"
                     [gridOptions]="gridOptions"
                     [localeText]="langue"
                     [paginationPageSize]="paginationSize"
                     [rowClassRules]="rowClassRules"
                     [rowData]="rowData | async"
                     [suppressDragLeaveHidesColumns]="true"
                     class="ag-theme-balham"
                     pagination="true">
    </ag-grid-angular>
</div>
