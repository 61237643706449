import {Component, OnInit} from '@angular/core';
import {FormatService} from '../../../../../../services/format.service';
import {YoungWebservice} from '../../../../../../services/webservices/young.webservice';
import {MassModificationStatusDto} from '../../../../../../model/dto/mass-modification-status.dto';
import {FilterYoungJob} from '../../../../../../model/filter/filter-young-job.model';
import {
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {ParamWebservice} from '../../../../../../services/webservices/param.webservice';
import {Destroyed} from '../../../../../shared/directives/destroyed.directive';
import {MatButtonModule} from '@angular/material/button';
import {MatOptionModule} from '@angular/material/core';
import {FormsModule} from '@angular/forms';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {NgFor, NgIf} from '@angular/common';
import {PMonitoringStatus} from '../../../../../../model/param/p-monitoring-status.model';

@Component({
  selector: 'app-mass-modification-modal',
  templateUrl: './mass-modification-modal-status.component.html',
  styleUrls: ['./mass-modification-modal-status.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogTitle,
    MatDialogContent,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    MatDialogActions,
    MatButtonModule,
    MatDialogClose
  ]
})
export class MassModificationModalStatusComponent extends Destroyed implements OnInit {
  listOfId: number[];
  statusList: PMonitoringStatus[] = [];
  filter: FilterYoungJob;
  massModification: MassModificationStatusDto;

  constructor(
    private readonly dialogRef: MatDialogRef<MassModificationModalStatusComponent>,
    private readonly formatService: FormatService,
    private readonly paramWebservice: ParamWebservice,
    private readonly youngService: YoungWebservice
  ) {
    super();
  }

  ngOnInit() {
    this.massModification = new MassModificationStatusDto();
    this.massModification.listOfYoung = this.listOfId;
    this.massModification.filter = this.filter;
    this.massModification.statut = undefined;
    this.initStatus();
  }

  onDismiss() {
    this.dialogRef.close({dismiss: true, data: null});
  }

  updateYoung() {
    this.youngService
      .massModificationStatus(2020, this.massModification)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: (item) => {
          this.dialogRef.close({dismiss: false, data: item});
        },
        error: (err) => {
          this.dialogRef.close({dismiss: true, data: null});
        }
      });
  }

  private initStatus() {
    this.paramWebservice
      .getAllMonitoringStatus()
      .pipe(this.untilDestroyed())
      .subscribe((statuses) => {
        this.statusList = statuses;
      });
  }
}
