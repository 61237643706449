<div id="grid-wrapper">
  <ag-grid-angular #agGrid
                   (gridReady)="onGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged($event)"
                   [animateRows]="true"
                   [columnDefs]="columnDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridOptions"
                   [localeText]="langue"
                   [paginationPageSize]="paginationSize"
                   [pagination]="true"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [rowSelection]="rowSelection"
                   [suppressDragLeaveHidesColumns]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>

<app-alert></app-alert>
@if(presencesEditable) {
  <div class="d-flex flex-row">
    <button [disabled]="loading" *appHasModule="canValidate" (click)="onValidPresence()" mat-button>Valider les présences</button>
    <button [disabled]="loading" *appHasModule="canValidate" (click)="onValidAbsence()" class="mx-3" mat-button>Valider les absences</button>
    <button [disabled]="loading" *appHasModule="canValidate" (click)="onValidLate()" mat-button>Valider les retards</button>
  </div>
}
