import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GridOptions} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {FormatService} from '../../../../services/format.service';
import {Router} from '@angular/router';
import {NameCellRendererComponent} from '../custom-cell/name-cell-renderer/name-cell-renderer.component';
import {EventTypeListCellRendererComponent} from '../custom-cell/event-type-list-cell-renderer/event-type-list-cell-renderer.component';
import {StatusCellRendererComponent} from '../custom-cell/status-cell-renderer/status-cell-renderer.component';
import {EditBtnRendererComponent} from '../custom-cell/edit-btn-renderer/edit-btn-renderer.component';
import {GridYoungBinomeDataService} from '../../../../services/grid-young-binome-data.service';
import {SimpleBinomeDto} from '../../../../model/dto/simple-binome.dto';
import {DialogAddRelationSpeakerComponent} from '../../dialog/dialog-add-relation-speaker/dialog-add-relation-speaker.component';
import {MatDialog} from '@angular/material/dialog';
import {RelationBinome} from '../../../../model/user/relation-binome.model';
import {Destroyed} from '../../directives/destroyed.directive';
import {DeleteRelationBtnComponent} from '../custom-cell/delete-relation-btn/delete-relation-btn.component';
import {ModuleSource, onlyAdmin} from '../../../../model/enums/source/module-source.enum';
import {CurrentUserService} from '../../../../services/current-user.service';
import {DialogConfirmSuppressionRelationComponent} from '../../dialog/dialog-confirm-suppression-relation/dialog-confirm-suppression-relation.component';
import {RelationBinomeWebservice} from '../../../../services/webservices/relation-binome.webservice';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PaginationComponent} from '../../pagination/pagination.component';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';
import {TypeBinomeEnum} from '../../../../model/enums/type-binome.enum';
import {TypeContactBinomeEnum} from '../../../../model/enums/type-contact-binome.enum';
import {DurationBinomeEnum} from '../../../../model/enums/duration-binome.enum';
import {Paged} from 'src/app/model/response/paged.model';

@Component({
  selector: 'app-grid-young-relation',
  templateUrl: './grid-young-relation.component.html',
  styleUrls: ['./grid-young-relation.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    AgGridModule,
    PaginationComponent,
    MatProgressSpinnerModule,
    MatCardModule,
    AsyncPipe
  ]
})
export class GridYoungRelationComponent extends Destroyed implements OnInit {
  @Input() isYoung = false;
  @Input() isLoading;
  @Input() itemPerPage;

  @Output() pageChange = new EventEmitter<unknown>();

  listOfYoung: Paged<SimpleBinomeDto>;
  rowClassRules;
  gridYoungApi: unknown;
  columnYoungDefs;
  isYoungLoading = true;
  gridYoungOption: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;

  paginationSize = 10;
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly gridYoungBinomeDataService: GridYoungBinomeDataService,
    private readonly formatService: FormatService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly currentUserService: CurrentUserService,
    private readonly relationBinomeWS: RelationBinomeWebservice
  ) {
    super();
  }

  ngOnInit() {
    if (this.isYoung) {
      this.columnYoungDefs = [
        {
          headerName: 'Nom',
          field: 'name',
          colId: 'name',
          cellRenderer: NameCellRendererComponent,
          sortable: true
        },
        {
          headerName: 'Type du Binome',
          field: 'typeBinome',
          sortable: true
        },
        {
          headerName: 'Date de début',
          field: 'date',
          sortable: true
        },
        {
          headerName: 'Type',
          field: 'type',
          sortable: true
        },
        {
          headerName: 'Durée',
          field: 'duree',
          sortable: true
        },
        {
          headerName: 'Suivi',
          field: 'suivi',
          sortable: true
        },
        {
          headerName: 'Date dernier suivi',
          field: 'dateLastFollow',
          sortable: true
        },
        {
          headerName: 'Commentaire dernier suivi',
          field: 'commentLastFollow',
          sortable: true
        },
        {
          headerName: '',
          cellRenderer: DeleteRelationBtnComponent,
          cellRendererParams: {
            action: this.deleteRelation.bind(this)
          },
          colId: 'btn',
          minWidth: 50,
          maxWidth: 100,
          pinned: 'right',
          hide: !this.isAdminLvl1()
        }
      ];
    } else {
      this.columnYoungDefs = [
        {
          headerName: 'Nom',
          field: 'name',
          colId: 'name',
          cellRenderer: NameCellRendererComponent,
          sortable: true
        },
        {
          headerName: 'Type du Binome',
          field: 'typeBinome',
          sortable: true
        },
        {
          headerName: 'Date de début',
          field: 'date',
          sortable: true
        },
        {
          headerName: 'Type',
          field: 'type',
          sortable: true
        },
        {
          headerName: 'Durée',
          field: 'duree',
          sortable: true
        },
        {
          headerName: '',
          cellRenderer: DeleteRelationBtnComponent,
          cellRendererParams: {
            action: this.deleteRelation.bind(this)
          },
          colId: 'btn',
          width: 75,
          pinned: 'right',
          hide: !this.isAdminLvl1()
        }
      ];
    }
    this.context = {componentParent: this};
    this.frameworkComponents = {
      nameCellRenderer: NameCellRendererComponent,
      eventTypeListCellRendererComponent: EventTypeListCellRendererComponent,
      statusCellRendererComponent: StatusCellRendererComponent,
      editBtnRendererComponent: EditBtnRendererComponent,
      deleteRelationBtnComponent: DeleteRelationBtnComponent
    };
    this.rowSelection = 'multiple';

    this.gridYoungOption = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    this.gridYoungBinomeDataService.youngList
      .pipe(this.untilDestroyed())
      .subscribe((data: Paged<SimpleBinomeDto>) => {
        this.listOfYoung = data;
        this.setAffichage();
      });
  }

  isAdminLvl1(): boolean {
    return this.currentUserService.checkModule(...onlyAdmin);
  }

  emitPageChange() {
    this.pageChange.emit();
  }

  onGridSizeChanged(params) {
    params.api.sizeColumnsToFit();
  }

  onGridYoungReady(params) {
    this.gridYoungOption.api.sizeColumnsToFit();
    this.gridYoungOption.animateRows = true;
    this.gridYoungApi = params.api;
    this.gridYoungOption.suppressCellFocus = true;
    if (!this.isYoung) {
      this.gridYoungOption.suppressHorizontalScroll = true;
    }
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridYoungOption.api) {
      this.listOfYoung.items.forEach((young: SimpleBinomeDto) => {
        returnRows.push(this.getRowFromUser(young));
      }, this);

      this.gridYoungOption.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un jeune pour l'affichage sur une ligne
   * @param young
   */
  getRowFromUser(young: SimpleBinomeDto) {
    if (this.isYoung) {
      return {
        id: young.id,
        name: this.formatService.formatName(young),
        firstName: young.firstName,
        lastName: young.lastName,
        type: TypeContactBinomeEnum.format(young.typeContactBinome),
        typeBinome: TypeBinomeEnum.format(young.typeBinome),
        date: this.formatService.formatDate(young.dateBeginBinome),
        duree: DurationBinomeEnum.format(young.duree),
        suivi: young.suivi ? young.suivi : '/',
        dateLastFollow: young.dateLastFollow
          ? this.formatService.formatDate(young.dateLastFollow)
          : '/',
        commentLastFollow: young.commentLastFollow ? young.commentLastFollow : '/'
      };
    } else {
      return {
        id: young.id,
        name: this.formatService.formatName(young),
        firstName: young.firstName,
        lastName: young.lastName,
        type: TypeContactBinomeEnum.format(young.typeContactBinome),
        typeBinome: TypeBinomeEnum.format(young.typeBinome),
        date: this.formatService.formatDate(young.dateBeginBinome),
        duree: DurationBinomeEnum.format(young.duree)
      };
    }
  }

  formatSex(params) {
    return this.formatService.formatSex(params.value, false);
  }

  onRowClick(event) {
    if (
      event.colDef.colId !== 'btn' &&
      this.currentUserService.checkModule(...onlyAdmin, ModuleSource.MODIFICATION_UTILISATEUR)
    ) {
      const dialogRef = this.dialog.open(DialogAddRelationSpeakerComponent, {
        panelClass: 'dialog'
      });

      dialogRef.componentInstance.relationBinome = new RelationBinome();
      dialogRef.componentInstance.relationBinome.id = event.data.id;
      dialogRef.componentInstance.isYoung = true;
      dialogRef.componentInstance.isIntervenant = true;

      dialogRef
        .afterClosed()
        .pipe(this.untilDestroyed())
        .subscribe((value) => this.emitPageChange());
    }
  }

  deleteRelation(data) {
    if (data) {
      const POPUP_TITLE = "Suppression d'une relation binôme";
      const POPUP_MESSAGE = `Souhaitez-vous supprimer la relation avec ${data.name} ?`;

      const dialogRef = this.dialog.open(DialogConfirmSuppressionRelationComponent);
      dialogRef.componentInstance.title = POPUP_TITLE;
      dialogRef.componentInstance.message = POPUP_MESSAGE;
      dialogRef.componentInstance.canDelete = this.isAdminLvl1();

      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.relationBinomeWS.deleteRelationBinome(data.id).subscribe(() => {
            this.emitPageChange();
          });
        }
      });
    }
  }
}
