import {Component, Input, OnInit} from '@angular/core';
import {GridYoungEventDataService} from 'src/app/services/grid-young-event-data.service';
import {EventDTO} from 'src/app/model/dto/event.dto';
import {GridOptions} from '@ag-grid-community/core';
import {agGridLanguageFr} from '../custom-ag-grid-language';
import {FormatService} from 'src/app/services/format.service';
import {TauxCellRendererComponent} from '../custom-cell/taux-cell-renderer/taux-cell-renderer.component';
import {Router} from '@angular/router';
import {Destroyed} from '../../directives/destroyed.directive';
import {ProgramEnum} from '../../../../model/enums/program.enum';
import {AgGridModule} from '@ag-grid-community/angular';
import {AsyncPipe, NgIf} from '@angular/common';

@Component({
  selector: 'app-grid-young-event',
  templateUrl: './grid-young-event.component.html',
  styleUrls: ['./grid-young-event.component.scss'],
  standalone: true,
  imports: [NgIf, AgGridModule, AsyncPipe]
})
export class GridYoungEventComponent extends Destroyed implements OnInit {
  @Input() idProgram;

  private;
  listOfEventDTO: EventDTO[];

  // grid
  rowClassRules;
  gridApi: unknown;
  columnDefs;
  isLoading = true;
  gridOptions: GridOptions;
  context;
  rowData: unknown = null;
  domLayout: unknown = 'autoHeight';
  frameworkComponents;

  paginationSize = 10;
  getRowHeight;
  rowSelection;
  langue = agGridLanguageFr;

  constructor(
    private readonly gridYoungEventDataService: GridYoungEventDataService,
    private readonly formatService: FormatService,
    private readonly router: Router
  ) {
    super();
    this.columnDefs = [
      {
        headerName: 'Nom',
        field: 'eventType',
        colId: 'name',
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Lieux',
        field: 'place',
        valueFormatter: this.formatPlace.bind(this),
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Inscrit le',
        field: 'registerDate',
        valueFormatter: this.formatDate.bind(this),
        sortable: true,
        minWidth: 200
      },
      {
        headerName: 'Début',
        field: 'dateStart',
        valueFormatter: this.formatDate.bind(this),
        sortable: true,
        minWidth: 100,
        maxWidth: 150
      },
      {
        headerName: 'Fin',
        field: 'dateEnd',
        valueFormatter: this.formatDate.bind(this),
        sortable: true,
        minWidth: 100,
        maxWidth: 150
      },
      {
        headerName: 'Taux de présence',
        field: 'presence',
        sortable: true,
        cellRenderer: TauxCellRendererComponent,
        minWidth: 250
      }
    ];
    this.context = {componentParent: this};
    this.frameworkComponents = {
      tauxCellRendererComponent: TauxCellRendererComponent
    };
    this.rowSelection = 'none';
  }

  ngOnInit() {
    this.gridOptions = {
      defaultColDef: {
        flex: 1,
        // make all cols more narrow
        width: 100,
        resizable: true,
        filter: false,
        domLayout: 'autoHeight',
        sortable: false
      },
      suppressMenuHide: true,

      overlayLoadingTemplate: 'Chargement des données...',
      overlayNoRowsTemplate: 'Aucune donnée',

      rowDragManaged: false,
      headerHeight: 50,
      rowHeight: 60
    } as GridOptions;

    if (this.idProgram === ProgramEnum.SPORTDANSLAVILLE) {
      this.gridYoungEventDataService.otherSportEventListMessager
        .pipe(this.untilDestroyed())
        .subscribe((data: EventDTO[]) => {
          this.listOfEventDTO = data;

          this.setAffichage();
          if (this.listOfEventDTO.length > 0) {
            this.isLoading = false;
          }
        });
    } else if (this.idProgram === ProgramEnum.LDANSLAVILLE) {
      this.gridYoungEventDataService.ldlvEventListMessager
        .pipe(this.untilDestroyed())
        .subscribe((data: EventDTO[]) => {
          this.listOfEventDTO = data;

          this.setAffichage();
          if (this.listOfEventDTO.length > 0) {
            this.isLoading = false;
          }
        });
    } else if (this.idProgram === ProgramEnum.JOBDANSLAVILLE) {
      this.gridYoungEventDataService.otherJobEventListMessager
        .pipe(this.untilDestroyed())
        .subscribe((data: EventDTO[]) => {
          this.listOfEventDTO = data;

          this.setAffichage();
          if (this.listOfEventDTO.length > 0) {
            this.isLoading = false;
          }
        });
    }
  }

  onGridSizeChanged(params) {
    const columnsToShow = [];
    const columnsToHide = [];
    let totalColsWidth = 0;
    const allColumns = params.columnApi.getColumns();
    if (document.getElementById('grid-wrapper')) {
      const gridWidth = document.getElementById('grid-wrapper').offsetWidth;

      if (allColumns) {
        allColumns.forEach((column) => {
          totalColsWidth += column.getMinWidth();
          if (totalColsWidth > gridWidth) {
            columnsToHide.push(column.colId);
          } else {
            columnsToShow.push(column.colId);
          }
        });
      }

      params.columnApi.setColumnsVisible(columnsToShow, true);
      params.columnApi.setColumnsVisible(columnsToHide, false);
      params.api.sizeColumnsToFit();
      params.api.resetRowHeights();
    }
  }

  onGridReady(params) {
    this.gridOptions.api.sizeColumnsToFit();
    this.gridOptions.animateRows = true;
    this.gridApi = params.api;
    this.gridOptions.suppressCellFocus = true;
    this.gridOptions.suppressHorizontalScroll = true;
    this.setAffichage();
  }

  setAffichage() {
    const returnRows = [];
    if (this.gridOptions.api) {
      this.listOfEventDTO.forEach((eventDto: EventDTO) => {
        returnRows.push(this.getRowFromYoung(eventDto));
      }, this);

      this.gridOptions.api.setRowData(returnRows);
    }
  }

  /**
   * Extrait les infos d'un event pour l'affichagé sur une ligne
   * @param EventDTO, event à afficher
   */
  getRowFromYoung(eventDTO: EventDTO) {
    return {
      id: eventDTO.event.id,
      eventType: eventDTO.event.eventType,
      registerDate: eventDTO.registerDate,
      place: eventDTO.event.listOfPlayOut,
      dateStart: eventDTO.event.dateBegin,
      dateEnd: eventDTO.event.dateEnd,
      presence: {
        tauxPresence: eventDTO.tauxDePresence
      }
    };
  }

  onRowClick(event) {
    this.router.navigate(['/list-jeune-by-one-activite', event.data.id]);
  }

  formatDate(params) {
    return this.formatService.formatDate(params.value);
  }

  formatPlace(params) {
    return this.formatService.formatListPlayout(params.value);
  }
}
