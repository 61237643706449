<div *ngIf="!isModuleLoading">
  <ag-grid-angular #agGrid2
                   (gridReady)="onModuleGridReady($event)"
                   (gridSizeChanged)="onGridSizeChanged()"
                   (selectionChanged)="onSelectionChanged()"
                   [animateRows]="true"
                   [columnDefs]="columnModuleDefs"
                   [context]="context"
                   [domLayout]="domLayout"
                   [gridOptions]="gridModuleOptions"
                   [localeText]="langue"
                   [rowClassRules]="rowClassRules"
                   [rowData]="rowData | async"
                   [rowSelection]="rowSelection"
                   [suppressDragLeaveHidesColumns]="true"
                   [suppressRowClickSelection]="true"
                   class="ag-theme-balham">
  </ag-grid-angular>
</div>
