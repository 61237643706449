import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {FilterEntreprise} from '../../model/filter/filter-entreprise.model';
import {Entreprise} from '../../model/entreprise/entreprise.model';
import {FilterIntervenant} from '../../model/filter/filter-intervenant.model';
import {Observable} from 'rxjs';
import {PageRequest} from 'src/app/model/request/pageRequest';

@Injectable({
  providedIn: 'root'
})
export class EntrepriseWebservice {
  private readonly root = `${environment.envApiUrl}/entreprise`;

  constructor(private readonly http: HttpClient) {}

  getEntrepriseWithFilter(filterEntreprise: FilterEntreprise, pageRequest: PageRequest) {
    return this.http.post(
      `${this.root}/list`,
      filterEntreprise,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getEntrepriseDetail(idEntreprise: number) {
    return this.http.get(`${this.root}/detail/${idEntreprise}`);
  }

  getEntreprise(idEntreprise: number): Observable<Entreprise> {
    return this.http.get<Entreprise>(`${this.root}/${idEntreprise}`);
  }

  getAllEntreprise(name?: string) {
    const params = new HttpParams().append('entrepriseName', name);
    return this.http.get(`${this.root}`, {params});
  }

  getIntervenantEntreprise(filter: FilterIntervenant, pageRequest: PageRequest) {
    return this.http.post(
      `${this.root}/intervenants`,
      filter,
      PageRequest.toQueryParams(pageRequest)
    );
  }

  getAllEntrepriseName() {
    return this.http.get(`${this.root}/allName`);
  }

  postSaveEntreprise(entreprise: Entreprise) {
    return this.http.post(`${this.root}/save`, entreprise);
  }

  putSaveEntreprise(entreprise: Entreprise) {
    return this.http.put(`${this.root}`, entreprise);
  }

  deleteEntreprise(id: number) {
    return this.http.delete(`${this.root}/${id}`);
  }

  checkExisting(name) {
    return this.http.get(`${this.root}/checkName/${name}`);
  }
}
