import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {RegisteredYoungLdlvDTO} from 'src/app/model/dto/registered-young-ldlv.dto';
import {Paged} from 'src/app/model/response/paged.model';

@Injectable()
export class ProfileYoungLdlvService {
  private readonly dataYoungList = new BehaviorSubject(null);
  public youngListMessager = this.dataYoungList.asObservable();

  changeYoungList(listOfYoung: Paged<RegisteredYoungLdlvDTO>) {
    this.dataYoungList.next(listOfYoung);
  }
}
