<div id='grid-wrapper' *ngIf='listOfYoung?.length > 0'>
  <ag-grid-angular #agGrid
                   (gridReady)='onGridReady($event)'
                   (gridSizeChanged)='onGridSizeChanged($event)'
                   (selectionChanged)='onSelectionChanged()'
                   [animateRows]='true'
                   [columnDefs]='columnDefs'
                   [context]='context'
                   [domLayout]='domLayout'
                   [gridOptions]='gridOptions'
                   [isExternalFilterPresent]='isExternalFilterPresent'
                   [localeText]='langue'
                   [paginationPageSize]='paginationSize'
                   [pagination]='true'
                   [rowClassRules]='rowClassRules'
                   [rowData]='rowData | async'
                   [rowSelection]='rowSelection'
                   [suppressDragLeaveHidesColumns]='true'
                   class='ag-theme-balham'>
  </ag-grid-angular>
</div>

<button (click)='goToFusion()' [disabled]='!selected || (selected && selected.length !== 2)' mat-button>Fusionner les
  jeunes
</button>

