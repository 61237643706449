import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Paged} from 'src/app/model/response/paged.model';
import {Entreprise} from 'src/app/model/entreprise/entreprise.model';

@Injectable({
  providedIn: 'root'
})
export class GridEntrepriseDataService {
  private readonly dataEntrepriseList = new BehaviorSubject(null);
  public entrepriseListMessager = this.dataEntrepriseList.asObservable();

  changeEntrepriseList(listOfEntreprise: Paged<Entreprise>) {
    this.dataEntrepriseList.next(listOfEntreprise);
  }
}
