import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlaceNombreEventCreationService {
  private readonly dataNombrePlaceEvent = new BehaviorSubject(0);
  public nombrePlaceEventMessager = this.dataNombrePlaceEvent.asObservable();
  private readonly dataNombrePlaceTotalPlayOut = new BehaviorSubject(0);
  public nombrePlaceTotalPlayOutMessager = this.dataNombrePlaceTotalPlayOut.asObservable();

  changeNombrePlaceEventList(placeEventList: number) {
    this.dataNombrePlaceEvent.next(placeEventList);
  }

  changeNombrePlaceTotalPlayoutList(placePlayout: number) {
    this.dataNombrePlaceTotalPlayOut.next(placePlayout);
  }
}
