import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SimpleWorkExperienceDTO} from 'src/app/model/dto/simple-work-experience.dto';
import {Paged} from 'src/app/model/response/paged.model';

@Injectable({
  providedIn: 'root'
})
export class GridYoungWorkExperienceDataService {
  private readonly dataWorkExperienceList = new BehaviorSubject(null);
  public workExperienceList = this.dataWorkExperienceList.asObservable();

  private readonly dataIsLoading = new BehaviorSubject(true);
  public isLoadingMessager = this.dataIsLoading.asObservable();

  changeWorkExperienceList(listOfWorkExperience: Paged<SimpleWorkExperienceDTO>) {
    this.dataWorkExperienceList.next(listOfWorkExperience);
  }

  changeIsLoading(isLoading: boolean) {
    this.dataIsLoading.next(isLoading);
  }
}
