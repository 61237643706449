import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Role} from 'src/app/model/role/role.model';
import {Module} from 'src/app/model/role/module.model';

@Injectable({
  providedIn: 'root'
})
export class RoleWebservice {
  private readonly root = `${environment.envApiUrl}/roles`;

  constructor(private readonly http: HttpClient) {}

  getAllRoles() {
    return this.http.get<Role[]>(`${this.root}`);
  }

  updateRoleModules(idRole: number, listOfModules: Module[]) {
    return this.http.put(`${this.root}/${idRole}/modules`, listOfModules);
  }

  createRole(role: Role) {
    return this.http.post(`${this.root}`, role);
  }

  deleteRole(idRole: number) {
    return this.http.delete(`${this.root}/${idRole}`);
  }
}
